import type {
  Cache,
  DataProxy,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  OperationVariables,
  PossibleTypesMap,
  Reference,
} from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache, isReference } from '@apollo/client';
import type {
  KeyFieldsFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache/inmemory/policies';

import type { ContentfulSysProps } from '../../types/contentful';
import { getTracer } from '../tracing';
import { getQueryName } from './getQueryName';

/** Our overwrides of the Apollo in memory cache. */
class ContentfulInMemoryCache extends InMemoryCache {
  constructor(config?: InMemoryCacheConfig) {
    super(config);
  }

  writeQuery<TD, TV>(options: DataProxy.WriteQueryOptions<TD, TV>): Reference | undefined {
    const trace = getTracer().startSpan(`InMemoryCache.writeQuery: ${getQueryName(options.query)}`);
    const result = super.writeQuery(options);
    trace.endSpan();
    return result;
  }

  diff<TData, TVariables extends OperationVariables = OperationVariables>(
    options: Cache.DiffOptions<TData, TVariables>
  ): DataProxy.DiffResult<TData> {
    const trace = getTracer().startSpan(`InMemoryCache.diff: ${getQueryName(options.query)}`);
    const result = super.diff(options);
    trace.endSpan();
    return result;
  }

  readQuery<QueryType, TVariables = unknown>(
    options: Cache.ReadQueryOptions<QueryType, TVariables>,
    optimistic?: boolean | undefined
  ): QueryType | null {
    const trace = getTracer().startSpan(`InMemoryCache.readQuery: ${getQueryName(options.query)}`);
    const result = super.readQuery(options, optimistic);
    trace.endSpan();
    return result;
  }
}

/**
 * Creates an InMemoryCache for apollo that is catered to be used w/ Contentful.
 *
 * Namely it tells the memory processor the shape of the objects, so that every "Entry" is known to
 * have a "sys.id".
 */
export function createContentfulCache(
  fragments: PossibleTypesMap,
  apolloState?: NormalizedCacheObject
): ContentfulInMemoryCache {
  const keyFields: KeyFieldsFunction = (storeObject, context) => {
    const entry = storeObject as unknown as ContentfulSysProps;

    if (entry.sys?.id) {
      return `${context.typename}:${entry.sys.id}`;
    }

    if (isReference(entry.sys)) {
      return `${context.typename}:${entry.sys.__ref.split(':')[1]}`;
    }

    // This isn't a hard error, so we don't log it. It's meant to warn developers that they didn't
    // include the sys props. And this isn't a warning because some pages throw 1000s of these and
    // collecting stack traces crashes the page.
    console.info(
      `Apollo Query missing { sys { id }}. Add '...ContentfulSysId' field. Type: ${context.typename}`
    );

    return defaultDataIdFromObject(storeObject);
  };

  // See: https://www.apollographql.com/docs/react/caching/cache-configuration#typepolicy-fields
  const typePolicies: TypePolicies = Object.fromEntries(
    fragments.Entry!.flatMap(typeName => [
      // [`${typeName}Collection`, { keyFields: [] } as TypePolicy],
      [typeName, { keyFields } as TypePolicy],
    ])
  );

  const cacheOptions: InMemoryCacheConfig = {
    possibleTypes: fragments,
    typePolicies,
  };

  const cache = new ContentfulInMemoryCache(cacheOptions);

  if (apolloState) {
    const restoreSpan = getTracer().startSpan('restoringContentfulCache');
    cache.restore(apolloState);
    restoreSpan.endSpan();
  }

  return cache;
}
