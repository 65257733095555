import { cx } from '@emotion/css';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Children } from 'react';

import type { BackgroundColor } from '../../constants';
import { Alignment } from '../../constants';
import type {
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize,
} from '../../constants/background';
import { p1Css } from '../../motif';
import { MotifComponent } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import type { BaseComponentProps, Buttons, ImageSources } from '../../types';
import {
  alignmentCss,
  BlockBackgroundColorContext,
  dataSetToAttributes,
  getBackgroundClassName,
  mobileAlignmentCss,
} from '../../utils';
import type { WidthType } from '../BlockBoundary';
import { BlockBoundary } from '../BlockBoundary';
import type { ContentProps } from '../Content';
import { Picture } from '../Picture';
import { Video } from '../Video';
import {
  backgroundCss,
  blockCss,
  boundaryCss,
  callsToActionWrapperCss,
  contentContainerCss,
  contentContainerStyle1ColumnCss,
  contentContainerStyle2ColumnCss,
  contentContainerStyle3ColumnCss,
  curtainCss,
  eyebrowCss,
  fullHeightBlockCss,
  headerCss,
  subtitleCss,
  titleCss,
} from './Block.styled';

export interface BlockProps extends BaseComponentProps {
  mobileBackgroundVideoSource?: string;
  backgroundImageSources?: ImageSources;
  /** @deprecated Pass styles through className */
  backgroundImageSource?: ImageSources;
  /** @deprecated Pass styles through className */
  bgImgClassName?: string;
  /** @deprecated Pass styles through className */
  backgroundRepeat?: BackgroundRepeat;
  /** @deprecated Pass styles through className */
  backgroundSize?: BackgroundSize;
  /** @deprecated Pass styles through className */
  backgroundPosition?: BackgroundPosition;
  /** @deprecated. Break templates aren't controlled on the block level anymore. */
  skipBreakTemplate?: boolean;
  /** @deprecated Use widthStyle instead. */
  isNarrow?: boolean;
  /** @deprecated Breaks are managed separately */
  isPreviousSameBackgroundColor?: boolean;
  /** @deprecated Breaks are managed separately */
  isNextSameBackgroundColor?: boolean;
  /** @deprecated Provide style overrides w/ className */
  isCustom?: boolean;
  /** @deprecated. Use children */
  contentsCollection?: {
    items: Array<ContentProps>;
  };
  showCurtain?: boolean;
  backgroundVideoSource?: string;
  callsToAction?: Buttons;
  anchorId?: string;
  maxColumns?: 1 | 2 | 3;
  backgroundColor?: BackgroundColor;
  eyebrow?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  widthStyle?: WidthType;
  fullHeight?: boolean;
  className?: string;
  eyebrowDataset?: DOMStringMap;
  titleDataset?: DOMStringMap;
  subtitleDataset?: DOMStringMap;
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}

export const Block: FC<PropsWithChildren<BlockProps>> = ({
  showCurtain,
  backgroundVideoSource,
  mobileBackgroundVideoSource,
  backgroundImageSources,
  callsToAction,
  children,
  anchorId,
  maxColumns = 3,
  backgroundColor,
  eyebrow,
  title,
  subtitle,
  titleAlignment = Alignment.Center,
  titleAlignmentMobile = Alignment.Start,
  widthStyle = 'full',
  fullHeight = false,
  className,
  style,
  eyebrowDataset,
  titleDataset,
  subtitleDataset,
  preChildren,
  postChildren,
}) => {
  useMotifStyles(MotifComponent.BLOCK);

  const hasHeader = !!eyebrow || !!title || !!subtitle;
  /**
   * NOTE: Not using Children.count() because it returns 1 more since it counts `null` as a child,
   * `false` as a child and empty fragments as children. See
   * https://www.agney.dev/blog/react-children-count
   */
  const numChildren = Children.toArray(children).length;
  const callsToActionCount = Children.count(callsToAction);

  // Ensure that maxColumns is between 1 and 3, but not more than children count.
  // @ts-ignore — cast number
  maxColumns = Math.max(1, Math.min(numChildren, maxColumns));

  return (
    <article
      id={anchorId}
      className={cx(
        MotifComponent.BLOCK,
        getBackgroundClassName(backgroundColor),
        blockCss,
        {
          [fullHeightBlockCss]: fullHeight,
        },
        className
      )}
      style={style}
    >
      {preChildren}
      {showCurtain && (
        <div data-test-id="sdsm-block-curtain" className={cx(curtainCss, backgroundCss)} />
      )}
      {backgroundImageSources && (
        <Picture
          imgSrcs={backgroundImageSources}
          imgClassName={backgroundCss}
          fetchPriority="auto"
        />
      )}
      {backgroundVideoSource && (
        <Video
          mobileSource={mobileBackgroundVideoSource}
          source={backgroundVideoSource}
          className={cx(backgroundCss)}
          isBackgroundVideo
        />
      )}
      <BlockBackgroundColorContext.Provider value={backgroundColor}>
        <BlockBoundary
          data-testid="sdsm-block-boundary"
          widthStyle={widthStyle}
          className={boundaryCss}
        >
          {hasHeader && (
            <section
              data-test-id="sdsm-block-title"
              className={cx(
                headerCss,
                alignmentCss[titleAlignment],
                mobileAlignmentCss[titleAlignmentMobile]
              )}
            >
              {eyebrow && (
                <div className={eyebrowCss} {...dataSetToAttributes(eyebrowDataset)}>
                  {eyebrow}
                </div>
              )}
              {title && (
                <h2
                  data-test-id="sdsm-block-title"
                  className={cx(titleCss)}
                  {...dataSetToAttributes(titleDataset)}
                >
                  {title}
                </h2>
              )}
              {subtitle && (
                <div
                  data-test-id="sdsm-block-subtitle"
                  className={cx(p1Css, subtitleCss)}
                  {...dataSetToAttributes(subtitleDataset)}
                >
                  {subtitle}
                </div>
              )}
            </section>
          )}
          {children && (
            <section
              data-test-id="sdsm-block-children"
              data-max-columns={maxColumns}
              className={cx(contentContainerCss, {
                [contentContainerStyle1ColumnCss]: maxColumns === 1,
                [contentContainerStyle2ColumnCss]: maxColumns === 2,
                [contentContainerStyle3ColumnCss]: maxColumns === 3,
              })}
            >
              {children}
            </section>
          )}
          {callsToActionCount > 0 && (
            <section
              data-test-id="sdsm-block-ctas"
              className={cx(
                alignmentCss[titleAlignment],
                mobileAlignmentCss[titleAlignmentMobile],
                callsToActionWrapperCss
              )}
            >
              {callsToAction}
            </section>
          )}
        </BlockBoundary>
      </BlockBackgroundColorContext.Provider>
      {postChildren}
    </article>
  );
};

Block.displayName = 'Block';
