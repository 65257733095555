type Point = { x: number; y: number };

const round2 = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export class BezierCurve {
  private static readonly predefinedCurves: { [key: string]: [Point, Point] } = {
    linear: [
      { x: 0.0, y: 0.0 },
      { x: 1.0, y: 1.0 },
    ],
    'ease-in': [
      { x: 0.42, y: 0.0 },
      { x: 1.0, y: 1.0 },
    ],
    'ease-out': [
      { x: 0.0, y: 0.0 },
      { x: 0.58, y: 1.0 },
    ],
    'ease-in-out': [
      { x: 0.42, y: 0.0 },
      { x: 0.58, y: 1.0 },
    ],
    ease: [
      { x: 0.25, y: 0.1 },
      { x: 0.25, y: 1.0 },
    ],
  };

  private static instance: BezierCurve;

  public static getInstance = (): BezierCurve => {
    if (!BezierCurve.instance) {
      BezierCurve.instance = new BezierCurve();
    }

    return BezierCurve.instance;
  };

  private bezierCurveMap;

  constructor() {
    this.bezierCurveMap = new Map<string, Map<number, number>>();
  }

  private getBezierValues(t: number, p1: Point, p2: Point): Point {
    const p0: Point = { x: 0, y: 0 };
    const p3: Point = { x: 1, y: 1 };
    const cX = 3 * (p1.x - p0.x),
      bX = 3 * (p2.x - p1.x) - cX,
      aX = p3.x - p0.x - cX - bX;

    const cY = 3 * (p1.y - p0.y),
      bY = 3 * (p2.y - p1.y) - cY,
      aY = p3.y - p0.y - cY - bY;

    const x = aX * Math.pow(t, 3) + bX * Math.pow(t, 2) + cX * t + p0.x;

    const y = aY * Math.pow(t, 3) + bY * Math.pow(t, 2) + cY * t + p0.y;
    return { x: round2(x), y: round2(y) };
  }

  private getControlPoints = (bezierStringKey: string): [Point, Point] => {
    if (BezierCurve.predefinedCurves[bezierStringKey]) {
      return BezierCurve.predefinedCurves[bezierStringKey]!;
    }

    const [x1, y1, x2, y2] = bezierStringKey.split(',').map(value => parseFloat(value));

    const p1: Point = { x: x1 as number, y: y1 as number };
    const p2: Point = { x: x2 as number, y: y2 as number };
    return [p1, p2];
  };

  private ensureTimingValues = (timingCurve: string): Map<number, number> => {
    if (this.bezierCurveMap.has(timingCurve)) {
      return this.bezierCurveMap.get(timingCurve)!;
    }

    const bezierValueMap = new Map<number, number>();

    // In order to get values for each percent 0 -> 100 for any control points passed we must choose a very small increment of t
    const [p1, p2] = this.getControlPoints(timingCurve);

    for (let t = 0; t < 1; t += 0.001) {
      const { x, y }: Point = this.getBezierValues(t, p1, p2);

      bezierValueMap.set(x, y);
    }

    bezierValueMap.set(0, 0);
    bezierValueMap.set(1, 1);

    this.bezierCurveMap.set(timingCurve, bezierValueMap);
    return bezierValueMap;
  };

  public getBezierCurveValue = (timingCurve: string, input: number): number => {
    const roundedInput = round2(input);

    const precomputedValues = this.ensureTimingValues(timingCurve);
    return precomputedValues.get(roundedInput)!;
  };
}
