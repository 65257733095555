// Note that we allow referrers here so we can track usage.
// See https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md#when-to-override-it
/* eslint-disable react/jsx-no-target-blank */ // Placed here because it doesn't work inside JSX.
import { cx } from '@emotion/css';
import snakeCase from 'lodash/snakeCase';
import type { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { Children, useContext } from 'react';

import { dataSetToAttributes } from '../../../utils';
import { isExternal } from '../../../utils/domains';
import type { TrackingParameters } from '../../../utils/tracking';
import { appendTrackingParameters } from '../../../utils/tracking';
import { DetailsSummary } from '../../DetailsSummary';
import { Icon } from '../../Icon';
import { PrimitivesContext } from '../../Primitives';
import { globalHeaderAnimationDuration, globalNavLevelClassName } from '../GlobalHeader.constants';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { itemListCss } from '../GlobalNavGroup/GlobalNavGroup.styles';
import {
  externalIconCss,
  globalNavItemChevronCss,
  globalNavItemCss,
  globalNavItemWithChildrenCss,
} from './GlobalNavItem.styles';
import type { GlobalNavItemProps } from './types';

const defaultTrackingParameters: TrackingParameters = {
  utm_source: 'global_nav',
  utm_medium: 'referral',
  utm_content: 'global_nav_item',
  utm_campaign: 'universal_navigation',
};

const GlobalNavItemWithChildren: FC<PropsWithChildren<GlobalNavItemProps>> = ({
  title,
  children,
  isSelected,
}) => {
  return (
    <DetailsSummary
      summary={title}
      summaryProps={{ className: cx({ selected: isSelected }) }}
      chevronProps={{ className: globalNavItemChevronCss }}
      className={cx(globalNavLevelClassName, globalNavItemWithChildrenCss)}
    >
      <ol className={itemListCss}>
        {Children.map(children, child => (
          <li>{child}</li>
        ))}
      </ol>
    </DetailsSummary>
  );
};
GlobalNavItemWithChildren.displayName = 'GlobalNavItemWithChildren';

export const GlobalNavItem: FC<GlobalNavItemProps> = ({
  title,
  href,
  showExternalIcon,
  children,
  onClick,
  isSelected,
  dataset,
  addTrackingParams = true,
}) => {
  const { Anchor } = useContext(PrimitivesContext);

  const { navigationBreadcrumbs, toggleExpanded, trackingSiteName } =
    useContext(GlobalHeaderContext);
  const isSelectedBreadcrumb = href ? !!navigationBreadcrumbs?.has(href) : false;

  const shouldSelect = isSelected === undefined ? isSelectedBreadcrumb : isSelected;

  if (children && (!Array.isArray(children) || children.length)) {
    return (
      <GlobalNavItemWithChildren title={title} isSelected={isSelected}>
        {children}
      </GlobalNavItemWithChildren>
    );
  }

  const trackingParameters = trackingSiteName
    ? { ...defaultTrackingParameters, utm_source: snakeCase(trackingSiteName) }
    : defaultTrackingParameters;

  const trackedHref = appendTrackingParameters(href, trackingParameters);
  const isLinkExternal = isExternal(href);

  const onLinkActivate: MouseEventHandler<HTMLElement> = event => {
    onClick && onClick(event);

    // We close the nav if the link is internal AND CTRL isn't pressed.
    // The later is to allow multiple local links to be opened in a new tab.
    // And scroll to the top. New pages should start from the top.
    if (!isLinkExternal && !event.ctrlKey) {
      toggleExpanded && toggleExpanded();
      // Wait for globalHeader to close before scrolling back top of page
      setTimeout(() => window.scrollTo(0, 0), globalHeaderAnimationDuration);
    }
  };

  return (
    <div className={globalNavItemCss} {...dataSetToAttributes(dataset)}>
      <Anchor
        className={cx(globalNavLevelClassName, { selected: shouldSelect })}
        href={isLinkExternal && addTrackingParams ? trackedHref : href}
        onClick={onLinkActivate}
        target={isLinkExternal ? '_blank' : '_self'}
        rel="noopener"
      >
        {title}
      </Anchor>
      {showExternalIcon && isLinkExternal && (
        <Icon className={externalIconCss} name="external-link" />
      )}
    </div>
  );
};

GlobalNavItem.displayName = 'GlobalNavItem';
