import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import { Spinner } from '../../Spinner';
import { ChartToggleDropdown } from './ChartToggleDropdowns';
import { ChartTogglePanelsV2 } from './ChartTogglePanelsV2';
import type { ChartToggleItemProps, ChartToggleV2Props } from './types';

export const ChartToggleV2: FC<ChartToggleV2Props> = ({ items, label, isLoading }) => {
  const [selectedChart, selectChart] = useState('0');
  useMotifStyles(MotifComponent.CHART_TOGGLE);

  // Logic to call ChartToggleItemProps onToggle.
  // This is set on MW side and should log the dropdown change.
  useEffect(() => {
    if (!items?.length) {
      return;
    }

    const { onToggle } = items[Number(selectedChart)] as ChartToggleItemProps;
    onToggle && onToggle();
  }, [items, selectedChart]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!items?.length) {
    return null;
  }

  // We need to have this component as a direct child on the ChartToggleWrapper component.
  return (
    <>
      <ChartToggleDropdown
        label={label}
        items={items}
        selectedChart={selectedChart}
        selectChart={selectChart}
      />

      <ChartTogglePanelsV2 items={items} selectedChart={selectedChart} />
    </>
  );
};
