import { Black, Gray, Plain, White } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const darkThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  '--global-header-bg-color': Plain.BLACK,
  '--global-header-fg-color': Gray.V250,
  '--global-header-nav-screen-bg-color': Plain.BLACK,
  '--global-header-nav-screen-fg-color': Gray.V250,
  '--global-header-item-color': m('--action-default-color'),
  '--global-header-item-hover-color': m('--action-hover-color'),
  '--global-header-item-active-color': m('--action-active-color'),
  '--global-header-navigator-item-color': Gray.V200,
  '--global-header-navigator-item-hover-color': Gray.V150,
  '--global-header-navigator-item-active-color': White,
};

export const transparentDarkThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  ...darkThemeHeaderVars,
  '--global-header-bg-color': Plain.TRANSPARENT,
  '--global-header-nav-screen-bg-color': Plain.BLACK,
  '--global-header-navigator-item-color': Gray.V200,
  '--global-header-navigator-item-hover-color': Gray.V150,
  '--global-header-navigator-item-active-color': White,
};

export const lightThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  '--global-header-bg-color': Plain.WHITE,
  '--global-header-fg-color': Black.V100,
  '--global-header-nav-screen-bg-color': Plain.WHITE,
  '--global-header-nav-screen-fg-color': Black.V100,
  '--global-header-item-color': m('--action-default-color'),
  '--global-header-item-hover-color': m('--action-hover-color'),
  '--global-header-item-active-color': m('--action-active-color'),
  '--global-header-navigator-item-color': Black.V100,
  '--global-header-navigator-item-hover-color': Black.V200,
  '--global-header-navigator-item-active-color': Black.V200,
};

export const transparentLightThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  ...lightThemeHeaderVars,
  '--global-header-bg-color': Plain.TRANSPARENT,
  '--global-header-nav-screen-bg-color': Plain.WHITE,
  '--global-header-navigator-item-color': Black.V100,
  '--global-header-navigator-item-hover-color': Black.V200,
  '--global-header-navigator-item-active-color': Black.V200,
};
