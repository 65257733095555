// See https://support.google.com/analytics/answer/1033981
export interface TrackingParameters {
  utm_source: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

// Based on https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
export const isAbsoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i');

/**
 * Appends tracking parameters to the URL.
 *
 * Does nothing if the URL is empty or is relative.
 */
export const appendTrackingParameters = (
  href: string | undefined,
  parameters: TrackingParameters
): string | undefined => {
  if (!href || !isAbsoluteUrl.test(href)) {
    return href;
  }

  try {
    const url = new URL(href);

    Object.entries(parameters).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    return url.toString();
  } catch {
    return undefined;
  }
};
