import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as animationsFragments } from '../Animations/query';
import { ctaFragment } from '../CallToAction/query';
import { fragments as codeFragments } from '../Code';
import { fragments as footnoteFragments } from '../Footnote';
import { fragments as imageFragments } from '../Image';
import { assetFragment } from '../Media';
import { fragments as quoteFragments } from '../Quote';
import { snapchatEmbedFragment } from '../SnapchatEmbed';
import { fragments as videoFragments } from '../Video';

export const contentAssetFragment = gql`
  fragment ContentAssetAll on ContentAsset {
    ...ContentfulSysId
    ... on Video {
      ...VideoAll
    }
    ... on Image {
      ...ImageAll
    }
    ... on SnapchatEmbed {
      ...SnapchatEmbedAll
    }
  }
  ${contentfulSysIdFragment}
  ${imageFragments.all}
  ${videoFragments.all}
  ${snapchatEmbedFragment}
`;

export const fragments = {
  all: gql`
    fragment ContentAll on Content {
      ...ContentfulSysId
      title {
        json
      }
      subtitle {
        json
      }
      # CTA Collection limit has to be 3 or less for GraphQL query complexity issues.
      callsToActionCollection(limit: 3) {
        items {
          ... on CallToAction {
            ...CallToActionAll
          }
          ... on ImageButton {
            ...ContentfulSysId
          }
        }
      }
      showVideoControls
      mediaWrap
      layout
      mediaDirection
      titleAlignment
      titleAlignmentMobile
      bodyAlignment
      bodyAlignmentMobile
      useHeadingTagsForBody
      collapseMobileTable
      showFullResolutionMedia
      displayOn
      theme
      backgroundColor
      asset {
        ...ContentAssetAll
      }
      # Limit to 6 =  maximum number of targets + the container
      animationsCollection(limit: 6) {
        items {
          ... on ContentAnimation {
            ...ContentAnimationAll
          }
        }
      }
      body {
        json
        # TODO: There's an inline expansion into embedded content. Consider lazy-loading this
        # to reduce the query complexity for the typical use-case.
        links {
          entries {
            block {
              ...ContentfulSysId
              ... on CustomerQuote {
                ...CustomerQuoteAll
              }
              ... on CallToAction {
                ...CallToActionAll
              }
              ... on Code {
                ...CodeAll
              }
              ... on Image {
                ...ImageAll
              }
            }
            inline {
              ...ContentfulSysId
              ... on FootNotes {
                ...FootNotesAll
              }
              ... on CustomerQuote {
                ...CustomerQuoteAll
              }
              ... on CallToAction {
                ...CallToActionAll
              }
            }
            hyperlink {
              ...ContentfulSysId
              ... on Slug {
                slug
              }
            }
          }
          assets {
            block {
              ...AssetAll
            }
            hyperlink {
              ...AssetAll
            }
          }
        }
      }
    }
    ${assetFragment}
    ${ctaFragment}
    ${footnoteFragments.all}
    ${quoteFragments.all}
    ${videoFragments.all}
    ${imageFragments.all}
    ${codeFragments.all}
    ${animationsFragments.contentAll}
    ${contentAssetFragment}
  `,

  media: gql`
    fragment ContentMedia on Content {
      ...ContentfulSysId
      title {
        json
      }
      asset {
        ...ContentAssetAll
      }
    }
    ${contentfulSysIdFragment}
    ${contentAssetFragment}
  `,
};

export const queries = {
  all: gql`
    query ContentQuery($preview: Boolean!, $locale: String!, $id: String!) {
      content(preview: $preview, locale: $locale, id: $id) {
        ...ContentAll
      }
    }
    ${fragments.all}
  `,
};
