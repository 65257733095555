import { cx } from '@emotion/css';
import type { CookieProviderProps } from '@snapchat/mw-cookie-components/src/components/CookieProvider/CookieProvider';
import { CookieProvider } from '@snapchat/mw-cookie-components/src/components/CookieProvider/CookieProvider';
import type { CookieSettingsProps as InnerSettingsProps } from '@snapchat/mw-cookie-components/src/components/CookieSettings/CookieSettings'; // eslint-disable-line max-len
import { CookieSettings as CookieSettingsComponent } from '@snapchat/mw-cookie-components/src/components/CookieSettings/CookieSettings';
import { PrimitivesProvider } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { BackgroundType } from '../CookieModal/cookieModalTypes';
import { SdsmComponents } from '../CookieModal/SdsmComponents';
import { cookieSettingsPageCss, globalCss } from '../CookieModal/SdsmCookieModal.styles';

export interface CookieSettingsProps {
  backgroundType?: BackgroundType;
  /** @deprecated Can grab hostname from the global context */
  cookieDomain?: string;
}

export const CookieSettings: FC<CookieSettingsProps> = ({ backgroundType, cookieDomain }) => {
  // Construct props for <CookieProvider />
  const context = useContext(GlobalComponentsContext);
  const { Anchor, currentLocale, isPreview, isSSR, onEvent, onError, globalApolloClient } = context;

  cookieDomain ??= context.hostname;

  const providerProps: CookieProviderProps = {
    ...SdsmComponents,
    isSSR,
    isPreview,
    currentLocale,
    client: globalApolloClient,
  };

  // Construct props for <CookieSettingsComponent />
  const settingsProps: InnerSettingsProps = {
    backgroundType,
    cookieDomain,
    onEvent,
    onError,
  };

  return (
    <PrimitivesProvider value={{ Anchor }}>
      <div data-testid="mwp-cookie-settings" className={cx(globalCss, cookieSettingsPageCss)}>
        <CookieProvider {...providerProps}>
          <CookieSettingsComponent {...settingsProps} />
        </CookieProvider>
      </div>
    </PrimitivesProvider>
  );
};
