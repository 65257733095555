// TODO use Config.trackingSettings.cookieDomain
export const allowedDomains = new Set([
  '.snapchat.com',
  '.snap.com',
  '.arcadiacreativestudio.com',
  '.pixy.com',
  '.snappartnersummit.com',
  '.yellowla.com',
  // dev/staging
  '.snap-dev.net',
  '.sc-corp.net',
  'localhost',
]);
export const essentialSessionCookieName = 'EssentialSession';
export const webClientIdName = 'sc-wcid';
