import type { WithConditionalCSSProp } from '@emotion/react/types/jsx-namespace';
import type { Partition } from '@snapchat/graphene';
import type { ComponentType, FC, PropsWithChildren, ReactNode } from 'react';
import { Component } from 'react';

import { BrowserGrapheneClient } from '../../helpers/logging/BrowserGrapheneClient';

interface Props extends PropsWithChildren {
  component: string;
  hostname: string;
  partition: Partition;
  onError?: (error: Error | string) => void;
  renderInstead?: ReactNode;
}

interface State {
  hasError: boolean;
  grapheneClient?: BrowserGrapheneClient;
}

/**
 * ErrorBoundary used for the Cookie components. Ensures unhandled errors are logged to Graphene and
 * triggers onError callback if specified. NOTE: must be implemented as a class component. See this
 * page for details: https://reactjs.org/docs/error-boundaries.html
 *
 * Major change vs `global-components` implementation: Dropped support for server side logging. This
 * was causing build issues and since we don't expect to log anything server side it wasn't
 * necessary.
 */
export class BrowserErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { partition, hostname } = props;
    const client = partition && new BrowserGrapheneClient(partition, hostname);

    this.state = {
      hasError: false,
      grapheneClient: client,
    };
  }

  componentDidCatch(error: Error): void {
    this.setState({
      hasError: true,
    });

    // Log error to Graphene
    this.state.grapheneClient?.logError(this.props.component, 'unhandled', error);

    // If error handler is specified, pass the error to the handler
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render(): React.ReactNode {
    const { children, renderInstead } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // If there is an error, render the replacement content (renders nothing if not specified)
      return renderInstead ?? null;
    } else {
      return children;
    }
  }
}

/** Base Type that component props must inherit when using the `withErrorBoundary` function. */
export interface WithOnError {
  onError?: (error: Error | string) => void;
}

/**
 * Type used to get decorator function to pass typechecks. TODO: determine why this is necessary and
 * find a better workaround. This is UGLY. relevant github threads:
 * https://github.com/emotion-js/emotion/issues/2169
 * https://github.com/microsoft/TypeScript/issues/42240
 *
 * NOTE: might just be a matter of updating emotion and typescript versions?
 */
type WithEmotionProps<Props> = Props & WithConditionalCSSProp<Props> & JSX.IntrinsicAttributes;

/** Helper function for wrapping a single Component in the ErrorBoundary. */
export function withErrorBoundary<WrappedComponentProps extends WithOnError>(
  /** Component to wrap in the error boundary. */
  WrappedComponent: ComponentType<WrappedComponentProps>,
  /** Specifies the Graphene partition that unhandled errors will be logged to. */
  partition: Partition,
  /** Optional: if specified, renders this instead of the WrappedComponent when an error occurs. */
  renderInstead?: ReactNode
): FC<WithEmotionProps<WrappedComponentProps>> {
  const displayName = WrappedComponent.displayName ?? 'UnknownComponent';

  const ComponentWithBrowserErrorBoundary = (props: WithEmotionProps<WrappedComponentProps>) => {
    const hostname = (window?.location?.hostname ?? 'unknown').toLowerCase();
    const { onError } = props;
    return (
      <BrowserErrorBoundary
        hostname={hostname}
        partition={partition}
        component={displayName}
        onError={onError}
        renderInstead={renderInstead}
      >
        <WrappedComponent {...props} />
      </BrowserErrorBoundary>
    );
  };

  return ComponentWithBrowserErrorBoundary;
}
