import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import {
  BackgroundColor,
  BrowserFeaturesContext,
  Button,
  GlobalHeader as GlobalHeaderSDS,
  GlobalHeaderContext,
  GlobalNavItem as ItemMobileSDS,
  isMobileOs,
  ItemDesktop as ItemDesktopSDS,
  Logo as LogoSDS,
  mobileMaxWidth,
  PrimitivesProvider,
} from '@snapchat/snap-design-system-marketing';
import head from 'lodash-es/head';
import type { FC } from 'react';
import { Children, useContext, useMemo } from 'react';

import { Action } from '../../analytics/Action';
import { defaultLocaleCode, GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { GlobalNavAllType, GlobalNavConfigType } from '../../generated/contentful-types';
import { GlobalNavConfigCollectionQueryDocumentType } from '../../generated/contentful-types';
import { hightlightSizedSrcSets } from '../GlobalNavHighlight/GlobalNavHighlight';
import { GlobalNavGroupScreen } from '../GlobalNavScreen/GlobalNavScreen';
import type { MediaPreloadSettings } from '../Media/mediaUtils';
import { useMediaPreload } from '../Media/useMediaPreload';
import type { GlobalHeaderProps } from './types';

const defaultBackgroundColor = BackgroundColor.Black;
const defaultDefaultGroupKey = 'snapchat';

// Test to see if there are duplicate contexts.
// Explanation: We set this value here as a type of invariant. Users of this package
// Can see if this value is set to test if they have a different version of SDSM installed.
// TODO: Make a separate file containing setting and testing logic.
// Tracking: https://jira.sc-corp.net/browse/ENTWEB-4284
GlobalHeaderContext.displayName = 'GlobalHeaderContextSDSM';

const mediaPreloadSettings: MediaPreloadSettings = {
  sizedSrcSets: hightlightSizedSrcSets,
  skipPreload: () => {
    if (!window) return true;
    return window.innerWidth <= mobileMaxWidth;
  },
};

/** Navigation Header with a global nav available on click. */
export const GlobalHeader: FC<GlobalHeaderProps> = ({
  backgroundColor,
  className,
  defaultGroupKey,
  siteName,
  displayed,
  trackingSiteName,
  cta,
  ctaItems,
  localNavDesktop,
  localNavItems,
  logo,
  logoProps,
  localNavMobile,
  localNavMobileFooter,
  onToggleExpanded,
  search,
  searchOpen,
  endChildrenDesktopClassName,
  endChildrenMobileClassName,
  showGlobalLinks = true,
  showNavScreen,
  pathname,
  headerNavigationTree,
  navItemAlignment,
}) => {
  const context = useContext(GlobalComponentsContext);
  const { Anchor, onEvent } = context;

  const { data } = useGlobalComponentsContentfulQuery(
    GlobalNavConfigCollectionQueryDocumentType,
    {
      currentLocale: context.currentLocale ?? defaultLocaleCode,
      isPreview: context.isPreview ?? false,
      isSSR: context.isSSR ?? false,
    },
    { client: context.globalApolloClient }
  );

  const config = head(data?.globalNavConfigCollection.items) as GlobalNavConfigType;
  const navGroups = useMemo(
    () => config?.globalNavGroupsCollection.items ?? [],
    [config?.globalNavGroupsCollection.items]
  );

  const browserFeatures = useContext(BrowserFeaturesContext);
  const isMobile = isMobileOs(browserFeatures);

  // we have to memo this otherwise we "preload" on every render since its a new array every time
  const assetsToPreload = useMemo(
    () =>
      showGlobalLinks
        ? navGroups.map(item => item.highlight?.background).filter(asset => !!asset)
        : [],
    [navGroups, showGlobalLinks]
  );
  useMediaPreload(assetsToPreload, false, mediaPreloadSettings);

  // Track expansion.
  const onToggleExpandedWithLogging = (isExpanded: boolean): void => {
    onEvent &&
      onEvent({
        component: 'GlobalHeader',
        action: Action.Click,
        label: isExpanded ? 'expand' : 'collapse',
      });
    onToggleExpanded?.(isExpanded);
  };

  const callToActions = cta ?? (
    <>
      {Children.toArray(
        ctaItems?.map((item, id) => (
          <Button key={id} link={item.url} type={item.type} size="Compact" onClick={item.onClick}>
            {item.title}
          </Button>
        ))
      )}
    </>
  );

  // TODO: Remove the concept of "search" from this version. Does not belong in exported nav.
  const endChildren = searchOpen ? (
    search
  ) : (
    <>
      {callToActions}
      {search}
    </>
  );

  const defaultLogo = logoProps ? <LogoSDS {...logoProps} logoType={'Ghost'} /> : null;

  const shouldShowLogo = !(searchOpen && isMobile);

  const endChildrenClassName = isMobile ? endChildrenMobileClassName : endChildrenDesktopClassName;

  const mobileNavigation = localNavMobile ?? (
    <>
      {localNavItems?.map((item1, id1) => (
        <ItemMobileSDS
          key={id1}
          title={item1.title}
          href={'url' in item1 ? item1.url : undefined}
          target={'target' in item1 ? item1.target : undefined}
        >
          {'items' in item1
            ? item1.items.map((item2, id2) => (
                <ItemMobileSDS
                  key={`${id1}.${id2}`}
                  title={item2.title}
                  href={'url' in item2 ? item2.url : undefined}
                  target={'target' in item2 ? item2.target : undefined}
                >
                  {'items' in item2
                    ? item2.items.map((item3, id3) => (
                        <ItemMobileSDS
                          key={`${id1}.${id2}.${id3}`}
                          title={item3.title}
                          href={'url' in item3 ? item3.url : undefined}
                          target={'target' in item3 ? item3.target : undefined}
                        ></ItemMobileSDS>
                      ))
                    : undefined}
                </ItemMobileSDS>
              ))
            : undefined}
        </ItemMobileSDS>
      ))}
    </>
  );

  const desktopNavigation = localNavDesktop ?? (
    <>
      {localNavItems?.map((item1, id1) => (
        <ItemDesktopSDS
          id={String(id1)}
          key={id1}
          title={item1.title}
          url={'url' in item1 ? item1.url : undefined}
          target={'target' in item1 ? item1.target : undefined}
        >
          {'items' in item1
            ? item1.items.map((item2, id2) => (
                <ItemDesktopSDS
                  id={`${id1}.${id2}`}
                  key={`${id1}.${id2}`}
                  title={item2.title}
                  url={'url' in item2 ? item2.url : undefined}
                  target={'target' in item2 ? item2.target : undefined}
                >
                  {'items' in item2
                    ? item2.items.map((item3, id3) => (
                        <ItemDesktopSDS
                          id={`${id1}.${id2}.${id3}`}
                          key={`${id1}.${id2}.${id3}`}
                          title={item3.title}
                          url={'url' in item3 ? item3.url : undefined}
                          target={'target' in item3 ? item3.target : undefined}
                        ></ItemDesktopSDS>
                      ))
                    : undefined}
                </ItemDesktopSDS>
              ))
            : undefined}
        </ItemDesktopSDS>
      ))}
    </>
  );

  return (
    <PrimitivesProvider value={{ Anchor }}>
      <GlobalHeaderSDS
        className={className}
        siteName={siteName}
        backgroundColor={backgroundColor ?? defaultBackgroundColor}
        displayed={displayed}
        logo={shouldShowLogo && (logo ?? defaultLogo)}
        onToggleExpanded={onToggleExpandedWithLogging}
        cta={endChildren}
        localNavDesktop={desktopNavigation}
        defaultGroupKey={defaultGroupKey ?? defaultDefaultGroupKey}
        stayOpenInvariant={pathname}
        trackingSiteName={trackingSiteName ?? context.hostname}
        endChildrenClassName={searchOpen ? endChildrenClassName : undefined}
        showGlobalLinks={showGlobalLinks}
        showNavScreen={showNavScreen}
        isUrlCurrent={context.isUrlCurrent}
        headerNavigationTree={headerNavigationTree}
        navItemAlignment={navItemAlignment}
      >
        {config && (
          <GlobalNavGroupScreen
            backgroundColor={backgroundColor ?? defaultBackgroundColor}
            localNavMobile={mobileNavigation}
            localNavMobileFooter={localNavMobileFooter}
            globalNavHeading={config?.globalNavLabel}
            navGroups={navGroups as unknown as GlobalNavAllType[]}
            showMobileGlobalLinks={showGlobalLinks}
          />
        )}
      </GlobalHeaderSDS>
    </PrimitivesProvider>
  );
};

GlobalHeader.displayName = 'GlobalHeader';
