import { useContentfulImages } from '@snapchat/mw-global-components';
import type { MosaicFilterProps, MosaicTileProps } from '@snapchat/snap-design-system-marketing';
import { Mosaic as MosaicSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { UserAction } from '../../types/events';
import { ConsumerContext } from '../ConsumerContextProvider';
import { getMediaProps } from '../Video/Video';
import type { MosaicTilesDataProps } from './types';

export const Mosaic: FC<MosaicTilesDataProps> = props => {
  const consumerContext = useContext(ConsumerContext);
  const { getBestBgImgSrc, getImageSources } = useContentfulImages();

  const { logEvent } = consumerContext;

  const { tilesCollection, columns, mobileColumns, filtersCollection } = props;

  const tiles = tilesCollection.items.map(
    ({ video, preview, thumbnail, filtersCollection, analytics, ...item }) => {
      const poster = getImageSources(thumbnail.url, { image: { width: 768 } });
      const label = analytics?.label ?? item.sys.id;
      const component = 'MosaicTile';

      return {
        ...item,
        onClose: () => logEvent?.({ type: UserAction.Close, component, label }),
        onOpen: () => logEvent?.({ type: UserAction.Open, component, label }),
        onEnter: () => logEvent?.({ type: UserAction.Enter, component, label }),
        onLeave: () => logEvent?.({ type: UserAction.Leave, component, label }),
        filters: filtersCollection?.items?.map(i => i.sys.id),
        poster: {
          src: poster?.default,
          srcSet: poster?.defaultSrcSet,
        },
        video: video ? getMediaProps(video, getBestBgImgSrc) : undefined,
        preview: preview ? getMediaProps(preview, getBestBgImgSrc) : undefined,
      } as MosaicTileProps;
    }
  );

  const filters = filtersCollection.items?.map(
    ({ sys, isAll, ...filter }) => ({ id: isAll ? '' : sys.id, ...filter } as MosaicFilterProps)
  );

  return (
    <MosaicSDS tiles={tiles} columns={columns} mobileColumns={mobileColumns} filters={filters} />
  );
};
Mosaic.displayName = 'Mosaic';
