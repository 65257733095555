import noop from 'lodash/noop';
import type React from 'react';
import { createContext } from 'react';

import type { FormAction, FormState } from './Form.types';
import { initialFormState } from './formReducer';

export interface FormContextProps {
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
}

export const FormContext = createContext<FormContextProps>({
  state: initialFormState,
  dispatch: noop,
});
