import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const primitiveElementClassNames = {
  h1: 'sdsm-h1',
  h2: 'sdsm-h2',
  h3: 'sdsm-h3',
  h4: 'sdsm-h4',
  h5: 'sdsm-h5',
  h6: 'sdsm-h6',
  p: 'sdsm-p',
  ul: 'sdsm-ul',
  ol: 'sdsm-ol',
  li: 'sdsm-li',
};

export const boldCss = css`
  font-weight: 700;

  /* TODO: Remove or figure out why we have this. */
  *[dir='rtl'] & {
    font-weight: 600;
  }
`;

export const italicsCss = css`
  font-style: italic;
`;

export const underlineCss = css`
  text-decoration: underline;
`;

export const paragraphCss = css`
  margin-bottom: ${m('--spacing-s')};
`;

export const unorderedCss = css`
  padding-left: 20px; /* eyeball guestimate */
  text-align: left;

  *[dir='rtl'] & {
    direction: rtl;
    text-align: right;
    padding-right: 15px;
  }

  & .${primitiveElementClassNames.p} {
    margin-bottom: 0;
  }

  & li {
    list-style-type: disc;
    *[dir='rtl'] & {
      padding-right: 5px;
    }
  }
`;

export const orderedListCss = css`
  padding-left: 20px; /* see above */
  text-align: left;

  & .${primitiveElementClassNames.p} {
    margin-bottom: 0;
  }

  & li {
    list-style-type: decimal;
  }

  & li ol li {
    list-style-type: upper-latin;
  }

  & li ol li ol li {
    list-style-type: lower-roman;
  }

  *[dir='rtl'] & {
    direction: rtl;
    text-align: right;
    padding-right: 15px;
  }

  *[dir='rtl'] & li {
    padding-right: 5px;
  }
`;
