import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { languageFromLocale } from './language';

export const LanguageDirective: FC = () => {
  const appContext = useContext(AppContext);

  return (
    <Helmet>
      <html lang={languageFromLocale(appContext?.currentLocale)} />;
    </Helmet>
  );
};

LanguageDirective.displayName = 'LanguageDirective';
