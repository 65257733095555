import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../../utils/contentful';
import { Loading } from '../Loading';
import { GeoMap } from './GeoMap';
import { queries } from './query';
import type { GeoMapDataHandlerProps, GeoMapProps } from './types';

export const GeoMapShallow: FC<GeoMapProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;

  const { data, loading } = useContentfulQuery<GeoMapDataHandlerProps, ContentfulIdVariable>(
    queries.all,
    {
      skip: !id,
      variables: { id },
    }
  );

  if (loading) return <Loading />;
  if (!data) return null;

  // If chart data or value key was passed directly to this chart, use those instead of whatever is on the
  // visualization (this will be the case if this is a child of a Multi Visualization)
  const chartData = props.chartDataOverride ?? data.geoVisualization.chartData;
  const valueKey = props.valueKeyOverride ?? data.geoVisualization.valueKey;

  return (
    <GeoMap
      {...data.geoVisualization}
      width={props.width}
      chartTitle={props.chartTitle}
      chartData={chartData}
      valueKey={valueKey}
    />
  );
};
