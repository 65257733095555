import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import type {
  ExperimentsByIdsLinkingToSlugsData,
  PagesWithSubOrSideNavData,
  SlugsByIdsData,
} from './slugNavigationQuery';
import {
  experimentsByIdsLinkingToSlugs,
  pagesWithSubOrSideNavQuery,
  slugsByIdsQuery,
} from './slugNavigationQuery';

interface SlugToNavMap {
  sideNavMap: Map<string, ContentfulSysProps>;
  subNavMap: Map<string, ContentfulSysProps>;
}

/**
 * Looks up maps from /slug to the ids of SideNavigation and SubNavigation.
 *
 * Works even through experiments.
 *
 * Done as a reverse lookup to avoid loading ALL slugs which is slow on sites like forbusiness.
 */
export function useSlugToNavMap(): SlugToNavMap {
  const sideNavMap = new Map<string, ContentfulSysProps>();
  const subNavMap = new Map<string, ContentfulSysProps>();

  const pageToSideNav = new Map<string, ContentfulSysProps>();
  const pageToSubNav = new Map<string, ContentfulSysProps>();

  const slugIdToSideNav = new Map<string, ContentfulSysProps>();
  const slugIdToSubNav = new Map<string, ContentfulSysProps>();

  const experimentIdToSideNav = new Map<string, ContentfulSysProps>();
  const experimentIdToSubNav = new Map<string, ContentfulSysProps>();

  // STEP 1: Find all pages with navigation bars (Sub or Side).

  const { data: pagesWithNav } = useContentfulQuery<PagesWithSubOrSideNavData>(
    pagesWithSubOrSideNavQuery
  );

  for (const page of pagesWithNav?.pageCollection.items ?? []) {
    page.sideNavigation && pageToSideNav.set(page.sys.id, page.sideNavigation);
    page.subNavigation && pageToSubNav.set(page.sys.id, page.subNavigation);

    for (const parent of page.linkedFrom.entryCollection.items) {
      switch (parent.__typename) {
        case 'Experiment': {
          page.sideNavigation && experimentIdToSideNav.set(parent.sys.id, page.sideNavigation);
          page.subNavigation && experimentIdToSubNav.set(parent.sys.id, page.subNavigation);
          break;
        }

        case 'Slug': {
          page.sideNavigation && slugIdToSideNav.set(parent.sys.id, page.sideNavigation);
          page.subNavigation && slugIdToSubNav.set(parent.sys.id, page.subNavigation);
          break;
        }
      }
    }
  }

  // STEP 2: Lookup slug ids for experiments

  const experimentIds = new Set<string>([
    ...experimentIdToSideNav.keys(),
    ...experimentIdToSubNav.keys(),
  ]);
  const { data: slugIdsFromExperiments } = useContentfulQuery<
    ExperimentsByIdsLinkingToSlugsData,
    { ids: string[] }
  >(experimentsByIdsLinkingToSlugs, { variables: { ids: [...experimentIds] } });

  for (const experiment of slugIdsFromExperiments?.experimentCollection.items ?? []) {
    for (const link of experiment.linkedFrom.entryCollection.items) {
      if (link.__typename !== 'Slug') continue; // Sanity check.
      const sideNav = experimentIdToSideNav.get(experiment.sys.id);
      sideNav && slugIdToSideNav.set(link.sys.id, sideNav);
      const subNav = experimentIdToSubNav.get(experiment.sys.id);
      subNav && slugIdToSubNav.set(link.sys.id, subNav);
    }
  }

  // STEP 3: Lookup slugs from slug ids.
  const slugIds = new Set<string>([...slugIdToSideNav.keys(), ...slugIdToSubNav.keys()]);
  const { data: slugsFromSlugIds } = useContentfulQuery<SlugsByIdsData, { ids: string[] }>(
    slugsByIdsQuery,
    {
      variables: { ids: [...slugIds] },
    }
  );

  for (const slug of slugsFromSlugIds?.slugCollection.items ?? []) {
    const sideNav = slugIdToSideNav.get(slug.sys.id);
    sideNav && sideNavMap.set(slug.slug, sideNav);
    const subNav = slugIdToSubNav.get(slug.sys.id);
    subNav && subNavMap.set(slug.slug, subNav);
  }

  return { sideNavMap, subNavMap };
}
