import type {
  HttpOptions,
  NormalizedCacheObject,
  PossibleTypesMap,
  ServerError,
} from '@apollo/client';
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { parseError } from '@snapchat/core';
import { contentfulRetryCount } from '@snapchat/mw-common';
import type { ASTNode, GraphQLError } from 'graphql';
import { stripIgnoredCharacters } from 'graphql';

import { Config } from '../../config';
import type { ContentfulConfiguration } from '../../configTypes';
import { logError, logInfo } from '../../helpers/logging';
import { customFetch } from '../fetch/customFetch';
import { getTracer } from '../tracing';
import { createContentfulCache } from './ContentfulCache';
import { getQueryName } from './getQueryName';
import { requestLatencyLogLink } from './RequestLatencyLogLink';

const defaultUrl = 'https://unknown.url';

/** Contentful errors taken from: https://www.contentful.com/developers/docs/references/errors/ */
const contentfulNetworkErrorNames = new Map<number, string>()
  .set(400, 'BadRequest')
  .set(401, 'AccessTokenInvalid')
  .set(403, 'AccessDenied')
  .set(404, 'NotFound')
  .set(409, 'VersionMismatch')
  .set(422, 'ValidationFailed')
  .set(429, 'RateLimitExceeded')
  .set(500, 'ServerError')
  .set(502, 'Hibernated')
  .set(599, 'Unknown');

/** Returns HttpOptions for making requests to contentful. */
export const getContentfulHttpOptions = (config: ContentfulConfiguration): HttpOptions => {
  const uri = config.environmentName
    ? `https://graphql.contentful.com/content/v1/spaces/${config.spaceId}/environments/${config.environmentName}`
    : `https://graphql.contentful.com/content/v1/spaces/${config.spaceId}`;

  const headers: Record<string, string> = {
    Authorization: `Bearer ${Config.isPreview ? config.previewAccessToken : config.accessToken}`,
  };

  // Jul 7, 2023 Temporarily adding this because we want to debug
  // We need to monitor if this has any performance impact when we include this header
  if (!Config.isClient) {
    headers['fastly-debug'] = '1';
  }

  return {
    uri,
    credentials: 'same-origin',
    headers,
    fetch: customFetch,
    print(ast: ASTNode, originalPrint: (ast: ASTNode) => string) {
      return stripIgnoredCharacters(originalPrint(ast));
    },
  };
};

/**
 * Creates a new contentful apollo client. See ApolloLink documentation:
 * https://www.apollographql.com/docs/react/api/link/introduction/
 */
export const createContentfulClient = (
  config: ContentfulConfiguration,
  fragments: PossibleTypesMap,
  apolloState?: NormalizedCacheObject
): ApolloClient<NormalizedCacheObject> => {
  // Retry link that will retry failed requests.
  const retryLink = new RetryLink({
    delay: {
      max: 100 /* milliseconds */,
    },
    attempts: {
      max: contentfulRetryCount,
      retryIf: (error, operation) => {
        if (error) {
          const parsedError = parseError(error);
          const response = operation.getContext().response as Response | undefined;
          const responseCode = response?.status ?? 500;
          // NOTE: we can only retry retriable errors.
          // Other ones like 401 or 400 cannot be retried as we expect the result to be the same.
          const shouldRetry = responseCode === 429 || (responseCode >= 500 && responseCode < 600);

          if (shouldRetry) {
            logInfo({
              eventAction: 'RetryFetch',
              eventCategory: 'Apollo',
              eventLabel: getQueryName(operation.query),
              context: {
                responseCode: String(responseCode),
                errorMessage: parsedError.message,
                'x-contentful-region': response?.headers?.get('x-contentful-region'),
                'x-cache': response?.headers?.get('x-cache'),
                'x-contentful-request-id': response?.headers?.get('x-contentful-request-id'),
              },
            });
          }

          return shouldRetry;
        }

        return false;
      },
    },
  });

  // Link that will record errors whn they occur.
  const onErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error: GraphQLError) => {
        // Contentful can fire a number of errors and still return partial results - see table in reference link:
        // https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors/graphql-errors-explained
        const contentfulErrorCode: string | undefined = error.extensions?.contentful?.code;
        const response = operation.getContext().response as Response | undefined;

        logError({
          component: 'Contentful',
          error,
          // reusing existing action dimension to be consistent with other error events
          action: 'GraphQlError',
          message: error.message,
          context: {
            errorName: contentfulErrorCode?.toLowerCase() ?? 'unknown',
            variables: JSON.stringify(operation.variables),
            queryName: getQueryName(operation.query),
            queryPath: error.path?.join(','),
            locations: error.locations?.join(','),
            requestUrl: operation.getContext().currentUrl as string | undefined,
            'x-contentful-region': response?.headers?.get('x-contentful-region'),
            'x-cache': response?.headers?.get('x-cache'),
            'x-contentful-request-id': response?.headers?.get('x-contentful-request-id'),
          },
        });
      });
    }

    if (networkError) {
      const serverError =
        networkError.name === 'ServerError' ? (networkError as ServerError) : undefined;

      const response = operation.getContext().response as Response | undefined;
      const errorCode = serverError?.statusCode ?? 599;
      const errorName = contentfulNetworkErrorNames.get(errorCode); // This might be similar to 'networkError.name'.

      logError({
        component: 'Contentful',
        error: networkError,
        // reusing existing action dimension to be consistent with other error events
        action: 'NetworkError',
        message: `${errorName} (${errorCode})`,
        context: {
          name: networkError.name,
          errorCode,
          errorName,
          url: new URL(serverError?.response?.url ?? defaultUrl),
          requestUrl: operation.getContext().currentUrl,
          'x-contentful-region': response?.headers?.get('x-contentful-region'),
          'x-cache': response?.headers?.get('x-cache'),
          'x-contentful-request-id': response?.headers?.get('x-contentful-request-id'),
        },
      });
    }
  });

  /** Link that adds tracing spans out outpoing requests. */
  const tracingApolloLink = new ApolloLink((operation, forward) => {
    const traceSpan = getTracer().startSpan(`ApolloQuery: ${operation.operationName}`);
    return forward(operation).map(result => {
      traceSpan.endSpan();
      return result;
    });
  });

  const link: ApolloLink = ApolloLink.from([
    tracingApolloLink,
    requestLatencyLogLink,
    onErrorLink,
    retryLink,
    createHttpLink(getContentfulHttpOptions(config)),
  ]);

  const client = new ApolloClient({
    link,
    cache: createContentfulCache(fragments, apolloState),
    ssrMode: !Config.isClient, // This var is essentially isServer.
    ssrForceFetchDelay: Config.isSSR ? 100 : 0, // Duration of time to flash SSR content (In milliseconds)
  });

  return client;
};
