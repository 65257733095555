import { css } from '@emotion/css';
import type { SrcSetSettings } from '@snapchat/mw-common/client';
import { getDprSrcSetSettingsByHeight } from '@snapchat/mw-common/client';
import { useContentfulImages } from '@snapchat/mw-global-components';
import { m, Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import type React from 'react';
import { type FC, useContext, useState } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { UserAction } from '../../types/events';
import { getFileInfo } from '../../utils/getFileInfo';
import { ConsumerContext } from '../ConsumerContextProvider';
import { getVideoWatchLogger } from '../Video/utils';
import { query } from './query';
import type { MediaDataHandlerProps, MediaProps } from './types';

const isImageUrl = (contentType: string | null): boolean => {
  if (!contentType) return false;
  return contentType.startsWith('image');
};

const embeddedMediaCss = css`
  margin-bottom: ${m('--spacing-m')};
`;

/** Component to render generic media. Currently only used by embeds (see contentfulComponentMap) */
export const Media: FC<MediaProps> = props => {
  const { data } = useContentfulQuery<MediaDataHandlerProps, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
  });
  const { getImageSources } = useContentfulImages();
  const { logEvent } = useContext(ConsumerContext);
  const [videoPlayed, setVideoPlayed] = useState(false);
  let prevWatchEventTime = -1;

  if (!data) return null;
  const { asset } = data;

  if (!asset) return null;

  const { contentType, url, description } = asset;

  // case that its an image
  if (isImageUrl(contentType)) {
    // If the original image can be larger than 600, we allow handling 2x dpr lol
    const imgSrcSetSettings: SrcSetSettings = getDprSrcSetSettingsByHeight(
      600,
      data.asset.height ?? 0
    );

    const imgSrcs = getImageSources(url, imgSrcSetSettings);

    return (
      <MediaSDS
        className={props.isEmbedded ? embeddedMediaCss : undefined}
        imgSrcs={imgSrcs}
        altText={description}
      />
    );
  }

  // case that its a video. We default to no autoplay.
  const onPlay: React.ReactEventHandler<HTMLVideoElement> = _e => {
    if (logEvent && url && !videoPlayed) {
      const { fileName } = getFileInfo(url);
      logEvent({ component: 'Video', type: UserAction.Play, label: fileName });
      setVideoPlayed(true);
    }
  };

  const onTimeUpdate = getVideoWatchLogger({
    autoPlay: false,
    eventLabel: asset.sys.id,
    getPreviousWatchTime: () => {
      return prevWatchEventTime;
    },
    setPreviousWatchTime: value => {
      prevWatchEventTime = value;
    },
  });

  return (
    <MediaSDS
      sourceType={contentType}
      className={props.isEmbedded ? embeddedMediaCss : undefined}
      videoSource={url}
      showVideoControls={true}
      onPlay={onPlay}
      onTimeUpdate={onTimeUpdate}
      maxHeight={600}
      altText={description}
    />
  );
};

Media.displayName = 'Media';
