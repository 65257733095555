import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants/layout';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';
import { globalCss } from '../../utils/globalCss';

const blockBackgroundColor = m('--background-color');
const blockContentColor = m('--foreground-color');

export const carouselCss = css`
  ${mobileMediaQuery} {
    .react-multi-carousel-dot-list {
      bottom: ${m('--spacing-m')};
    }
  }
`;

/** CSS copied fromt he react-multi-carousel package. */
export const carouselStyleCss = globalCss`
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
    width: 100%;
  }

  .react-multi-carousel-tile-layout::before,
  .react-multi-carousel-tile-layout::after {
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    height: calc(100% - 40px);
    width: 64px;
    background: linear-gradient(to right, ${blockBackgroundColor} 0%, transparent 100%);
    z-index: ${ZIndex.CAROUSEL_SIDE_GRADIENT};
  }

  .react-multi-carousel-tile-layout::after {
    right: 0;
    left: unset;
    background: linear-gradient(to left, ${blockBackgroundColor} 0%, transparent 100%);
  }

  @media screen and (max-width: 767px) {
    .react-multi-carousel-tile-layout::before,
    .react-multi-carousel-tile-layout::after {
      display: none;
    }

    .wrap-react-multi-carousel-tile-layout {
      margin: 0;
      min-height: 600px;
    }

    .react-multi-carousel-tile-layout {
      position: relative;
      margin: 0;
      width: calc(100% + 215px);
      margin-left: -122px;
    }
  }

  .react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
  }

  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: ${ZIndex.CAROUSEL_ARROW};
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px);
  }

  .react-multi-carousel-dot-list {
    position: absolute;
    /** Aligned to have 16 (medium) spacing below */
    bottom: ${m('--spacing-m')};
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .react-multi-carousel-tile-layout .react-multi-carousel-dot-list {
    bottom: 0;
  }

  .react-multi-carousel-dot-icon {
    background: rgba(133, 141, 148, 0.5);
    border: none;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background 0.5s;
    border-width: 2px;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
  }

  .react-multi-carousel-dot button.react-multi-carousel-dot-icon {
    cursor: pointer;
  }

  .react-multi-carousel-dot--active .react-multi-carousel-dot-icon {
    background: ${blockContentColor};
  }

  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
      flex-shrink: 0 !important;
    }
    .react-multi-carousel-track {
      overflow: visible !important;
    }
  }
`;
