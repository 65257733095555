import { cx } from '@emotion/css';
import { localeMap } from '@snapchat/mw-common';
import { getTopLevelDomain } from '@snapchat/parse-domain';
import type { DropdownItem } from '@snapchat/snap-design-system-marketing';
import { DropdownMenu } from '@snapchat/snap-design-system-marketing';
import Cookies from 'js-cookie';
import type { FC } from 'react';
import { useCallback, useContext } from 'react';

import { Action } from '../../analytics/Action';
import type { LocaleOption } from '../../context/GlobalComponentsContext';
import {
  defaultLocaleCode,
  defaultSupportedLocales,
  GlobalComponentsContext,
} from '../../context/GlobalComponentsContext';
import { CategoryOptInCookie } from '../CookieModal/cookieModalTypes';
import { localeCookie } from './localeConstants';
import { localeDropdownCss } from './LocaleDropdown.styles';

export interface LocaleDropdownProps {
  className?: string;
  /** @deprecated Read from global context */
  supportedLocales?: Record<string, LocaleOption>;
  /** @deprecated Read from global context */
  currentLocale?: string;
  /** @deprecated Read from global context */
  onLocaleChange?: (locale: string) => void;
  /** @deprecated Does nothing */
  containerProvider?: (node: HTMLElement) => HTMLElement;
}

/**
 * Default implementation of the locale dropdown that sets a cookie value and triggers a callback
 * when the selected value changes.
 */
export const LocaleDropdown: FC<LocaleDropdownProps> = ({
  currentLocale,
  supportedLocales,
  onLocaleChange,
  className,
}) => {
  const context = useContext(GlobalComponentsContext);
  currentLocale ??= context.currentLocale;
  supportedLocales ??= context.supportedLocales ?? defaultSupportedLocales;
  onLocaleChange ??= context.onLocaleChange;
  const localeArray = Object.values(supportedLocales);

  const onSelect = useCallback(
    (item: DropdownItem) => {
      const localeCode = item.id;

      // we can only set locale cookie when preferences are enabled
      if (Cookies.getJSON(CategoryOptInCookie.Preferences) === true) {
        Cookies.set(localeCookie, localeCode, {
          // Note: We set the cookie on the base domain, so all websites on the *.snap.com domain
          // use the same cookie. Ditto for snapchat.com.
          // Also note that we use the &lang= parameter to transfer language setting between domains.
          domain: getTopLevelDomain(window.location.host),
          secure: true,
        });
      }

      context.onEvent?.({
        action: Action.LocaleSelect,
        component: 'LocaleDropdown',
        label: `Language: ${localeCode}`,
      });

      onLocaleChange?.(localeCode);
    },
    [context, onLocaleChange]
  );

  const dropdownItems: DropdownItem[] = localeArray.map(locale => {
    const displayText = localeMap[locale.code] ?? locale.name;
    return { id: locale.code, title: displayText };
  });

  return (
    <DropdownMenu
      items={dropdownItems}
      className={cx(localeDropdownCss, className)}
      data-testid="gc-locale-language"
      selectedItemId={currentLocale ?? defaultLocaleCode}
      onItemSelect={onSelect}
    />
  );
};

LocaleDropdown.displayName = 'LocaleDropdown';
