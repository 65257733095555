/* eslint-disable no-console */
import { cx } from '@emotion/css';
import isNil from 'lodash-es/isNil';
import type {
  CSSProperties,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from 'react';
import { useEffect } from 'react';

import { ToggleState, ToggleTarget, useToggleState } from '../../hooks/useToggleState';
import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import { Icon } from '../Icon';
import {
  chevronCss,
  detailsAnimationCss,
  detailsCss,
  detailsSummaryAnimationDurationCssVar,
  summaryCss,
} from './DetailsSummary.styles';
import type { DetailsSummaryProps } from './types';

export type DetailsSummaryHandle = {
  summaryRef: RefObject<HTMLElement>;
  detailsRef: RefObject<HTMLDetailsElement>;
};

export const DetailsSummary: FC<PropsWithChildren<DetailsSummaryProps>> = ({
  showChevron = true,
  chevronProps,
  onToggle,
  summary,
  summaryProps,
  children,
  className: detailsClassName,
  fadeInAnimation = true,
  transitionDurationMs,
  open: forceOpen,
  detailsRef,
  summaryRef,
  disableScrollToOnOpen,
  defaultControlledState = true,
  ...detailsProps
}) => {
  // If the 'forceOpen' is set it effectively takes state control away from this component.
  const parentHasStateControl = !isNil(forceOpen);
  useMotifStyles(MotifComponent.DETAIL_SUMMARY);

  const { state: isOpenState, toggle: toggleIsOpenInternal } = useToggleState({
    transitionDurationMs,
  });

  // If parent controls the open state, then we need to update internal state to match.
  useEffect(() => {
    if (!parentHasStateControl) {
      return;
    }

    toggleIsOpenInternal(forceOpen ? ToggleTarget.ON : ToggleTarget.OFF);
  }, [parentHasStateControl, forceOpen, toggleIsOpenInternal]);

  // If parent has no control, then only notify 'onToggle'.
  useEffect(() => {
    if (parentHasStateControl || !onToggle) {
      return;
    }

    onToggle(isOpenState === ToggleState.ON ? ToggleTarget.ON : ToggleTarget.OFF);
  }, [onToggle, isOpenState, parentHasStateControl]);

  const toggleWithAnimation: MouseEventHandler<HTMLDetailsElement> = event => {
    // Stop the toggle event from happening. We fire it off manually later.
    event.preventDefault();

    // This notifies the parent of a click event and doesn't change internal state.
    if (parentHasStateControl) {
      onToggle?.(forceOpen ? ToggleTarget.OFF : ToggleTarget.ON);
    } else {
      toggleIsOpenInternal();
      // NOTE: 'onToggle' is notified separately (see above).
    }
  };

  /**
   * Keeps the details open if parent has control and internal state isn't OFF.
   *
   * Note that keep open=true always so any controller needs to control the visibility as well. We
   * did it like this so that we could use transition animation without internal state messing up
   * the animation timing.
   */
  const isNativeOpen = parentHasStateControl
    ? defaultControlledState
    : isOpenState !== ToggleState.OFF;

  const scrollIntoViewIfOpen: MouseEventHandler<HTMLDetailsElement> = event => {
    // we need this because sometimes this triggers when it shouldn't when its being used as a
    // controlled component. AccordionItem for example, where on initial render, isNativeOpen is
    // always true, but AccordionItem will set open to false in it's useEffect, which will trigger
    // the onToggle handler, which for some reason only sometimes has currentTarget.open... idk why.
    // repro - 4biz: '/' -> '/advertising/objectives/awareness' will usually trigger.
    // related ticket: https://jira.sc-corp.net/browse/ENTWEB-6899
    const isActuallyOpen = parentHasStateControl ? forceOpen : event.currentTarget.open;

    // Sometimes expanding the last section can expand to below the fold. This ensures
    // that the expanded content is visible.
    if (isActuallyOpen) {
      event.persist(); // Allows us to reference `event.currentTarget` after the event.
      const target = event.currentTarget;
      requestAnimationFrame(() => target.scrollIntoView({ behavior: 'smooth', block: 'nearest' }));
    }
  };

  const { className: summaryClassName, dataset, ...otherSummaryProps } = summaryProps ?? {};

  const getTitle = (summary: ReactNode) => {
    if (typeof summary !== 'string') return undefined;

    return summary;
  };

  return (
    <details
      ref={detailsRef}
      className={cx(MotifComponent.DETAIL_SUMMARY, detailsCss, detailsClassName, {
        [detailsAnimationCss]: fadeInAnimation,
      })}
      onToggle={disableScrollToOnOpen ? undefined : scrollIntoViewIfOpen}
      open={isNativeOpen}
      data-state={isOpenState}
      {...detailsProps}
      style={
        {
          ...detailsProps.style,
          [detailsSummaryAnimationDurationCssVar]: `${transitionDurationMs ?? 250}ms`,
        } as CSSProperties
      }
    >
      <summary
        ref={summaryRef}
        role="button"
        tabIndex={0}
        title={getTitle(summary)}
        className={cx(summaryClassName, summaryCss)}
        onClick={toggleWithAnimation}
        {...dataSetToAttributes(dataset)}
        {...otherSummaryProps}
      >
        {summary}
        {showChevron && (
          <Icon
            className={cx(chevronCss, chevronProps?.className)}
            name="chevron-down"
            fill={chevronProps?.fill}
          />
        )}
      </summary>
      {children}
    </details>
  );
};
DetailsSummary.displayName = 'DetailsSummary';
