import { Superscript } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { isContentfulIdProps } from '../../utils/contentful';
import { query } from './query';
import type { FootnoteDataHandlerProps, FootnoteProps } from './types';

export const FootnoteIndex: FC<FootnoteProps> = props => {
  const hasData = !!props.number;
  const { data } = useContentfulQuery<FootnoteDataHandlerProps, ContentfulIdVariable>(query, {
    variables: { id: isContentfulIdProps(props) ? props.id : props.sys.id },
    skip: hasData,
  });

  const result = hasData ? props : data?.footNotes;
  if (!result) return null;
  return <Superscript>{result.number}</Superscript>;
};

FootnoteIndex.displayName = 'FootnoteIndex';
