import { gdprCountries } from '@snapchat/mw-common';
import { getTopLevelDomain } from '@snapchat/parse-domain';

import type { UserLocation } from '../../AppContext';
import type { CookieManager, CookieOptions } from '../cookies/CookieManager';
import type { CreateUuidV4 } from '../uuid/uuidFactory';
import {
  allowedDomains,
  essentialSessionCookieName,
  webClientIdName,
} from './webClientIdConstants';

type EnsureWebClientIdArgs = {
  currentUrl: URL;
  userLocation: UserLocation;
  cookieManager: CookieManager;
  createUuidV4: CreateUuidV4;
};

/**
 * Returns the current `sc-wcid` value.
 *
 * If one doesn't exist, creates it and sets the cookie.
 *
 * If one isn't allowed to be stored, returns undefined.
 *
 * This code is inteded to be used on server and client-side, so all of the implementation details
 * are abstracted.
 *
 * This is inspired by
 * https://github.sc-corp.net/Snapchat/web/blob/main/common/sc-cookies/lib/sc-cookies.ts
 */
export function ensureWebClientId(args: EnsureWebClientIdArgs): string | undefined {
  const { currentUrl, userLocation, cookieManager, createUuidV4: createUuidV4 } = args;

  // Case 1: user has opted out of Essential Session we do nothing
  if (cookieManager.getCookie(essentialSessionCookieName) === 'false') {
    return;
  }

  // Case 2: Cookie already exists
  const existingCookie = cookieManager.getCookie(webClientIdName);

  if (existingCookie) {
    return existingCookie;
  }

  // Case 3: Cookie needs to be set
  const isGdpr = userLocation.country ? gdprCountries.has(userLocation.country) : true;
  const expires = new Date();

  if (isGdpr) {
    // For GDPR countries, the expiry of the web_client_id should be set to 24 hours
    expires.setDate(expires.getDate() + 1);
  } else {
    expires.setFullYear(expires.getFullYear() + 1);
  }

  let cookieDomain = getTopLevelDomain(currentUrl.hostname);
  // need leading .
  if (cookieDomain !== 'localhost') cookieDomain = `.${cookieDomain}`;
  // handle staging special case
  if (cookieDomain === '.appspot.com') cookieDomain = '.sc-corp.net';

  const cookieOptions: CookieOptions = {
    expires: new Date(expires),
    path: '/', // Is this necessary?
    secure: true,
    httpOnly: false,
    credentials: 'same-origin',
    domain: allowedDomains.has(cookieDomain) ? cookieDomain : undefined,
  };

  const webClientId = createUuidV4();

  cookieManager.setCookie(webClientIdName, webClientId, cookieOptions);

  return webClientId;
}
