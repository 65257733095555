import { cx } from '@emotion/css';
import type { DesignAgnosticModalProps } from '@snapchat/mw-cookie-components/src/components/designAgnostic/DesignAgnosticModal';
import {
  getBackgroundClassName,
  Modal,
  smallMobileMaxWidth,
  useMediaMode,
  useWindowSize,
  VerticalAlignment,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { contentCss, cookieModalCss, globalCss } from './SdsmCookieModal.styles';

/** SdsmModal with positioning logic and emotion styles used for rendering the Cookie Modal */
export const SdsmCookieModal: FC<DesignAgnosticModalProps> = ({
  backgroundType,
  className,
  children,
  isDisplayed,
  portalRoot,
}) => {
  const mode = useMediaMode();
  const { width: windowWidth } = useWindowSize();
  const [modalAlignment, setModalAlignment] = useState<VerticalAlignment>(VerticalAlignment.Middle);

  // Set Modal positioning
  useEffect(() => {
    let alignment = VerticalAlignment.Middle;

    if (mode === 'Mobile' && (windowWidth ?? Number.POSITIVE_INFINITY) <= smallMobileMaxWidth) {
      alignment = VerticalAlignment.Bottom;
    }
    setModalAlignment(alignment);
  }, [mode, windowWidth, setModalAlignment]);

  return (
    <Modal
      isBlocking={true}
      disableBackgroundScroll={true}
      verticalAlignment={modalAlignment}
      isDisplayed={isDisplayed}
      portalRoot={portalRoot}
      className={cx(globalCss, getBackgroundClassName(backgroundType), cookieModalCss, className)}
      contentClassName={cx(contentCss)}
    >
      {children}
    </Modal>
  );
};
