import uniq from 'lodash-es/uniq';

import type { BarData } from './types';

export const defaultMargin = {
  top: 20,
  right: 65,
  bottom: 20,
  left: 20,
  leftWhenNoYAxisLabels: 150,
};

export const chartWrapperPadding = {
  mobile: 0,
  desktop: 150,
};
export const minimumDesktopChartSizePx = 500;
export const minimumMobileChartSizePx = 315;

export function mapStringToNumber(value: unknown): number {
  const mappedValue = Number(value);

  if (isNaN(mappedValue)) {
    return 0;
  }

  return mappedValue;
}

export function toLowercaseAndTrim(value?: unknown): string {
  if (!value) {
    return '';
  }

  const newValue = String(value).toLowerCase().trim();

  return newValue;
}

export function getLegendLabels(data: BarData, property: string): string[] {
  const legends = uniq(data.map(item => item[property]));
  return legends as string[];
}

export function getLegendColors(legends: string[], colors: string[]): string[] {
  const legendColors = [...colors];
  let i = 0;

  while (legendColors.length < legends.length) {
    legendColors.push(colors[i]!);
    i = (i + 1) % colors.length;
  }

  return legendColors;
}

export function formatBarLabel(
  value: number | string,
  showAsPercent = true,
  numberOfDecimalPlaces = 1
): string {
  const valueAsNumber = Number(value);
  let newValue: string;

  if (showAsPercent && valueAsNumber > 0 && valueAsNumber < 1) {
    newValue = '< 1';
  } else if (valueAsNumber === 0) {
    newValue = '0';
  } else {
    newValue = Number(value).toFixed(numberOfDecimalPlaces);
  }

  return showAsPercent ? `${newValue}%` : newValue;
}
