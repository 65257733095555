import { Black, Gray, Plain, Yellow } from '../../constants';
import { type MotifComponent, type MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const lightThemeDefaultVars = {
  '--side-navigation-desktop-box-shadow': m('--box-shadow-s'),
  '--side-navigation-mobile-bar-bg-color': Gray.V150,
  '--side-navigation-mobile-bar-fg-color': Plain.BLACK,
  '--side-navigation-mobile-bg-color': Gray.V100,
  '--side-navigation-mobile-fg-active-color': Black.V200,
  '--side-navigation-mobile-fg-color': Black.V100,
  '--side-navigation-mobile-toggler-icon-color': Plain.BLACK,
} as const;

export const blackThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  '--side-navigation-desktop-bar-bg-color': '#222425',
  '--side-navigation-desktop-box-shadow': '0px 0px 0px 0 rgba(0, 0, 0, 0)',
  '--side-navigation-mobile-bar-bg-color': Black.V150,
  '--side-navigation-mobile-bar-fg-color': Plain.WHITE,
  '--side-navigation-mobile-bg-color': Gray.V100,
  '--side-navigation-mobile-fg-active-color': Black.V200,
  '--side-navigation-mobile-fg-color': Black.V100,
  '--side-navigation-mobile-toggler-icon-color': Plain.WHITE,
};

export const whiteThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': Plain.WHITE,
};

export const grayThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': Gray.V100,
};

export const yellowThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': Yellow.V100,
};
