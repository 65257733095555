import type { ChartToggleItemProps } from '@snapchat/snap-design-system-marketing';
import {
  ChartToggle as ChartToggleSDS,
  ChartToggleV2 as ChartToggleV2SDS,
  Spinner,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../../types/contentful';
import { UserAction } from '../../../types/events';
import { ConsumerContext } from '../../ConsumerContextProvider';
import { Feature, useFeatureFlags } from '../../FeatureFlags';
import { BarChartShallow } from '../BarChart';
import { GeoMapShallow } from '../GeoMap';
import { LineChartShallow } from '../LineChart';
import { TableShallow } from '../Table';
import type { ChartToggleItemDataProps } from './query';
import { chartToggleQuery } from './query';
import type { ChartToggleProps } from './types';

export const Chart: FC<ContentfulSysProps> = props => {
  switch (props.__typename) {
    case 'ChartToggle': {
      return <ChartToggle {...props} />;
    }

    case 'BarChart': {
      return <BarChartShallow {...props} />;
    }

    case 'LineChart': {
      return <LineChartShallow {...props} />;
    }

    case 'GeoVisualization': {
      return <GeoMapShallow {...props} />;
    }

    case 'TableVisualization': {
      return <TableShallow {...props} />;
    }

    default: {
      console.error(`📙 Unknown chart type: ${props.__typename} with id: ${props.sys.id}`);
      return <>📙</>;
    }
  }
};
Chart.displayName = 'Chart';

export const ChartToggle: FC<ContentfulSysProps> = props => {
  const featureFlags = useFeatureFlags();
  const useDropdowns = featureFlags[Feature.USE_CHART_DROPDOWN_TOGGLE] === 'true';

  const { data, loading } = useContentfulQuery<ChartToggleProps, ContentfulIdVariable>(
    chartToggleQuery,
    {
      variables: { id: props.sys.id },
    }
  );
  const { logEvent } = useContext(ConsumerContext);

  // This loading element is temporary. Waiting for skeleton.
  if (loading)
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: 80,
          justifyContent: 'center',
          minWidth: 160,
          width: 'initial',
        }}
      >
        <Spinner />
      </div>
    );
  if (!data && !useDropdowns) return null;

  const itemsProps = data?.chartToggle.itemsCollection.items.map(
    ({ analytics, title, contentsCollection, sys: { id } }: ChartToggleItemDataProps) => {
      const onToggle = logEvent
        ? () =>
            logEvent({
              type: UserAction.Open,
              component: 'ChartToggle',
              label: analytics?.label ?? id,
            })
        : undefined;

      return {
        onToggle,
        title,
        content: contentsCollection.items.map(contents => (
          <Chart key={contents.sys.id} {...contents} />
        )),
      } as ChartToggleItemProps;
    }
  );

  if (useDropdowns) {
    return (
      <ChartToggleV2SDS
        items={itemsProps ?? []}
        label={data?.chartToggle.label ?? ''}
        isLoading={loading}
      />
    );
  } else {
    return <ChartToggleSDS items={itemsProps ?? []} />;
  }
};

ChartToggle.displayName = 'ChartToggle';
