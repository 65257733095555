import { dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import isNil from 'lodash-es/isNil';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';

import { logError } from '../../../../helpers/logging';
import type { ContentfulSysProps } from '../../../../types/contentful';
import { CheeriosButton } from '../CheeriosButton/CheeriosButton';
import { CheeriosImage } from '../CheeriosImage';
import { CheeriosMultiVideoBlock } from '../CheeriosMultiVideoBlock/CheeriosMultiVideoBlock';
import type { ProgressChangeEvent } from '../CheeriosPamphlet/CheeriosPamphletContext';
import { CheeriosPamphletContext } from '../CheeriosPamphlet/CheeriosPamphletContext';
import { pamphletSectionProgressVar } from '../CheeriosPamphlet/styles';
import { BezierCurve } from '../CheeriosPamphlet/utils/bezierCurve';
import { CheeriosScrollBy } from '../CheeriosScrollBy/CheeriosScrollBy';
import { CheeriosText } from '../CheeriosText';
import { CheeriosVideo } from '../CheeriosVideo/CheeriosVideo';
import { cheeriosFrameCss } from './styles';
import type { CheeriosContentType, CheeriosFrameProps, CheeriosLayerProps } from './types';

const getProgressProp = <T extends string | number>(
  start: T | undefined,
  end: T | undefined,
  bezierProgress: number | undefined
): string | undefined => {
  if (isNil(start) && isNil(end)) {
    return undefined;
  }

  // Use singular value when the value does not depend on progress
  if ((!isNil(start) && isNil(end)) || (isNil(start) && !isNil(end)) || start === end) {
    return String(isNil(end) ? start : end);
  }

  if (!isNil(bezierProgress)) {
    const startBez = `calc(${start} * calc(1 - ${bezierProgress}))`;
    const endBez = `calc(${end} * ${bezierProgress})`;
    return `calc(${startBez} + ${endBez})`;
  }

  const startContribution = `calc(${start} * calc(1 - var(${pamphletSectionProgressVar})))`;
  const endContribution = `calc(${end} * var(${pamphletSectionProgressVar}))`;
  return `calc(${startContribution} + ${endContribution})`;
};

export interface CheeriosLayerMetadata {
  frameStartIndex: number;
  frameCount: number;
}

interface Props extends Pick<CheeriosLayerProps, 'cssProperties' | 'title'> {
  metadata: CheeriosLayerMetadata;
  content: CheeriosContentType;
  startFrame: CheeriosFrameProps;
  endFrame: CheeriosFrameProps;
  isMobile: boolean;
}

/**
 * A layer in a cheerios section.
 *
 * A layer is differentiated by a z-index so when objects are animated we can force a render order.
 */
export const CheeriosLayer: FC<Props> = props => {
  const properties: CSSProperties = {
    ...props.cssProperties,
  };

  const [progress, setProgress] = useState(0);
  const pamphletContext = useContext(CheeriosPamphletContext);

  useEffect(() => {
    const listener = (event: ProgressChangeEvent) => {
      setProgress(event.progress);
    };
    pamphletContext.addProgressChangeListener(listener);

    return () => {
      pamphletContext.removeProgressChangeListener(listener);
    };
  }, [pamphletContext]);

  let bezierProgress: number | undefined;

  if (props.startFrame.transitionTimingFunction) {
    bezierProgress = BezierCurve.getInstance().getBezierCurveValue(
      props.startFrame.transitionTimingFunction,
      progress
    );
  }

  // Handles scalar properties.
  const scalarProperties = ['opacity', 'width', 'height', 'top', 'left'] as const;

  for (const property of scalarProperties) {
    const calculatedProp = getProgressProp(
      props.startFrame[property],
      props.endFrame[property],
      bezierProgress
    );

    if (properties[property] && calculatedProp) {
      logError({
        component: 'CheeriosLayer',
        message: `Property '${property}' is defined as a CSS property AND as a property to animate`,
        context: {
          contentType: props.content.__typename,
          sysId: props.content.sys.id,
        },
      });
    }
    properties[property] = calculatedProp ?? properties[property];
  }

  let transformScale: string | undefined;

  if (props.startFrame?.scale) {
    const scale = getProgressProp(props.startFrame.scale, props.endFrame.scale, bezierProgress);
    transformScale = `scale(${scale})`;
  }

  let transformRotate: string | undefined;

  if (props.startFrame?.rotate) {
    const rotate = getProgressProp(
      `${props.startFrame.rotate}`,
      `${props.endFrame.rotate}`,
      bezierProgress
    );
    transformRotate = `rotate(${rotate})`;
  }

  properties.transform = [properties.transform, transformScale, transformRotate]
    .filter(x => !!x)
    .join(' ');

  const dataset: DOMStringMap = {
    offsetTop: props.endFrame.top,
    offsetLeft: props.endFrame.left,
    sysId: props.content.sys.id,
    title: props.title,
  };

  // Renders content
  let renderedContent: ReactNode = null;

  switch (props.content.__typename) {
    case 'Image':
      renderedContent = (
        <CheeriosImage
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
          {...props.content}
        />
      );
      break;

    case 'Video': {
      renderedContent = (
        <CheeriosVideo
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
          videoSourcesCollection={{ items: [props.content.media] }}
          thumbnailImage={props.content.thumbnailMedia}
          sysId={props.content.sys.id}
          {...props.content}
        />
      );
      break;
    }

    case 'CheeriosVideo': {
      renderedContent = (
        <CheeriosVideo
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
          sysId={props.content.sys.id}
          {...props.content}
        />
      );
      break;
    }

    case 'CheeriosMultiVideoBlock': {
      properties.position = 'absolute';
      renderedContent = <CheeriosMultiVideoBlock style={properties} {...props.content} />;
      break;
    }

    case 'CheeriosText': {
      renderedContent = (
        <CheeriosText
          text={props.content.text ?? ''}
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
        />
      );
      break;
    }

    case 'CheeriosScrollBy': {
      renderedContent = (
        <CheeriosScrollBy
          text={props.content.cheeriosText.text ?? ''}
          scrollAmount={props.content.scrollAmount ?? ''}
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
        />
      );
      break;
    }

    case 'Button': {
      renderedContent = (
        <CheeriosButton
          {...props.content}
          style={properties}
          className={cheeriosFrameCss}
          dataset={dataset}
        />
      );
      break;
    }

    case 'ImageSequenceSource': {
      renderedContent = <>ImageSequence is no longer supported</>;
      break;
    }

    default: {
      const content = props.content as ContentfulSysProps;

      renderedContent = (
        <div {...dataSetToAttributes(dataset)} className={cheeriosFrameCss} style={properties}>
          Not renderable content {content.__typename}.
        </div>
      );
      break;
    }
  }

  return <>{renderedContent}</>;
};
