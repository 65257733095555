import flatMap from 'lodash-es/flatMap';
import unescape from 'lodash-es/unescape';
import type { ReactElement, ReactNode } from 'react';

export const convertNewLineToBr = (text: string): (string | ReactElement)[] => {
  return flatMap(text.split('\n'), (text, i) =>
    i > 0
      ? [
          <>
            <br />
          </>,
          text,
        ]
      : [text]
  );
};

// TODO: Remove this hack.
export const retainLtrText = (text: string | ReactElement): ReactNode => {
  if (typeof text === 'string' && text.includes('Snap Inc.')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return text.split('Snap Inc.').reduce((children: any[], textSegment: string, index: number) => {
      return [
        ...children,
        index < children.length && (
          <span dir="ltr" key={textSegment}>
            Snap Inc.
          </span>
        ),
        unescape(textSegment),
      ];
    }, []);
  }

  const textToReturn = typeof text === 'string' ? unescape(text) : text;
  return [textToReturn];
};

/**
 * Inserts line breaks where new lines exist and other shananagans.
 *
 * TODO: Remove the ltr hack for snap inc. This code is horrible and should probably be fixed w/
 * non-break word markers instead (i.e. regex replace).
 */
export const renderMultiLineText: (text?: string) => ReactNode = (text = '') => {
  const textWithLineBreaks = convertNewLineToBr(text);
  const preservedLtrText = flatMap(textWithLineBreaks, retainLtrText);
  return preservedLtrText;
};
