/* eslint-disable max-len */
import { css, cx } from '@emotion/css';
import type { FC } from 'react';

import { mobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';

export const arrowWidth = 48;
export const arrowHeight = 48;

// These properties are required to get the arrows to render correctly.
// They were derived from cracking open the inspector for the default arrows.
const arrowCss = css`
  position: absolute;
  cursor: pointer;
  width: ${arrowWidth}px;
  height: ${arrowHeight}px;
  left: 32px;
  z-index: ${ZIndex.CAROUSEL_ARROW};

  ${mobileMediaQuery} {
    display: none;
  }
`;

const rightCss = css`
  right: 32px;
  left: unset;
  z-index: ${ZIndex.CAROUSEL_ARROW};
  transform: rotate(180deg);
`;

export interface CarouselArrowProps {
  direction: 'Left' | 'Right';
  onClick?: () => void;
  // TODO(jm): this property will be used in the left: calcs above to
  // support other content sizes. It's a number | string because we
  // also want to accept values like "100%"
  contentWidth?: number | string;
}

export const CarouselArrow: FC<CarouselArrowProps> = ({ direction, onClick }) => {
  return (
    <div className={cx(arrowCss, direction === 'Right' && rightCss)}>
      <svg onClick={onClick} width={arrowWidth} height={arrowHeight} fill="none">
        <g>
          <path
            d="m0.96596 24.149c0-12.804 10.379-23.183 23.183-23.183 12.804 0 23.183 10.379 23.183 23.183 0 12.804-10.379 23.183-23.183 23.183-12.804 0-23.183-10.379-23.183-23.183z"
            fill="#fff"
          />
          <path
            d="m22.91 19.007-1.3672-1.3649-6.4964 6.5072 6.4964 6.5072 1.3672-1.3649-4.1694-4.1763h14.102v-1.9319h-14.102l4.1694-4.1763z"
            fill="#3A3E41"
            clipRule="evenodd"
            fillRule="evenodd"
          />
          <path
            d="m24.149 46.366c-12.27 0-22.217-9.9469-22.217-22.217h-1.9319c0 13.337 10.812 24.149 24.149 24.149v-1.9319zm22.217-22.217c0 12.27-9.9469 22.217-22.217 22.217v1.9319c13.337 0 24.149-10.812 24.149-24.149h-1.9319zm-22.217-22.217c12.27 0 22.217 9.9469 22.217 22.217h1.9319c0-13.337-10.812-24.149-24.149-24.149v1.9319zm0-1.9319c-13.337 0-24.149 10.812-24.149 24.149h1.9319c0-12.27 9.9469-22.217 22.217-22.217v-1.9319z"
            fill="#858D94"
          />
        </g>
      </svg>
    </div>
  );
};

CarouselArrow.displayName = 'CarouselArrow';
