import { css } from '@emotion/css';
import { m, maxWidth, ZIndex } from '@snapchat/snap-design-system-marketing';

import { richTextCtaClassName } from '../../utils/contentfulComponentMap';
import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';

/** Used to apply the background color set by Props or Site Config. */
export const defaultPageBackgroundCss = css`
  background-color: ${m('--background-color')};
`;

/**
 * Used to ensure consistent rendering of page since some of our breaks have different widths and
 * cause inconsistent rednering without this.
 */
export const pageSideNavCss = css`
  flex-grow: 1;
  max-width: ${maxWidth}px;
`;

export const pageStickyCss = css`
  position: sticky;
  top: var(${totalHeaderHeightCssVar});
  z-index: ${ZIndex.SUB_NAVIGATION};
`;

export const bannerContentContainerCss = css`
  line-height: 20px;
  white-space: pre-wrap;

  /* TODO: Swap this rule when use a proper <p> element for paragraphs */
  .sdsm-p {
    margin-bottom: 0;
  }

  .${richTextCtaClassName} {
    font-size: 14px;
    /*
    need to unset lineheight because it messes with alignment :(
    also need vertical align bottom to make it actually aligned for some reason
    */
    line-height: unset;
    vertical-align: bottom;

    /*
    hack because icons mess up alignment due to their taller size and
    we don't want to use flex because the text nodes won't be able to wrap
    properly then
    */
    i {
      font-size: 14px;
      transform: scale(1.25);
    }

    /* same as above for images, bad and will update in future */
    img {
      height: 14px;
      transform: scale(1.5);
      margin-left: 2px;
    }
  }
`;
