import { cx } from '@emotion/css';
import type { FC } from 'react';

import { dataSetToAttributes } from '../../utils';
import { IconButton } from '../IconButton';
import { IconButtonSize } from '../IconButton/IconButton.types';
import { testIds } from './GlobalHeader.constants';
import {
  globalHeaderCtaDesktopCss,
  globalHeaderDesktopContainerCss,
  globalHeaderDesktopCss,
  globalHeaderDesktopNavButtonCss,
} from './GlobalHeader.styles';
import type { GlobalHeaderDesktopProps } from './types';

const alignmentToCss = (alignment: 'left' | 'right' | 'center') => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
  }
};

export const GlobalHeaderDesktop: FC<GlobalHeaderDesktopProps> = ({
  logo,
  siteName,
  className,
  cta,
  endChildrenClassName,
  localNavDesktop,
  toggleExpanded,
  showNavScreen,
  showGlobalLinks = true,
  navItemAlignment = 'right',
  dataset,
}) => (
  <header className={cx(globalHeaderDesktopCss, className)} {...dataSetToAttributes(dataset)}>
    {/* // HACK: we move everything inside a div with overflow visible, and do the clip on the header parent */}
    <div className={globalHeaderDesktopContainerCss}>
      {showGlobalLinks && showNavScreen && (
        <IconButton
          size={IconButtonSize.LARGE}
          className={globalHeaderDesktopNavButtonCss}
          iconName="waffle"
          onClick={toggleExpanded}
          data-testid={testIds.openButton}
        />
      )}
      {logo}
      <span>{siteName}</span>

      <nav
        style={{ justifyContent: alignmentToCss(navItemAlignment) }}
        data-testid={testIds.navItems}
      >
        {localNavDesktop}
      </nav>
      <aside
        className={endChildrenClassName ?? globalHeaderCtaDesktopCss}
        data-testid={testIds.endContents}
      >
        {cta}
      </aside>
    </div>
  </header>
);

GlobalHeaderDesktop.displayName = 'GlobalHeaderDesktop';
