import { css } from '@emotion/css';
import type { ScrollAnimatedSectionAnimatedContentCollection } from '@snapchat/mw-contentful-schema';
import {
  ScrollAnimatedSection as ScrollableSectionSDS,
  ScrollAnimatedVideo,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getHeaderHeightFromCssVar, totalHeaderHeightCssVar } from '../Header/headerSizeUtils';

interface ScrollableSectionProps {
  animatedContentCollection?: ScrollAnimatedSectionAnimatedContentCollection;
  scrollHeight?: number;
}

const innerScrollContainerCss = css`
  height: calc(100vh - var(${totalHeaderHeightCssVar}));
  top: var(${totalHeaderHeightCssVar});
`;

const scrubVideoCss = css`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ScrollAnimatedSection: FC<ScrollableSectionProps> = ({
  scrollHeight = 1,
  animatedContentCollection,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const totalHeaderHeightRef = useRef<number>(64);

  // gets header height value from css var. only need to do this once
  // until we allow variable header heights... one day...
  // when that time comes we will have a hook w/ resize observer to fix this
  useEffect(() => {
    totalHeaderHeightRef.current = getHeaderHeightFromCssVar();
  }, []);

  /**
   * Function that returns the scroll progress for the scrub video. This returns the percentage of
   * the scrollable section that has scrolled past the top of the screen.
   */
  const calculateScrollProgress = useCallback(() => {
    if (!containerRef.current) {
      return 0;
    }
    const top =
      (containerRef.current.getBoundingClientRect().top - totalHeaderHeightRef.current) * -1;

    const totalHeight = containerRef.current.offsetHeight - window.innerHeight;
    const scrollProgress = top / totalHeight;
    return scrollProgress;
  }, []);

  const heightStyle = useMemo(() => {
    return { height: scrollHeight * 100 + 'vh' };
  }, [scrollHeight]);

  return (
    <ScrollableSectionSDS
      innerContainerClassName={innerScrollContainerCss}
      containerRef={containerRef}
      style={heightStyle}
    >
      {animatedContentCollection?.items.map(video => {
        const {
          sys,
          mp4Source,
          webmSource,
          videoStart,
          videoEnd,
          scrubStart = 0,
          scrubEnd = 100,
        } = video;
        if (!mp4Source?.url || !webmSource?.url) return null;
        return (
          <ScrollAnimatedVideo
            calculateScrollProgress={calculateScrollProgress}
            className={scrubVideoCss}
            key={sys.id}
            scrubEnd={scrubEnd}
            scrubStart={scrubStart}
            videoEnd={videoEnd}
            videoMp4Src={mp4Source.url}
            videoStart={videoStart}
            videoWebmSrc={webmSource?.url}
          />
        );
      })}
    </ScrollableSectionSDS>
  );
};
