import { defaultFeatureFlags } from '../components/FeatureFlags';
import { urlParameters } from '../constants/urlParameters';
import { appendLocaleToBusinessHelpUrl, businessHelpHostname } from './businessHelpUrl';

const relativePathPrefix = '/';
const httpPrefix = 'http://';
const httpPrefixUppercase = httpPrefix.toUpperCase();
const httpsPrefix = 'https://';
const httpsPrefixUppercase = httpsPrefix.toUpperCase();

export const isUrl = (str?: string): boolean =>
  !!str &&
  (str.startsWith(relativePathPrefix) ||
    str.startsWith(httpPrefix) ||
    str.startsWith(httpPrefixUppercase) ||
    str.startsWith(httpsPrefix) ||
    str.startsWith(httpsPrefixUppercase));

/**
 * Combine query params of both old and new URL before navigating to new URL. For persistent query
 * params, we preserve them from the old URL. (i.e. if new URL also has a persistent query param, it
 * does not overwrite the old one) For non-persistent params in old URL, we drop them. (This is
 * because we don't want to track any non-UTM query params for now) If new URL contains referrer,
 * apply the persistent query params to referrer URL as well.
 */
export const copyUrlSearchParameters = (
  destinationUrl: URL,
  currentUrl: URL,
  persistentQueryParams: string[]
): void => {
  const referrerUrlString = destinationUrl.searchParams.get('referrer');
  const referrerUrl = referrerUrlString ? new URL(referrerUrlString) : null;

  for (const queryParameter of persistentQueryParams) {
    const value =
      destinationUrl.searchParams.get(queryParameter) ??
      currentUrl.searchParams.get(queryParameter) ??
      referrerUrl?.searchParams.get(queryParameter);

    if (!value) {
      continue;
    }
    destinationUrl.searchParams.set(queryParameter, value);
    referrerUrl?.searchParams.set(queryParameter, value);
  }

  if (referrerUrl) {
    destinationUrl.searchParams.set('referrer', referrerUrl.href);
  }
};

/** Returns the destination URL with some query parameters copied over from the current URL. */
export const mergeUrlParameters = (
  destinationUrl: URL,
  currentUrl: URL,
  persistentQueryParams: string[] = []
): void => {
  copyUrlSearchParameters(destinationUrl, currentUrl, [
    ...urlParameters,
    ...Object.keys(defaultFeatureFlags),
    ...persistentQueryParams,
  ]);
};

/** Whether the url is for an internal site that should accept the LANG parameter. */
const acceptsLangParameter = (url: URL): boolean => {
  return (
    url.hostname.includes('snap.com') ||
    url.hostname.includes('snapchat.com') ||
    url.hostname.includes('pixy.com') ||
    url.hostname.includes('yellowla.com') ||
    url.hostname.includes('spectacles.com') ||
    url.hostname.includes('arcadiacreativestudio.com') ||
    url.hostname.includes('snappartnersummit.com')
  );
};

/**
 * Appends the specified url with the `lang` query parameter.
 *
 * Includes special logic for the businesshelp.snapchat.com website.
 */
export const appendLocaleParameterToUrl = (url: URL, locale: string): void => {
  if (!locale || !acceptsLangParameter(url)) return;

  if (url.hostname === businessHelpHostname) {
    appendLocaleToBusinessHelpUrl(url, locale);
  } else if (!url.searchParams.has('lang')) {
    url.searchParams.set('lang', locale);
  }
};
