import type { FC } from 'react';
import { createContext, useContext } from 'react';

// ============================================================================
// Utils
// ============================================================================

/**
 * Message formatter interface that matches what we use from the react-intl package.
 *
 * The only difference is that we force the user to format their own variables. There's no default
 * assumption about number format, currency etc. It's on the caller to use Intl.FooFormatter with
 * appropriate parameters.
 */
export type MessageFormatter = (
  message: {
    id: string;
    defaultMessage: string;
  },
  vars?: Record<string, string>
) => string;

/** Default formatter that uses the default message. */
export const defaultFormatMessage: MessageFormatter = (message, vars): string => {
  let formattedValue = message.defaultMessage;

  if (!vars) return formattedValue;

  for (const [key, value] of Object.entries(vars)) {
    formattedValue = formattedValue.replace(`{${key}}`, value);
  }

  return formattedValue;
};

// ============================================================================
// Context definition
// ============================================================================

/**
 * Context for the formatMessage from react-intl.
 *
 * Note that we don't use react-intl package or formatjs library for its implementation because they
 * are a very heavy dependency.
 */
interface MessageProps {
  formatMessage: MessageFormatter;
}

export const MessageContext = createContext<MessageProps>({
  formatMessage: defaultFormatMessage,
});

// ============================================================================
// Convenience component
// ============================================================================

type FormatMessageProps = {
  id: string;
  defaultMessage: string;
  values?: Record<string, string>;
};

/**
 * Convenience component for using the formatMessage context.
 *
 * Interface matches react-intl.
 *
 * Note that there's is some HTML support here, but the nature of these is that it's ALWAYS
 * developer html that gets put in here. Never any user input.
 */
export const FormattedMessage: FC<FormatMessageProps> = ({ id, defaultMessage, values }) => {
  const { formatMessage } = useContext(MessageContext);
  const formattedMessage = formatMessage({ id, defaultMessage }, values)
    // Sanity injection protection protection.
    .replace(/<script/g, '&lt;script');

  return <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />;
};
