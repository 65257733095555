import { Site } from '@snapchat/mw-common';
import type { MotifRootProps } from '@snapchat/snap-design-system-marketing';
import { MotifRoot } from '@snapchat/snap-design-system-marketing';
import { type FC, type PropsWithChildren, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { UrlParameter } from '../../constants/urlParameters';
import { forBusinessMotif } from '../../sites/for-business/forBusinessMotif';
import { customBlueMotif } from '../../styles/customBlueMotif';
import { customGreenMotif } from '../../styles/customGreenMotif';
import { Feature, useFeatureFlags } from '../FeatureFlags';

export const MotifRootWrapper: FC<PropsWithChildren<MotifRootProps>> = ({
  children,
  motif,
  ...passThroughProps
}) => {
  const { getCurrentUrl } = useContext(AppContext);
  const featureFlags = useFeatureFlags();

  let finalMotif = motif;

  const url = new URL(getCurrentUrl());
  const useAlternateMotif = url.searchParams.get(UrlParameter.USE_ALTERNATE_MOTIF);

  if (
    featureFlags[Feature.FOR_BIZ_BLACK_CTA_ON_WHITE_AND_GRAY] === 'true' &&
    // lock to only for business
    Config.site === Site.FOR_BUSINESS
  ) {
    finalMotif = forBusinessMotif;
  }

  if (Config.isDeploymentTypeProd) {
    // Do nothing. The theme is cached, so we can't turn on the easter egg.
  } else if (useAlternateMotif === 'true' || useAlternateMotif === 'green') {
    finalMotif = customGreenMotif;
  } else if (useAlternateMotif === 'blue') {
    finalMotif = customBlueMotif;
  }

  return (
    <MotifRoot motif={finalMotif} {...passThroughProps}>
      {children}
    </MotifRoot>
  );
};
