import { cx } from '@emotion/css';
import type { SrcSetSizes } from '@snapchat/mw-common/client';
import type { Tile } from '@snapchat/mw-contentful-schema';
import { useContentfulImages } from '@snapchat/mw-global-components';
import {
  mobileMaxWidth,
  SummaryCard as SummaryCardSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { useFormatDate } from '../../hooks/useFormatDate';
import { UserAction } from '../../types/events';
import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { parseMedia } from '../../utils/media';
import { renderRichText, renderRichTextMarkingsOnly } from '../../utils/renderText/renderRichText';
import { ConsumerContext } from '../ConsumerContextProvider';
import { summaryCardHeroCss } from './SummaryCard.styled';
import type { SummaryCardProps } from './types';

/*
This is a global fallback image to display on summary card when the user doesn't provide an image for the gallery or tile.
This image is in the global-components contentful space
*/
const globalDefaultImage =
  'https://images.ctfassets.net/kp51zybwznx4/120IJvdruE5HeC9XBYjFiU/879b8f63bde0d27c0750ade94c4b1a39/Default_Gallery_Image.png';

const defaultImagAltText = 'Snap Inc.';

const defaultDateOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  // NOTE: Always render these dates assuming UTC timezone to ensure consistent rendering between server and client.
  //       Otherwise we would need to determine the client's timezone whenever we process the initial render on the server.
  //       By using UTC for all cases we ensure consistent behavior, though we may be off by 1 day depending on the user's
  //       location.
  timeZone: 'UTC',
};

// 450 height on desktop, 360 height on mobile.
const imageHeights = [360, 450];
const galleryImgSrcSetSizes: SrcSetSizes = {
  sizeToUrl: imageHeights.map(height => ({
    // We can only use width descriptor so... even though it says 'w' it's not really its width.
    // This way the browser will still use the correct one since we are telling it at mobile
    // to use 360w and on desktop to use 450w.
    size: `${height}w`,
    settings: { height },
  })),
  sizes: `(max-width: ${mobileMaxWidth}px) 360px, 450px`,
};

export const SummaryCard: FC<Omit<SummaryCardProps, 'linkedFrom'>> = ({
  analytics,
  date,
  label,
  media,
  showDates,
  showDescriptions,
  showMedia = true,
  slugReference,
  sys,
  title,
  url,
  isHeroTile = false,
  eyebrowText,
}) => {
  const { formatDate } = useFormatDate();
  const { logEvent } = useContext(ConsumerContext);
  const { getImageSources } = useContentfulImages();

  const validUrl = url ?? slugReference?.slug;

  const logEventAction = () => {
    validUrl &&
      logEvent?.({
        component: 'Summary Card',
        type: UserAction.Click,
        label: analytics?.label ? `${analytics.label} - ${validUrl}` : validUrl,
        url: validUrl,
      });
  };

  let { imageSource, imageAltText } = parseMedia(media?.media);
  const { imageSource: mobileImageSource } = parseMedia(media?.mobileMedia);

  if (!imageSource) {
    imageSource = globalDefaultImage;
    imageAltText = defaultImagAltText;
  }
  const imageSrcSettings = { size: galleryImgSrcSetSizes };
  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, imageSrcSettings),
    mobile: getImageSources(mobileImageSource, imageSrcSettings),
  });

  const { contentfulDescriptionDataset, titleDataset, labelDataset, mediaDataset, dateDataset } =
    getContentfulInspectorProps<Tile>({
      entryId: sys.id,
      fieldIds: ['title', 'label', 'date', 'media', 'contentfulDescription'],
    });

  return (
    <SummaryCardSDS
      onClick={logEventAction}
      key={sys.id}
      title={renderRichTextMarkingsOnly(title)}
      description={renderRichText(label)}
      imgSrcs={imgSrcs}
      imgAltText={imageAltText}
      link={validUrl}
      showDate={showDates}
      showDescription={showDescriptions}
      showMedia={showMedia}
      date={formatDate(date, defaultDateOptions)}
      dateTime={date ? new Date(date) : undefined}
      dataset={contentfulDescriptionDataset} // take the user to the tile entry
      titleDataset={titleDataset}
      descriptionDataset={labelDataset}
      mediaDataset={mediaDataset}
      dateDataset={dateDataset}
      className={cx({ [summaryCardHeroCss]: isHeroTile })}
      eyebrowText={eyebrowText}
    />
  );
};

SummaryCard.displayName = 'SummaryCard';
