import type { SrcSetSizes } from '@snapchat/mw-common/client';

export const isVideoUrl = (contentType: string | null): boolean => {
  if (!contentType) return false;
  return contentType.startsWith('video');
};

export const isImageUrl = (contentType: string | null): boolean => {
  if (!contentType) return false;
  return contentType.startsWith('image');
};

export interface MediaPreloadSettings {
  sizedSrcSets?: SrcSetSizes;
  skipPreload?: () => boolean;
}
