import type { AbVariant, Criteria, Experiment } from '@snapchat/mw-contentful-schema';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import kebabCase from 'lodash-es/kebabCase';
import { useCallback, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { logEvent, logInfo, SubscribedEventType } from '../../helpers/logging';
import type { AbExperimentDecider } from '../../hooks/useAbExperiments';
import { useAbExperiments } from '../../hooks/useAbExperiments';
import { type SingleCallbackFn, useSingleCallback } from '../../hooks/useSingleCallback';
import type { ContentfulSysProps } from '../../types/contentful';
import type { BlockShallowWithContentIds } from '../Block';
import type { PageShallowDataProps } from '../Page';

// =================================================================================================
// Controls
// =================================================================================================
const printDebugStatements = false;

function printDebugSkip(
  analyticsId: string | undefined,
  what: string | number,
  where: Array<string | number> = [],
  action: string | undefined
) {
  if (!printDebugStatements) return;
  analyticsId ??= 'synthetic';
  action ??= 'Allow';

  console.debug(
    `Criteria not applicable for arm '${analyticsId}' because of check ` +
      `'${what}' in '${where}' has action '${action}'.`
  );
}

// =================================================================================================
// Interfaces
// =================================================================================================

export interface CriteriaDecisionProps {
  /**
   * Snap standard locale code in format language-COUNTRY Where langauge is one of the
   * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes And country is one of the alpha-2
   * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes codes
   */
  language: string;

  /** 2-digit country code as in https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes */
  country: string;

  /**
   * One of "Android", "Chrome OS", "Fuchsia", "iOS", "Linux", "macOS", "Windows", or "Unknown". See
   * spec: https://wicg.github.io/ua-client-hints/#sec-ch-ua-platform
   */
  platform: 'Android' | 'Chrome OS' | 'Fuchsia' | 'iOS' | 'Linux' | 'macOS' | 'Windows' | 'Unknown';

  /**
   * Whether the device is mobile or not. Based on "mobile" user hints header. See spec:
   * https://wicg.github.io/ua-client-hints/#sec-ch-ua-mobile
   */
  deviceType: 'Mobile' | 'Non-Mobile' | 'Unknown';
}

// TODO: This shouldn't extend CriteriaDecisionProps at all.
export interface ExperimentDecisionProps extends CriteriaDecisionProps {
  getCurrentUrl: () => string;
  singleCallback: SingleCallbackFn;
  decideAbExperiment: AbExperimentDecider;
}

export type ReplacementData =
  | ContentfulSysProps
  | BlockShallowWithContentIds
  | PageShallowDataProps;

export type ReplacementMap = Partial<Record<string, ReplacementData>>;

interface ExperimentResult<T> {
  decision: T;
  experimentId?: string;
  variantId?: string;
  replacements?: ReplacementMap;
}

/**
 * Finds the first experiment arm that matches its criteria, and if all fail returns the default.
 *
 * In absence of an experiment, returns value.
 */
export type ExperimentDecider = <T extends ContentfulSysProps>(
  value: T | Experiment,
  options: {
    logImpression: boolean;
  }
) => ExperimentResult<T>;

/** Returns TRUE if the passed-in value MATCHES the criteria. */
export type CriteriaChecker = (criteria: Criteria | null | undefined) => boolean;

// =================================================================================================
// Public Experiment API
// =================================================================================================

export interface UseExperiment {
  decideExperiment: ExperimentDecider;
  // TODO: Move CriteriaChecker into a different hook (useCriteria).
  checkCriteria: CriteriaChecker;
}

export function useExperiment(): UseExperiment {
  const { currentLocale, userLocation, getCurrentUrl } = useContext(AppContext);
  const browserFeatures = useContext(BrowserFeaturesContext);
  const singleCallback = useSingleCallback();
  const decideAbExperiment = useAbExperiments();

  const language = currentLocale ?? 'Unknown';
  const country = userLocation.country ?? 'Unknown';
  const platform = browserFeatures.getLowEntropyHints().platform ?? 'Unknown';
  const deviceType = browserFeatures.getLowEntropyHints().isMobile ? 'Mobile' : 'Non-Mobile';

  const checkCriteria = useCallback(
    (value: Criteria | null | undefined) => {
      return doesPassCriteria(value, { language, country, platform, deviceType });
    },
    [language, country, platform, deviceType]
  );

  const decideExperiment = useCallback<ExperimentDecider>(
    (value, options) => {
      const context: ExperimentDecisionProps = {
        language,
        country,
        platform,
        deviceType,
        singleCallback,
        getCurrentUrl,
        decideAbExperiment,
      };

      return decideExperimentImpl(value, context, options);
    },
    [language, country, platform, deviceType, singleCallback, decideAbExperiment, getCurrentUrl]
  );

  return {
    decideExperiment,
    checkCriteria,
  };
}

/**
 * Returns the default value of an experiment or the only value without the experiment wrapper.
 *
 * NOTE: Use this very sparingly. This is only applicable in situations where nothing sensitive can
 * be shown to the user. I.e. If an experiment has a filter to not show sensitive material in Saudi
 * Arabia, we cannot use this method if it can show bad material to our users.
 *
 * Otherwise use the {@link decideExperimentImpl} and provide the correct context.
 */
export function getExperimentDefault<T extends ContentfulSysProps>(value: T | Experiment): T {
  // If not an experiment, return.
  if (value.__typename !== 'Experiment') {
    return value as T;
  }
  const experiment = value as Experiment;
  return experiment.defaultReference as unknown as T;
}

/**
 * Returns whether the action check fails.
 *
 * Usage:
 *
 * If (doesFailCheck(check)) { fail condition here }
 */
const doesFailCheck = (array: string[] | undefined, value: string, action?: string): boolean => {
  action ??= 'Allow'; // Works for nulls.

  // Fail if we have an allow-list, and current value isn't on it.
  if (action === 'Allow' && array && !array.includes(value)) return true;

  // Fail if we have a block-list and the current value is on it.
  if (action === 'Deny' && array?.includes(value)) return true;
  // Otherwise return 'false' as in 'check did not fail'.
  return false;
};

export function doesPassCriteria(
  criteria: Criteria | null | undefined,
  props: CriteriaDecisionProps,
  analyticsId?: string
): boolean {
  if (!criteria) return true;

  // Check countries.
  if (doesFailCheck(criteria.countries, props.country, criteria.countryAction)) {
    printDebugSkip(analyticsId, props.country, criteria.countries, criteria.countryAction);
    return false;
  }

  // Check languages.
  if (doesFailCheck(criteria.languages, props.language, criteria.languageAction)) {
    printDebugSkip(analyticsId, props.language, criteria.languages, criteria.languageAction);
    return false;
  }

  // Check platforms.
  if (doesFailCheck(criteria.platforms, props.platform, criteria.platformAction)) {
    printDebugSkip(analyticsId, props.platform, criteria.platforms, criteria.platformAction);
    return false;
  }

  // Check devices.
  if (doesFailCheck(criteria.devices, props.deviceType, criteria.deviceAction)) {
    printDebugSkip(analyticsId, props.deviceType, criteria.devices, criteria.deviceAction);
    return false;
  }

  // If none of the checks failed, then the criteria has passed.
  return true;
}

/**
 * Evaluates the experiment and finds the first arm for which criteria passes. If no arms pass, uses
 * the default reference.
 */
export function decideExperimentImpl<T extends ContentfulSysProps>(
  value: T | Experiment,
  props: ExperimentDecisionProps,
  options: Parameters<ExperimentDecider>[1]
): ExperimentResult<T> {
  // If not an experiment, return.
  if (value.__typename !== 'Experiment') {
    // we fire this log event to show that there was no experiment on this page
    // this is useful for clearing experiment data on certain listeners (like Blizzard)
    // Google and GoogleCloud listeners ignore this event
    options.logImpression &&
      logEvent({
        subscribedEventType: SubscribedEventType.EXPERIMENT_IMPRESSION,
        experimentId: undefined,
        variantId: undefined,
      });

    return { decision: value as T };
  }
  const experiment = value as Experiment;

  let chosenArmAnalyticsId = 'default';
  let reference = experiment.defaultReference;
  const replacements: ReplacementMap = {};
  const singleCallback = props.singleCallback;

  for (const arm of experiment.experimentArmsCollection?.items ?? []) {
    if (!arm.criteria) continue;

    const armHasAbExperiment = (arm.abExperimentsCollection?.items.length ?? 0) > 0;

    const passCriteria = doesPassCriteria(arm.criteria, props, arm.analyticsId);

    if (!passCriteria) continue;

    reference = arm.reference;
    chosenArmAnalyticsId = arm.analyticsId ?? chosenArmAnalyticsId;

    if (armHasAbExperiment && arm.abExperimentsCollection?.items.length) {
      const abExperiments = arm.abExperimentsCollection.items;

      // for every experiment on the page
      for (const abExperiment of abExperiments) {
        const entryToReplace = abExperiment.reference?.sys.id;
        let madeReplacement = false;
        if (!entryToReplace) continue;
        const { isThinking, decision: experimentArm } = props.decideAbExperiment<AbVariant>({
          seed: abExperiment.seed ?? abExperiment.sys.id,
          variants: abExperiment.abVariantsCollection?.items,
        });

        if (experimentArm) {
          // If replacement is a Page, this is a page swap so just change the reference to the replacement
          if (experimentArm.replacement?.__typename === 'Page') {
            reference = experimentArm.replacement;
            // The replacement is at the entry level, and the replacement entry exists
          } else if (experimentArm.replacement) {
            replacements[entryToReplace] = {
              ...experimentArm.replacement,
            };
            // The replacement is at the entry level, but the replacement entry does not exist so we set it to undefined
            // this will let the components know to not render the entryToReplace
          } else {
            replacements[entryToReplace] = undefined;
          }

          // Log Exposure for the variant swap
          singleCallback({
            key: 'useExperimentVariantSwap',
            callback: () =>
              logInfo({
                eventCategory: 'Experiment',
                eventAction: 'experimentExposure',
                eventLabel: `${chosenArmAnalyticsId}:${abExperiment.analyticsId}:${experimentArm.analyticsId}`,
              }),
            dependencies: [
              chosenArmAnalyticsId,
              abExperiment.analyticsId,
              experimentArm.analyticsId,
            ],
          });

          madeReplacement = true;
        }

        // no replacements were made, so we log an exposure for the default (control)
        if (!isThinking && !madeReplacement) {
          // Log event into GA to record that experiment decisions happen. This also goes to Blizard.
          singleCallback({
            key: 'useExperimentDefault',
            callback: () =>
              logInfo({
                eventCategory: 'Experiment',
                eventAction: 'experimentExposure',
                eventLabel: `${chosenArmAnalyticsId}:${abExperiment.analyticsId}:default`,
              }),
            dependencies: [chosenArmAnalyticsId, abExperiment.analyticsId],
          });
        }
      }
    }
    break;
  }

  // Log event into GA to record that experiment decisions happen. This also goes to Blizard.
  singleCallback({
    key: 'useExperiment',
    callback: () =>
      logInfo({
        eventCategory: 'Experiment',
        eventAction: 'useExperiment',
        eventLabel: `${kebabCase(experiment.analyticsId ?? '')}:${kebabCase(chosenArmAnalyticsId)}`,
      }),
    dependencies: [experiment.analyticsId, chosenArmAnalyticsId],
  });

  // Log event through GTag into Google Optimize for report generation.
  options.logImpression &&
    singleCallback({
      key: 'experimentImpression',
      callback: () =>
        logEvent({
          subscribedEventType: SubscribedEventType.EXPERIMENT_IMPRESSION,
          experimentId: experiment.analyticsId ?? 'Unknown',
          variantId: chosenArmAnalyticsId,
        }),
      dependencies: [experiment.analyticsId, chosenArmAnalyticsId],
    });

  return {
    decision: reference as unknown as T,
    experimentId: experiment.analyticsId,
    variantId: chosenArmAnalyticsId,
    replacements,
  };
}
