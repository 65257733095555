import { type FC } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../../utils/contentful';
import { Loading } from '../Loading';
import { BarChart } from './BarChart';
import { queries } from './query';
import type { BarChartDataHandlerProps, BarChartProps } from './types';

export const BarChartShallow: FC<BarChartProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;

  const { data, loading } = useContentfulQuery<BarChartDataHandlerProps, ContentfulIdVariable>(
    queries.all,
    {
      skip: !id,
      variables: { id },
    }
  );

  if (loading) return <Loading />;
  if (!data) return null;

  // If chart data or x axes was passed directly to this bar chart, use those instead of whatever is on the
  // visualization (this will be the case if this is a child of a Multi Visualization)
  const chartData = props.chartDataOverride ?? data.barChart.chartData;
  const xAxes = props.xAxesOverride ?? data.barChart.xAxes;

  return <BarChart {...data.barChart} chartData={chartData} xAxes={xAxes} />;
};
