import { cx, injectGlobal } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { BrowserFeaturesContext, isMobileOs } from '../../BrowserFeatures';
import { MediaMode } from '../../constants';
import { isSSR } from '../../utils/environment';
import { CarouselArrow } from './CarouselArrow';
import { Carousel as MultiCarousel } from './ReactMultiCarousel';
import { carouselCss, carouselStyleCss } from './styles';
import type { CarouselProps } from './types';
import { generateResponsive } from './utils';

injectGlobal(carouselStyleCss);

/** @deprecated Use CarouselV3 */
export const Carousel: FC<CarouselProps> = ({
  children,
  maxItems = 1,
  itemWidth = 1,
  spaceNeeded,
  autoPlaySpeed = 5000,
  arrows = false,
  useCustomArrows = true,
  centerMode = false,
  showDots = true,
  isRTL = false,
  className,
  ...otherMultiCarouselProps
}) => {
  const browserFeatures = useContext(BrowserFeaturesContext);
  const deviceType = isMobileOs(browserFeatures) ? MediaMode.Mobile : MediaMode.Desktop;
  const responsive = generateResponsive({
    maxItems,
    itemWidth,
    spaceNeeded,
  });

  if (!children) {
    return null;
  }

  return (
    <MultiCarousel
      className={cx('sdsm-carousel', carouselCss, className)}
      minimumTouchDrag={25}
      customLeftArrow={useCustomArrows ? <CarouselArrow direction="Left" /> : undefined}
      customRightArrow={useCustomArrows ? <CarouselArrow direction="Right" /> : undefined}
      responsive={responsive}
      ssr={isSSR()}
      deviceType={deviceType.toLowerCase()} // This is necessary for SSR
      swipeable={true}
      draggable={true}
      autoPlay={!!autoPlaySpeed}
      showDots={showDots}
      arrows={arrows}
      centerMode={centerMode}
      isRTL={isRTL}
      {...otherMultiCarouselProps}
    >
      {children}
    </MultiCarousel>
  );
};
