import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import { ChartToggleButtons } from './ChartToggleButtons';
import { TabsPanels as ChartTogglePanels } from './ChartTogglePanels';
import type { ChartToggleItemProps, ChartToggleProps } from './types';

export const ChartToggle: FC<ChartToggleProps> = ({ items }) => {
  const [selectedChart, selectChart] = useState(0);
  useMotifStyles(MotifComponent.CHART_TOGGLE);

  useEffect(() => {
    const { onToggle } = items[selectedChart] as ChartToggleItemProps;
    onToggle && onToggle();
  }, [items, selectedChart]);

  if (!items?.length) {
    return null;
  }

  return (
    <section className={MotifComponent.CHART_TOGGLE}>
      <ChartToggleButtons items={items} selectedChart={selectedChart} selectChart={selectChart} />
      <ChartTogglePanels items={items} selectedChart={selectedChart} />
    </section>
  );
};
