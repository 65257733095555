import { Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const heroVars: MotifComponentProps<MotifComponent.HERO> = {
  '--hero-subtitle-color': m('--foreground-color'),
  // To avoid loss of contrast, the text color should be white when the curtain is active.
  '--hero-text-color-curtain-active': Plain.WHITE,
  '--hero-title-color': m('--foreground-color'),
};
