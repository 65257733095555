import { getImageUrl } from '@snapchat/mw-common/client';
import { useIsMobile } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { CallToAction } from '../components/CallToAction/CallToAction';
import type { CallToActionDataProps } from '../components/CallToAction/types';
import { Code } from '../components/Code';
import { FootnoteIndex } from '../components/Footnote/FootnoteIndex';
import { Image } from '../components/Image';
import type { MediaProps } from '../components/Media';
import { Media } from '../components/Media';
import { Quote } from '../components/Quote';
import { SnapchatEmbedShallow } from '../components/SnapchatEmbed';
import { Video } from '../components/Video';

export const richTextCtaClassName = 'mwp-cta';

const EmbeddedMedia: FC<MediaProps> = props => {
  const isMobile = useIsMobile();
  const heightPx = isMobile ? 400 : 600;
  return (
    <Media
      {...props}
      url={getImageUrl({
        imageUrl: props.url,
        settings: {
          height: heightPx,
          // TODO: Do the other optimizations here.
        },
      })}
      isEmbedded
    />
  );
};

// NOTE: There are some props that make this return an invalid ReactNode, so only registering
// the properties from CallToActionDataProps makes it work.
//
// For reference, this is the error that happens on CitizenSnap "/diversity" page:
//     react-jsx-runtime.development.js:124 Warning: React.jsx: type is invalid
//     -- expected a string (for built-in components) or a class/function (for composite components)
//     but got: undefined. You likely forgot to export your component from the file it's defined in,
//      or you might have mixed up default and named imports.
//
// This only seems to happen when there's a reference to a CallToAction from a rich text body.
const CallToActionWrapper: FC<CallToActionDataProps> = props => {
  // adding className here so we can target this component via css
  return <CallToAction {...props} className={richTextCtaClassName} />;
};

/**
 * Note: All of these components CANNOT reference back to renderText or this file in order to avoid
 * circular dependencies.
 *
 * They should use 'renderRichText' instead which does not allow embedded assets/entries.
 */
export const contentfulComponentMap = {
  FootNotes: FootnoteIndex,
  CustomerQuote: Quote,
  Asset: EmbeddedMedia,
  CallToAction: CallToActionWrapper,
  SnapchatEmbed: SnapchatEmbedShallow,
  Image,
  Video,
  Code,
};

export type ContentfulComponentMap = typeof contentfulComponentMap;
