import { css, cx } from '@emotion/css';
import { localPoint } from '@visx/event';
import { Legend, LegendItem, LegendLabel } from '@visx/legend';
import type { LabelFormatterFactory } from '@visx/legend/lib/types';
import { ParentSize } from '@visx/responsive';
import type { PickD3Scale } from '@visx/scale';
import { scaleQuantile, scaleQuantize } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { applyMatrixToPoint, Zoom } from '@visx/zoom';
import type { FC, MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';

import { MediaMode } from '../../../constants';
import { Gray, Orange } from '../../../constants/chartColors';
import { White } from '../../../constants/colors';
import { useMediaMode } from '../../../hooks/useMediaMode';
import { MotifComponent, useMotifStyles } from '../../../motif';
import type { FieldMetadata } from '../types';
import { flags } from './country-flags';
import { DefaultTooltip } from './DefaultTooltip';
import type { GeoMapDatum, GeoTooltipData, WorldData } from './geoMapTypes';
import { MemoMap as Map } from './Map';
import { controlsCss, filtersCss, mobileTooltipCss } from './styles';

const defaultColorRange = [Orange.V50, Orange.V100, Orange.V150, Orange.V200];
const defaultEmptyColor = Gray.V50;
const titleCss = css`
  text-align: center;
  margin-bottom: 0;
`;
const glyphSize = 15;

type QuantizeOrQuantileScale = PickD3Scale<'quantile' | 'quantize'>;

const numberFormatter = (value: number, locale = 'en-US') => {
  return value.toLocaleString(locale, { notation: 'compact' });
};

const labelFormatter: LabelFormatterFactory<QuantizeOrQuantileScale> =
  ({ scale }) =>
  (datum, index) => {
    const [x0, x1] = scale.invertExtent(scale(datum));
    return {
      extent: [x0, x1],
      text: `${numberFormatter(x0)} - ${numberFormatter(x1)}`,
      value: scale(x0),
      datum,
      index,
    };
  };

type GeoMapProps = {
  chartTitle?: string;
  data: GeoMapDatum[];
  fieldMetadata: FieldMetadata;
  countryFieldKey: string;
  backgroundColor?: string;
  colorRange?: string[];
  emptyColor?: string;
  stroke?: string;
  width?: number;
  height?: number;
  topoData: WorldData;
  locale?: string;
  scaleMode?: 'quantile' | 'quantized';
  isLoading?: boolean;
};

export const GeoMap: FC<GeoMapProps> = ({
  chartTitle,
  width,
  height,
  backgroundColor,
  data,
  fieldMetadata,
  countryFieldKey,
  stroke,
  colorRange = defaultColorRange,
  emptyColor = defaultEmptyColor,
  topoData,
  locale,
  scaleMode = 'quantized',
}) => {
  useMotifStyles(MotifComponent.GEO_MAP);

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<{
      id: string;
      name: string;
      data?: number | string;
      field: string;
    }>();

  const mediaMode = useMediaMode();
  const isMobile = mediaMode === MediaMode.Mobile;

  // If you don't want to use a Portal, simply replace `TooltipInPortal` below with
  // `Tooltip` or `TooltipWithBounds` and remove `containerRef`
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const hideTooltipCb = useCallback(hideTooltip, [hideTooltip]);
  const handleMouseOverCb = useCallback(
    (event: MouseEvent, datum: GeoTooltipData) => {
      const relativeElement = Object.hasOwn(event.target, 'ownerSVGElement')
        ? // @ts-ignore: The type checks out.
          event.target.ownerSVGElement
        : event.target;
      const coords = localPoint(relativeElement as Element, event);

      showTooltip({
        tooltipLeft: coords?.x,
        tooltipTop: coords?.y,
        tooltipData: datum,
      });
    },
    [showTooltip]
  );

  // expensive to run. also GeoMap rerenders on every mouse in/out so it gets slow
  const scaleObj = useMemo(() => {
    const finalData = data.map(datum => datum[fieldMetadata.key] as number);

    if (scaleMode === 'quantile') {
      const scale = scaleQuantile({ domain: finalData, range: colorRange });
      return { scale, domain: scale.range().map(output => scale.invertExtent(output)[0]) };
    } else {
      let min = Infinity;
      let max = -Infinity;

      for (const datum of finalData) {
        min = Math.min(datum, min);
        max = Math.max(datum, max);
      }

      const scale = scaleQuantize({ domain: [min, max], range: colorRange, nice: true });
      return {
        scale,
        domain: min > 10 ? scale.range().map(output => scale.invertExtent(output)[0]) : undefined,
      };
    }
  }, [colorRange, fieldMetadata.key, data, scaleMode]);

  return (
    <section data-testid="geoMap" className={cx(MotifComponent.GEO_MAP)}>
      {chartTitle ? <h2 className={titleCss}>{chartTitle}</h2> : null}
      <div className={controlsCss}>
        <div className={filtersCss}>
          <Legend scale={scaleObj.scale} domain={scaleObj.domain} labelTransform={labelFormatter}>
            {labels =>
              labels.map((label, i) => (
                <LegendItem key={`legend-${i}`}>
                  <svg width={glyphSize} height={glyphSize} style={{ margin: '2px 0' }}>
                    <circle
                      fill={label.value}
                      r={glyphSize / 2}
                      cx={glyphSize / 2}
                      cy={glyphSize / 2}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))
            }
          </Legend>
        </div>
      </div>
      <ParentSize>
        {parent => {
          const realWidth = width ?? parent.width;
          const realHeight = height ?? parent.width * (isMobile ? 0.6 : 0.5);
          const centerX = 0;
          const centerY = 0;
          const scale = 1;
          return (
            <Zoom
              key={realWidth + 'zoom'}
              width={realWidth}
              height={realHeight}
              scaleXMin={1}
              scaleXMax={10}
              scaleYMin={1}
              scaleYMax={10}
              initialTransformMatrix={{
                scaleX: scale,
                scaleY: scale,
                translateX: centerX,
                translateY: centerY,
                skewX: 0,
                skewY: 0,
              }}
              constrain={(transformMatrix, prevTransformMatrix) => {
                const { scaleX, scaleY } = transformMatrix;

                if (scaleX < 1 || scaleX > 10) {
                  return prevTransformMatrix;
                }

                if (scaleY < 1 || scaleY > 10) {
                  return prevTransformMatrix;
                }

                const min = applyMatrixToPoint(transformMatrix, { x: 0, y: 0 });
                const max = applyMatrixToPoint(transformMatrix, { x: realWidth, y: realHeight });

                if (max.x < realWidth || max.y < realHeight) {
                  return prevTransformMatrix;
                }

                if (min.x > 0 || min.y > 0) {
                  return prevTransformMatrix;
                }

                return transformMatrix;
              }}
            >
              {zoom => (
                <svg
                  ref={containerRef}
                  width={realWidth}
                  height={realHeight}
                  className={zoom.isDragging ? 'dragging' : undefined}
                  onTouchStart={zoom.dragStart}
                  onTouchMove={zoom.dragMove}
                  onTouchEnd={zoom.dragEnd}
                  onMouseDown={zoom.dragStart}
                  onMouseMove={zoom.dragMove}
                  onMouseUp={zoom.dragEnd}
                  onMouseLeave={() => {
                    if (zoom.isDragging) {
                      zoom.dragEnd();
                    }
                  }}
                >
                  <rect
                    x={0}
                    y={0}
                    width={realWidth}
                    height={realHeight}
                    fill={backgroundColor || White}
                    rx={14}
                  />
                  <g
                    transform={`translate(${zoom.transformMatrix.translateX},
                      ${zoom.transformMatrix.translateY})scale(${zoom.transformMatrix.scaleX},
                        ${zoom.transformMatrix.scaleY})`}
                  >
                    <Map
                      dataScaler={scaleObj.scale}
                      width={realWidth}
                      height={realHeight}
                      onMouseEnter={handleMouseOverCb}
                      onMouseLeave={hideTooltipCb}
                      fieldMetadata={fieldMetadata}
                      countryFieldKey={countryFieldKey}
                      data={data}
                      stroke={stroke}
                      colorRange={colorRange}
                      emptyColor={emptyColor}
                      topoData={topoData}
                    />
                  </g>
                </svg>
              )}
            </Zoom>
          );
        }}
      </ParentSize>
      {tooltipOpen && !isMobile && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          <DefaultTooltip flags={flags} tooltipData={tooltipData} locale={locale} />
        </TooltipInPortal>
      )}
      {tooltipOpen && isMobile && (
        <DefaultTooltip flags={flags} tooltipData={tooltipData} className={mobileTooltipCss} />
      )}
    </section>
  );
};
