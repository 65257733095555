import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment FootNotesAll on FootNotes {
      ...ContentfulSysId
      number
      text
    }
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query FootNotesQuery($preview: Boolean!, $locale: String!, $id: String!) {
    footNotes(preview: $preview, locale: $locale, id: $id) {
      ...FootNotesAll
    }
  }
  ${fragments.all}
`;
