import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../../utils/contentful';
import { Loading } from '../Loading';
import { LineChart } from './LineChart';
import { queries } from './query';
import type { LineChartDataHandlerProps, LineChartProps } from './types';

export const LineChartShallow: FC<LineChartProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;

  const { data, loading } = useContentfulQuery<LineChartDataHandlerProps, ContentfulIdVariable>(
    queries.all,
    {
      skip: !id,
      variables: { id },
    }
  );

  if (loading) return <Loading />;
  if (!data) return null;

  // If chart data or y keys were passed directly to this chart, use those instead of whatever is on the
  // visualization (this will be the case if this is a child of a Multi Visualization).
  const chartData = props.chartDataOverride ?? data.lineChart.chartData;
  const yKeys = props.yKeysOverride ?? data.lineChart.yKeys;

  return <LineChart {...data.lineChart} chartData={chartData} yKeys={yKeys} />;
};
