import { cx } from '@emotion/css';
import type { FC } from 'react';
import type React from 'react';
import { Children, useContext, useEffect, useState } from 'react';

import { MediaMode } from '../../../constants';
import { ToggleState, ToggleTarget } from '../../../hooks/useToggleState';
import { DetailsSummary } from '../../DetailsSummary';
import {
  globalNavGroupAnimationDurationMs,
  globalNavLevelClassName,
} from '../GlobalHeader.constants';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { globalNavItemChevronCss } from '../GlobalNavItem/GlobalNavItem.styles';
import {
  childrenCountCssVarName,
  detailsHeightAnimationDesktopCss,
  globalNavGroupCss,
  itemListCss,
} from './GlobalNavGroup.styles';
import type { GlobalNavGroupProps } from './types';

export const GlobalNavGroup: FC<GlobalNavGroupProps> = ({
  navGroupKey,
  title,
  mobileHighlight,
  children,
}) => {
  const { groupKey, setGroupKey, mode, screenState } = useContext(GlobalHeaderContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(
    () => {
      const newIsOpen = groupKey === navGroupKey;

      // Do nothing if there's no state update.
      if (isOpen === newIsOpen) {
        return;
      }

      let timeoutRef: ReturnType<typeof setTimeout>;

      if (newIsOpen && screenState === ToggleState.ON) {
        timeoutRef = setTimeout(setIsOpen.bind(null, newIsOpen), globalNavGroupAnimationDurationMs);
      } else {
        setIsOpen(newIsOpen);
      }

      return () => {
        timeoutRef && clearTimeout(timeoutRef);
      };
    },
    // Skip 'screenState' because changes shouldn't re-trigger animations.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupKey, navGroupKey]
  );

  const wrappedChildren = (
    <ol className={itemListCss} role="menu">
      {Children.map(children, (child, index) => (
        <li key={`list-item-${index}`} role="menuitem">
          {child}
        </li>
      ))}
    </ol>
  );

  const setGroupKeyOnToggleOn = (target: ToggleTarget) => {
    if (target === ToggleTarget.ON) {
      setGroupKey && setGroupKey(navGroupKey);
    }
  };

  const isMobile = mode === MediaMode.Mobile;

  return (
    <DetailsSummary
      showChevron={mode === MediaMode.Mobile}
      onToggle={setGroupKeyOnToggleOn}
      open={isMobile ? undefined : isOpen} // Do not force state on mobile.
      summary={title}
      chevronProps={{ className: globalNavItemChevronCss }}
      className={cx(
        globalNavLevelClassName,
        isMobile ? undefined : detailsHeightAnimationDesktopCss,

        globalNavGroupCss
      )}
      fadeInAnimation={isMobile} // Use default fade-in animations on mobile. They're faster.
      transitionDurationMs={globalNavGroupAnimationDurationMs}
      data-testid={`group-${navGroupKey}`}
      style={
        {
          [childrenCountCssVarName]: Children.count(children),
        } as React.CSSProperties
      }
    >
      {wrappedChildren}
      {isMobile && mobileHighlight}
    </DetailsSummary>
  );
};

GlobalNavGroup.displayName = 'GlobalNavGroup';
