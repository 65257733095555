import { cx } from '@emotion/css';
import { useBrowserLayoutEffect } from '@snapchat/mw-common/client';
import { DetailsSummary as DetailsSummarySDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';

import { ProgressIndicator } from './ProgressIndicator';
import {
  accordionItemCss,
  accordionItemDetailContentActiveCss,
  accordionItemDetailContentCss,
  accordionItemDetailCss,
  accordionItemSummaryCss,
} from './styles';
import type { AccordionItemProps } from './types';

const animationOptions = {
  duration: 400, // ms
  easing: 'ease-out',
};

export const AccordionItem: FC<AccordionItemProps> = ({
  isOpen,
  isPaused,
  progressIndicatorDurationMs,
  title,
  body,
  onToggle,
  onMouseOver,
  onMouseLeave,
  onAnimationComplete,
}) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const summaryRef = useRef<HTMLElement>(null);

  const animation = useRef<Animation | null>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);

  const shrink = useCallback(() => {
    if (!detailsRef?.current || !summaryRef?.current) {
      return;
    }

    setIsClosing(true);

    detailsRef.current.style.overflow = 'hidden';

    const startHeight = `${detailsRef.current.offsetHeight}px`;
    const endHeight = `${summaryRef.current.offsetHeight}px`;

    if (animation.current) {
      animation.current.cancel();
    }

    animation.current = detailsRef.current.animate?.(
      { height: [startHeight, endHeight] },
      animationOptions
    );

    animation.current?.finished
      ?.then(() => onAnimationFinish(false))
      .catch(() => setIsClosing(false));
  }, []);

  const expand = useCallback(() => {
    if (!detailsRef.current || !summaryRef.current) {
      return;
    }

    setIsExpanding(true);

    detailsRef.current.style.height = `${detailsRef.current.offsetHeight}px`;
    detailsRef.current.style.overflow = 'hidden';
    detailsRef.current.open = true;

    const startHeight = `${detailsRef.current.offsetHeight}px`;
    const endHeight = `${detailsRef.current.scrollHeight}px`;

    if (animation.current) {
      animation.current.cancel();
    }

    animation.current = detailsRef.current.animate?.(
      { height: [startHeight, endHeight] },
      animationOptions
    );

    animation.current?.finished
      .then(() => onAnimationFinish(true))
      .catch(() => setIsExpanding(false));
  }, []);

  const onAnimationFinish = (open: boolean) => {
    if (!detailsRef.current) return;

    detailsRef.current.open = open;
    detailsRef.current.style.height = '';
    detailsRef.current.style.overflow = '';
    animation.current = null;
    setIsExpanding(false);
    setIsClosing(false);
  };

  // Sync intial state of DetailsSummarySDS component with 'isOpen' prop
  useBrowserLayoutEffect(() => {
    if (!detailsRef.current) {
      return;
    }

    if (isOpen !== detailsRef.current.open) {
      detailsRef.current.open = isOpen;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Expand/shrink DetailsSummarySDS component when 'isOpen' prop changes
  useBrowserLayoutEffect(() => {
    window.requestAnimationFrame(isOpen ? expand : shrink);
  }, [isOpen, expand, shrink]);

  return (
    <div className={accordionItemCss} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <ProgressIndicator
        durationMs={progressIndicatorDurationMs}
        isActive={isOpen}
        isPaused={isPaused}
        onAnimationComplete={onAnimationComplete}
      />
      <DetailsSummarySDS
        disableScrollToOnOpen
        detailsRef={detailsRef}
        summaryRef={summaryRef}
        summary={title}
        showChevron={false}
        className={accordionItemDetailCss}
        onToggle={onToggle}
        summaryProps={{ className: accordionItemSummaryCss }}
        transitionDurationMs={animationOptions.duration}
        open={isOpen}
      >
        <div
          className={cx(accordionItemDetailContentCss, {
            [accordionItemDetailContentActiveCss]: isOpen || isExpanding || isClosing,
          })}
        >
          {body}
        </div>
      </DetailsSummarySDS>
    </div>
  );
};
