/* eslint-disable @typescript-eslint/no-explicit-any */
export const throttle = (
  func: (...someArgs: any) => any,
  limit: number,
  setIsInThrottle?: (value?: boolean) => void
): typeof func => {
  let inThrottle: boolean;

  return function (...args: Parameters<typeof func>): ReturnType<typeof func> | null {
    // Using 'arguments' because it seems to work better. :shrug:
    // eslint-disable-next-line prefer-rest-params
    // const useArgs = args ?? arguments;
    if (!inThrottle) {
      const returnValue = func(...args);
      inThrottle = true;

      if (typeof setIsInThrottle === 'function') {
        setIsInThrottle(true);
      }

      setTimeout(() => {
        inThrottle = false;

        if (typeof setIsInThrottle === 'function') {
          setIsInThrottle(false);
        }
      }, limit);

      return returnValue;
    }

    return null;
  };
};
