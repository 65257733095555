import type { ImageSources } from '@snapchat/mw-common/client';

import { ScreenSize } from '../styles/screenSizes';

/** Combines mobile and desktop sources so they can be used together in one <picture> element. */
export function combineImageSources(sources: {
  desktop?: ImageSources;
  mobile?: ImageSources;
}): ImageSources | undefined {
  if (!sources.mobile) return sources.desktop;

  if (!sources.desktop) return undefined;

  return {
    sources: [
      // List mobile sources first, but set the media query.
      ...sources.mobile.sources.map(source => ({
        ...source,
        media: source.media ?? `(max-width: ${ScreenSize.width_768_desktop_small}px)`,
      })),
      ...sources.desktop.sources,
    ],
    // TODO: Pass-in isMobile flag to choose between desktop or mobile for the default.
    // Defaults are reading from desktop.
    default: sources.desktop.default,
    defaultSrcSet: sources.desktop.defaultSrcSet,
    defaultSizes: sources.desktop.defaultSizes,
  };
}
