import { cx } from '@emotion/css';
import omit from 'lodash/omit';
import type { ChangeEvent, FC, ReactNode } from 'react';
import { memo, useCallback, useContext, useEffect } from 'react';

import { Icon } from '../Icon';
import { Field } from './Field';
import type { FormFieldComponentProps } from './Form.types';
import { FormEventType } from './Form.types';
import { FormContext } from './FormContext';
import { getStringValue } from './formUtils';
import { arrowCss, inputErrorCss, placeholderCss, selectCss, selectWrapperCss } from './styles';

type SelectField = FormFieldComponentProps & {
  allValues?: string[];
  children?: ReactNode;
  // This applies to the whole field, including label, help text, etc.
  // Compare to `dataset` which would be passed to the <select> element
  fieldDataset?: DOMStringMap;
};

export const Select: FC<SelectField> = memo(props => {
  const {
    name,
    placeholder,
    children,
    required = false,
    initialValue = '',
    allValues = [],
    shouldResetToInitial,
    fieldDataset,
    ...restProps
  } = props;
  const selectProps = {
    required,
    ...omit(
      restProps,
      'helpText',
      'helpTextDataset',
      'errorDataset',
      'labelDataset',
      'fieldDataset'
    ),
  };

  const { state, dispatch } = useContext(FormContext);
  const validInitialValue = allValues.includes(getStringValue(initialValue)) ? initialValue : '';
  const formField = state.fields[name];
  const value = state.formBody[name];

  useEffect(() => {
    dispatch({
      type: FormEventType.REGISTER_FIELD,
      name,
      field: {
        required,
        initialValue: validInitialValue,
        shouldResetToInitial,
      },
    });
  }, [dispatch, name, required, validInitialValue, shouldResetToInitial]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: FormEventType.CHANGE_FIELD_VALUE,
      name,
      value: e.target.value,
    });
  };

  const handleInvalid = useCallback(() => {
    dispatch({ type: FormEventType.INVALIDATE_FIELD, name });
  }, [dispatch, name]);

  return (
    <Field
      hasError={formField?.hasError}
      {...omit(props, 'dataset')}
      dataset={fieldDataset}
      type="Select"
    >
      <div className={cx(selectWrapperCss, placeholderCss)}>
        <select
          value={getStringValue(value)}
          name={name}
          className={cx(selectCss, { [inputErrorCss]: formField?.hasError })}
          onChange={handleChange}
          onBlur={handleChange}
          onInvalid={handleInvalid}
          {...selectProps}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <Icon name="chevron-down" className={arrowCss} />
      </div>
    </Field>
  );
});

Select.displayName = 'Select';
