/**
 * Environment variables used on the client.
 *
 * These do not have dynamic values on the client. They are exported during bundling. See
 * esbuild.client.ts
 *
 * The keys for this object need to match the key they are reading from in process.env otherwise the
 * values will be different between the client the build. The result of the build is stored into the
 * process.env of the client, so if the key is different, the value will be different because you
 * are always reading from the same key.
 *
 *     build:
 *       process.env = {
 *        b: 'value'
 *       };
 *       clientEnv = {
 *         a: process.env.b,
 *       }
 *
 *     client:
 *       // this process.env is set by the build using the values of clientEnv
 *       process.env = {
 *         a: 'value'
 *       };
 *       clientEnv = {
 *         a: process.env.b, // this ends up being undefined
 *       } *
 */
export const clientEnv = {
  BUILD_NUMBER: process.env.BUILD_NUMBER!,
  GOOGLE_CLOUD_PROJECT: process.env.GOOGLE_CLOUD_PROJECT!,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN!,
  CONTENTFUL_DEV_ACCESS_TOKEN: process.env.CONTENTFUL_DEV_ACCESS_TOKEN!,
  /** Override for the contentful environment name. Defaults to 'undefined' when not set. */
  CONTENTFUL_ENVIRONMENT_NAME: process.env.CONTENTFUL_ENVIRONMENT_NAME!,
  DEPLOYMENT_TYPE: process.env.DEPLOYMENT_TYPE!,
  GLOBAL_COMPONENTS_CONTENTFUL_PREVIEW_ACCESS_TOKEN:
    process.env.GLOBAL_COMPONENTS_CONTENTFUL_PREVIEW_ACCESS_TOKEN!,
  /**
   * This value is set in jenkins all the time, so we only forward the value if we are not in
   * production and we set the value in devToolsEnv.ts
   */
  GLOBAL_COMPONENTS_CONTENTFUL_DEV_ACCESS_TOKEN:
    process.env.GLOBAL_COMPONENTS_CONTENTFUL_DEV_ACCESS_TOKEN!,
  NODE_ENV: process.env.NODE_ENV!,
  PREVIEW: process.env.PREVIEW!,
  SITE_CONFIG: process.env.SITE_CONFIG!,
  SSR: process.env.SSR!,
  JEST_WORKER_ID: process.env.JEST_WORKER_ID!,
  // The ENV variables below are not resolved at compile time. They are dynamic.
  GOOGLE_CLOUD_REGION: process.env.GOOGLE_CLOUD_REGION ?? 'unknown_region',
  // Use npm script in elastic-etl package to:
  //  1 - start docker with Elasticsearchh container
  //  2 - seed Elasticsearch with data (reindex script)
  USE_LOCAL_ELASTIC: process.env.USE_LOCAL_ELASTIC,
  ELASTIC_SEARCH_READER_API_KEY: process.env.ELASTIC_SEARCH_READER_API_KEY,
} as const;

/** List of environment variables we don't want to hard-code into javascript. */
export const dynamicClientEnv: ReadonlyArray<keyof typeof clientEnv> = [
  'GOOGLE_CLOUD_REGION',
] as const;
