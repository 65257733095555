import { parseError } from '@snapchat/core';
import type { Dimensions } from '@snapchat/graphene';
import { Partition } from '@snapchat/graphene';
import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import clone from 'lodash/clone';
import head from 'lodash/head';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ContentfulContext } from '../../context';
import type { CookieModalType } from '../../generated/contentful-types';
import { CookieModalQueryDocumentType } from '../../generated/contentful-types';
import { BrowserGrapheneClient } from '../../helpers/logging';
import {
  acceptCustomCookies,
  createCookieMapping,
  fetchUserLocation,
  getAllCookies,
  getCookieDomain,
  removeCookiesForNonacceptedCategories,
  setOptInCookies,
} from '../../utils';
import type { DesignAgnosticButtonProps } from '../designAgnostic';
import { DesignAgnosticButton, DesignAgnosticSection } from '../designAgnostic';
import type { WithOnError } from '../ErrorBoundary';
import { withErrorBoundary } from '../ErrorBoundary';
import { CookieCategories } from '../shared/CookieCategories';
import { BackgroundType, CategoryOptInCookie, EventAction } from '../types';

interface OnSubmitEvent {
  /**
   * User's location per API endpoint. Included as a convenience for consuming applications to avoid
   * multiple calls to that endpoint if configuring GTM/GA, etc.
   */
  userLocation: string;
  /** Category Cookies and acceptance state for the user. */
  cookieAcceptance: Record<string, boolean>;
}

export interface CookieSettingsProps extends WithOnError {
  backgroundType?: BackgroundType;
  cookieDomain?: string;
  /**
   * Fires when a user submits changes to their cookie settings. This event hook is used to
   * enable/disable features per user cookie acceptance.
   */
  onSubmit?: (event: OnSubmitEvent) => void;
  /** On event listener for user actions */
  onEvent?: (event: { component: string; label: string; action: EventAction }) => void;
}

enum ButtonStates {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Saved = 'Saved',
}

const displayName = 'CookieSettings';

const CookieSettings: FC<CookieSettingsProps> = ({
  backgroundType = BackgroundType.LightMode,
  cookieDomain: inputCookieDomain,
  onSubmit,
  onEvent,
}) => {
  // ==============================================================================================
  // Fetch content from Contentful
  // ==============================================================================================
  const context = useContext(ContentfulContext);
  const { data } = useGlobalComponentsContentfulQuery(CookieModalQueryDocumentType, context, {
    client: context.client,
  });
  const config = head(data?.cookieModalCollection.items) as CookieModalType | undefined;
  const { categorySettings, cookieCategoryMap, enableCookieDeletion } = useMemo(() => {
    if (!config?.cookieCategoriesCollection?.items)
      return {
        categorySettings: undefined,
        cookieCategoryMap: undefined,
        enableCookieDeletion: false,
      };

    const categorySettings = config.cookieCategoriesCollection.items;

    const cookieCategoryMap = createCookieMapping(config.cookieCategoriesCollection.items);

    // Settings for cookie deletion
    const { enableCookieDeletion } = config;

    return { categorySettings, cookieCategoryMap, enableCookieDeletion };
  }, [config]);

  // ==============================================================================================
  // Internal State
  // ==============================================================================================
  const cookieDomain = getCookieDomain(inputCookieDomain);

  const [userLocation, setUserLocation] = useState<string>('Unknown');

  const [categoryState, setCategoryState] = useState<Record<string, boolean>>({});

  const updateCategoryState = (key: string, value: boolean) => {
    const newState = clone(categoryState);
    newState[key] = value;
    setCategoryState(newState);
    setSaveButtonState(ButtonStates.Enabled);
  };

  const [saveButtonState, setSaveButtonState] = useState<ButtonStates>(ButtonStates.Disabled);

  const updateSaveButton = () => {
    setSaveButtonState(ButtonStates.Saved);

    setTimeout(() => {
      setSaveButtonState(ButtonStates.Disabled);
    }, 3000);
  };

  const [grapheneClient, setGrapheneClient] = useState<BrowserGrapheneClient>();

  // ==============================================================================================
  // Helper Functions
  // ==============================================================================================
  const updateUserCookies = useCallback(
    (cookiesToSet: Record<string, boolean>) => {
      // If mappings have not been loaded, treat as noop
      if (!cookieCategoryMap || !categorySettings) return;

      setOptInCookies(cookieDomain, cookiesToSet);

      const results = removeCookiesForNonacceptedCategories(
        cookieDomain,
        cookiesToSet,
        cookieCategoryMap,
        enableCookieDeletion
      );

      // log any cookie removals that were incomplete (indicates misconfiguration of cookie attributes)
      for (const cookieName in results) {
        // removal was successful, noop
        if (results[cookieName]?.wasRemoved) continue;

        // otherwise log so we can review the configuration.  Graphene doesn't support enough dimensions
        // for us to store the full configuration, so domain and cookieName will have to do.
        const dimensions: Dimensions = {
          cookieName,
          userLocation,
        };
        grapheneClient?.logMetric('tracking_cookie_misconfiguration', dimensions);
      }
    },
    [
      cookieDomain,
      userLocation,
      cookieCategoryMap,
      categorySettings,
      grapheneClient,
      enableCookieDeletion,
    ]
  );

  // ==============================================================================================
  // useEffect calls
  // ==============================================================================================

  useEffect(() => {
    const host = window.location.hostname;
    const client = new BrowserGrapheneClient(Partition.COOKIE_MODAL_COMPONENTS, host);
    setGrapheneClient(client);
  }, [setGrapheneClient]);

  useEffect(() => {
    // If mappings have not been loaded, treat as noop
    if (!categorySettings) return;

    const retrieveUserCookies = () => {
      // Calling getAllCookies in order to check current Cookies and to help set default values
      const retrievedCookies = getAllCookies(categorySettings);
      const hasPreviouslyAcceptedCookies = retrievedCookies[CategoryOptInCookie.Essential];

      if (hasPreviouslyAcceptedCookies) {
        setCategoryState(retrievedCookies);
        clearInterval(interval);
      }
    };
    const interval = setInterval(retrieveUserCookies, 1000);

    // call immediately rather than awaiting the interval period
    retrieveUserCookies();

    return () => clearInterval(interval);
  }, [categorySettings, setCategoryState]);

  // Retrieve user's location
  useEffect(() => {
    // Wait for grapheneClient to initialize before executing
    if (!grapheneClient) return;

    // Retrieving user's country and region
    const fetchUserRegion = async () => {
      try {
        const userLocation = await fetchUserLocation();
        setUserLocation(userLocation);
      } catch (unknownError) {
        const error = parseError(unknownError);
        grapheneClient.logError(displayName, 'userLocation', error);
      }
    };
    void fetchUserRegion();
  }, [setUserLocation, grapheneClient]);

  // ==============================================================================================
  // User Interaction handlers
  // ==============================================================================================

  const acceptSelected = () => {
    // If mappings have not been loaded, treat as noop
    if (!categorySettings) return;

    const cookiesToSet = acceptCustomCookies(categorySettings, categoryState);
    updateUserCookies(cookiesToSet);
    updateSaveButton();
    logUserAction('save_changes', cookiesToSet);

    // Fire onSubmit event hook
    onSubmit?.({
      userLocation,
      cookieAcceptance: cookiesToSet,
    });
  };

  const logUserAction = (label: string, cookieCategories: Record<string, boolean>) => {
    // Log event to event listener from GlobalComponentsContext
    onEvent?.({
      component: displayName,
      action: EventAction.Click,
      label,
    });

    // Also log event to dedicated CookieComponents Graphene Partition
    const dimensions: Dimensions = {
      preferences: `${cookieCategories[CategoryOptInCookie.Preferences] ?? false}`,
      performance: `${cookieCategories[CategoryOptInCookie.Performance] ?? false}`,
      marketing: `${cookieCategories[CategoryOptInCookie.Marketing] ?? false}`,
      userLocation,
    };
    grapheneClient?.logMetric(`${label}_clicks_settings`, dimensions);
  };

  if (!config) {
    return null;
  }

  const buttonProps: DesignAgnosticButtonProps = {
    isDisabled: saveButtonState === ButtonStates.Disabled,
    isPrimary: saveButtonState === ButtonStates.Saved ? false : true,
    testId: 'mwp-cookie-settings-save-btn',
    text: saveButtonState === ButtonStates.Saved ? config.changesSavedText : config.saveChangesText,
    onClick: acceptSelected,
  };

  return (
    <DesignAgnosticSection backgroundType={backgroundType}>
      <div data-testid="mwp-cookie-settings-page-body" className="settings-page-body">
        <CookieCategories
          title={config.settingsScreenTitle}
          cookieCategories={config.cookieCategoriesCollection.items}
          categoriesState={categoryState}
          updateCategoriesState={updateCategoryState}
          activeToggleLabel={config.toggleEnabledText}
          inactiveToggleLabel={config.toggleDisabledText}
        />
      </div>
      <div data-test-id="mwp-cookie-settings-page-footer" className="settings-page-footer">
        <DesignAgnosticButton {...buttonProps} />
      </div>
    </DesignAgnosticSection>
  );
};

// NOTE: for some reason displayName is not automatically deetected by ErrorBoundary.  Need to explicitly set it.
CookieSettings.displayName = displayName;

const wrapped = withErrorBoundary(CookieSettings, Partition.COOKIE_MODAL_COMPONENTS);

export { wrapped as CookieSettings };
