import { useContentfulImages } from '@snapchat/mw-global-components';
import { ImageButton as ImageButtonSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysId } from '../../utils/contentful/contentfulQuery';
import { parseMedia } from '../../utils/media';
import type { ImageButtonData } from './imageButtonQuery';
import { query } from './imageButtonQuery';

export const LazyImageButton: FC<ContentfulSysId> = props => {
  const { data } = useContentfulQuery<ImageButtonData, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
  });
  const { getImageSources } = useContentfulImages();

  if (!data?.imageButton) return null;

  const {
    imageButton: { image },
  } = data;

  const { imageSource, imageAltText } = parseMedia(image);
  const imgSrcs = getImageSources(imageSource);
  return <ImageButtonSDS {...data.imageButton} imgSrcs={imgSrcs} altText={imageAltText} />;
};
