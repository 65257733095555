import { Site } from '@snapchat/mw-common';
import invert from 'lodash-es/invert';

/** Space IDs for all of the contentful spaces under MWP. */
export const contentfulSpaceIds: Record<Site, string> = {
  [Site.A523]: '6f4y0nzsmtwg',
  [Site.ARCADIA]: '8tv80wwv755s',
  [Site.AR]: 'ub38vssza5h3',
  [Site.CAREERS]: 'jwenq9l5fmib',
  [Site.CHEERIOS]: 'svn43w404u4n',
  [Site.CITIZEN]: 'ngit5gcrb661',
  [Site.CREATORS]: 'ji04otasc2ol',
  [Site.DEVELOPER]: 'x1e0urae4t9e',
  [Site.DIVERSITY]: 'kql3ubzzanzk',
  [Site.EDUCATORS]: 'ou2g43s52fjc',
  [Site.ENG_BLOG]: '7w2tf600vbko',
  [Site.EXPERIENCE]: 'zu0g3q4so0yf',
  [Site.FOR_BUSINESS]: 'inb32lme5009',
  [Site.NEWSROOM]: 'o1znirz7lzo4',
  [Site.ORIGINALS]: '7hin9gdh0aky',
  [Site.PARENTS]: 'e6fbkkb3ka3b',
  [Site.SANDBOX]: 'akh6d57cjeed',
  [Site.SPS]: 'iprgafuf908x',
  [Site.TRUST]: 'kw9k15zxztrs',
  [Site.YELLOW_LA]: '0pw0q4oh1m3z',
  [Site.AVALON]: 'cbjigzuhgs28',
  [Site.SNAP]: 'dwtpq5hdcqjg',
};

export const siteBySpaceId = invert(contentfulSpaceIds);
