/**
 * Maps a locale code to its native title
 *
 * Values are adopted from https://www.npmjs.com/package/langmap npm package and modified as needed
 */
export const localeMap: Record<string, string> = {
  ar: 'العربية',
  'bn-BD': 'বাংলা(বাংলাদেশ)',
  'bn-IN': 'বাংলা (ভারত)',
  'bg-BG': 'Български',
  'zh-Hans': '中文简体',
  'zh-Hant': '中文繁體',
  'hr-HR': 'Hrvatski',
  'cs-CZ': 'Čeština',
  'da-DK': 'Dansk',
  'nl-NL': 'Nederlands (Nederland)',
  'en-GB': 'English (UK)',
  'en-US': 'English (US)',
  'et-EE': 'Eesti',
  'fil-PH': 'Filipino (Philippines)',
  'fi-FI': 'Suomi',
  'fr-FR': 'Français (France)',
  'de-DE': 'Deutsch (Deutschland)',
  'el-GR': 'Ελληνικά',
  'gu-IN': 'ગુજરાતી',
  'hi-IN': 'हिन्दी',
  'hu-HU': 'Magyar',
  'id-ID': 'Bahasa Indonesia',
  'ga-IE': 'Éireannach',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'kn-IN': 'ಕನ್ನಡ (India)',
  'ko-KR': '한국어',
  'lv-LV': 'Latviešu',
  'lt-LT': 'Lietuvių',
  'ms-MY': 'Bahasa Melayu',
  'ml-IN': 'മലയാളം',
  'mt-MT': 'Malti',
  'mr-IN': 'मराठी',
  'nb-NO': 'Norsk (bokmål)',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (Brasil)',
  'pt-PT': 'Português (Portugal)',
  pa: 'ਪੰਜਾਬੀ',
  'ro-RO': 'Română',
  'ru-RU': 'Русский',
  'sk-SK': 'Slovenčina',
  'sl-SI': 'Slovenščina',
  'es-AR': 'Español (Argentine)',
  es: 'Español',
  'es-MX': 'Español (México)',
  'es-ES': 'Español (España)',
  'sv-SE': 'Svenska',
  'ta-IN': 'தமிழ்',
  'te-IN': 'తెలుగు',
  'th-TH': 'ภาษาไทย (ประเทศไทย)',
  'tr-TR': 'Türkçe',
  'ur-PK': 'اردو',
  'uk-UA': 'Українська',
  'vi-VN': 'Tiếng Việt',
};
