import { cx } from '@emotion/css';
import noop from 'lodash/noop';
import type { FC, ReactNode } from 'react';
import type React from 'react';
import { useRef, useState } from 'react';

import type { VideoPanelAlignment } from '../../constants';
import { MediaMode } from '../../constants';
import { useMediaMode } from '../../hooks/useMediaMode';
import { MotifComponent, useMotifStyles } from '../../motif';
import type { IconName } from '../Icon';
import type { TogglePanelItem } from '../TogglePanel/TogglePanel';
import { TogglePanel } from '../TogglePanel/TogglePanel';
import {
  backgroundImageCss,
  multiVideoBlockCss,
  slidePanelCss,
  slidePanelMobileCss,
  togglePanelWithBreakSpacerWrapCss,
  togglePanelWrapCss,
} from './styles';
import { VideoItem } from './VideoItem';

export interface MediaItem {
  url: string;
  contentType?: string;
}

export interface VideoItem extends TogglePanelItem {
  posterSource?: string;
  media: MediaItem;
  thumbnailMedia: MediaItem;
}

export interface MultiVideoBlockProps {
  anchorId?: string;
  children?: ReactNode | Array<ReactNode>;
  className?: string;
  hasVideoSync?: boolean;
  id: string;
  mobilePrimaryVideos: Array<VideoItem>;
  mobileSecondaryVideos: Array<VideoItem>;
  onVideoChange?: (index: number, isChecked: boolean) => void;
  panelPosition: VideoPanelAlignment;
  primaryVideos: Array<VideoItem>;
  secondaryVideos: Array<VideoItem>;
  style?: React.CSSProperties;
  toggleButtonIcons: Array<IconName>;
  togglePanelClassName?: string;
  togglePanelLabel?: string;
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}

export const MultiVideoBlock: FC<MultiVideoBlockProps> = ({
  anchorId,
  children,
  className,
  hasVideoSync = true,
  id,
  mobilePrimaryVideos,
  mobileSecondaryVideos,
  onVideoChange = noop,
  panelPosition,
  primaryVideos,
  style,
  secondaryVideos,
  toggleButtonIcons,
  togglePanelClassName,
  togglePanelLabel,
  preChildren,
  postChildren,
}: MultiVideoBlockProps) => {
  const [sliderChecked, setSliderChecked] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const currentTime = useRef(0);
  useMotifStyles(MotifComponent.MULTI_VIDEO_BLOCK);

  const mode = useMediaMode();
  const isMobile = mode === MediaMode.Mobile;

  const videoGroup = isMobile
    ? [mobilePrimaryVideos, mobileSecondaryVideos]
    : [primaryVideos, secondaryVideos];
  const videos = sliderChecked ? videoGroup[1]! : videoGroup[0]!;
  const selectedVideo = videos[selectedVideoIndex];

  const allVideos = isMobile
    ? [...mobilePrimaryVideos, ...mobileSecondaryVideos]
    : [...primaryVideos, ...secondaryVideos];

  const handleButtonToggle = (index: number) => {
    setSelectedVideoIndex(index);
    currentTime.current = 0;
    onVideoChange(index, sliderChecked);
  };

  const handleSliderCheck = (isSliderChecked: boolean) => {
    setSliderChecked(isSliderChecked);
    onVideoChange(selectedVideoIndex, isSliderChecked);
  };

  const prefetchedVideos = allVideos
    .filter(video => video?.media?.url)
    .map((video, index) => {
      return <link key={index} rel="prefetch" as="video" href={video.media.url} />;
    });

  return (
    <div
      id={anchorId}
      style={style}
      className={cx(MotifComponent.MULTI_VIDEO_BLOCK, multiVideoBlockCss, className)}
    >
      {prefetchedVideos}

      {/* This component will display with zero height, when used as a page block,
      if a thumbnail is not selected in Contentful */}
      {selectedVideo?.thumbnailMedia?.url && (
        <img className={backgroundImageCss} src={selectedVideo.thumbnailMedia.url} />
      )}

      {selectedVideo && (
        <VideoItem
          hasVideoSync={hasVideoSync}
          isSelected={true}
          video={selectedVideo}
          currentTime={currentTime}
        />
      )}

      {children}

      {/* This container covers the whole component height and contains a 'BreakSpacer' at the top and/or bottom
      (if a 'Break' overlays this component in a page) and an inner container that takes up the remaining height.
      The 'TogglePanel' is positioned absolute in the inner container so that it can be offset by the 'BreakSpacer'. */}
      <div className={togglePanelWithBreakSpacerWrapCss}>
        {preChildren}
        <div className={togglePanelWrapCss}>
          <TogglePanel
            id={id}
            items={videos}
            label={togglePanelLabel}
            sliderChecked={sliderChecked}
            onSliderUpdate={handleSliderCheck}
            onButtonToggle={handleButtonToggle}
            hasToggleSlider={!!videoGroup[1]?.length}
            selectedId={(videos[selectedVideoIndex] as VideoItem).id}
            className={cx(slidePanelCss[panelPosition], slidePanelMobileCss, togglePanelClassName)}
            buttonIcons={toggleButtonIcons}
          />
        </div>
        {postChildren}
      </div>
    </div>
  );
};

MultiVideoBlock.displayName = 'MultiVideoBlock';
