import { FormattedMessage } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import {
  noResultsFoundContainerCss,
  noResultsFoundImageCss,
  noResultsFoundSubtitleCss,
  noResultsFoundTitleCss,
} from './NoResultsFound.styles';

export const NoResultsFound: FC = () => (
  <div className={noResultsFoundContainerCss}>
    <h4 className={noResultsFoundTitleCss} tabIndex={0}>
      <FormattedMessage id="searchNotFoundTitle" defaultMessage="Sorry, no results were found" />
    </h4>
    <p className={noResultsFoundSubtitleCss} tabIndex={0}>
      <FormattedMessage
        id="searchNotFoundSubtitle"
        defaultMessage="Please try again with a different keyword."
      />
    </p>
    <img alt="confused ghost logo" src="/images/404.png" className={noResultsFoundImageCss} />
  </div>
);

NoResultsFound.displayName = 'NoResultsFound';
