import type { FC, ReactElement } from 'react';

import { Carousel } from './Carousel';
import type { ResponsiveType } from './ReactMultiCarousel/types';
import type { CarouselProps, ItemsSchedule } from './types';

export const isCarouselElement = (elem: unknown): elem is ReactElement<CarouselProps> => {
  const asCarousel = elem as ReactElement<CarouselProps>;

  if (asCarousel.type === Carousel) {
    return true;
  }

  // evil displayName check
  const typeAsFC = (elem as ReactElement).type as FC;
  return typeAsFC.displayName === 'Carousel';
};

export const unfoldItems = (preitems?: number | ItemsSchedule): ItemsSchedule => {
  if (typeof preitems === 'object') {
    return preitems;
  }
  const items = preitems || 1;
  return {
    superLargeDesktop: items,
    desktop: items,
    tablet: items,
  };
};

export interface GenerateResponsiveProps {
  // The maximum number of items we want to show at a time.
  maxItems: number;
  // The expected width of the items, including padding and margins.
  itemWidth: number;
  // Returns the screen space needed for rendering n pixels of carousel.
  spaceNeeded?: (x: number) => number;
}

const iden = (x: number) => x;

export const generateResponsive = ({
  maxItems,
  itemWidth,
  spaceNeeded = iden,
}: GenerateResponsiveProps): ResponsiveType => {
  const responsiveSettings: ResponsiveType = {};

  for (let itemsVisible = 1; itemsVisible <= maxItems; itemsVisible++) {
    const settingName = `responsive-with-${itemsVisible}-items-visible`;

    responsiveSettings[settingName] = {
      items: itemsVisible,
      breakpoint: {
        min: spaceNeeded(itemsVisible * itemWidth + 1),
        max: spaceNeeded((itemsVisible + 1) * itemWidth),
      },
    };
  }

  // We need to add the setting for resolutions that can show
  // more than all cards.
  responsiveSettings['all-items-visible'] = {
    items: maxItems,
    breakpoint: {
      min: spaceNeeded((maxItems + 1) * itemWidth + 1),
      max: Number.MAX_SAFE_INTEGER,
    },
  };

  return responsiveSettings;
};

/**
 * This method is used to calculate the right number for the maxItems.
 *
 * The requirements for the carousel are:
 *
 * When maxItems is equal to 2 or 4 the layout to be used is the layout for maxItem equal 3 or
 * maxItem equal 5
 */
export const handleMaxItems = (nMaxItems?: number): number | undefined => {
  return nMaxItems && [2, 4].includes(nMaxItems) ? nMaxItems + 1 : nMaxItems;
};
