import { cx } from '@emotion/css';
import { Highlight } from 'prism-react-renderer';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { codeCss } from './Code.styles';

export interface CodeProps {
  code?: string;
  language: string;
}

export const Code: FC<CodeProps> = ({ code = '', language }) => {
  useMotifStyles(MotifComponent.CODE);
  return (
    <div className={cx(MotifComponent.CODE, codeCss)}>
      <Highlight code={code} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre dir="ltr" className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })} key={i}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  );
};

Code.displayName = 'Code';
