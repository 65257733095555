import { css } from '@emotion/css';

import { maxWidth, mobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const footnoteCss = css`
  font-size: 12px;
  padding: 0 ${m('--spacing-xl')};
  max-width: ${maxWidth}px;
  margin: 0 auto ${m('--spacing-s')};
  box-sizing: border-box;

  ${mobileMediaQuery} {
    padding: 0 ${m('--spacing-xs')};
  }
`;
