import { PauseIcon, PlayFilledIcon } from '@snapchat/snap-design-system-icons';
import type { FC } from 'react';

import { ProgressIndicatorMobile } from './ProgressIndicatorMobile';
import {
  accordionControlsContainerCss,
  dotContainerCss,
  dotCss,
  navButtonCss,
  navDotsContainerCss,
  progressIndicatorContainerCss,
} from './styles';
import type { AnimatedAccordionControlsProps } from './types';

export const AnimatedAccordionControls: FC<AnimatedAccordionControlsProps> = props => {
  const {
    currentItemIndex,
    durationMs = 7000, // Default duration is set by design (7 secs)
    isActive,
    isPaused,
    itemsCount,
    onAnimationComplete,
    onPause,
  } = props;

  return (
    <div className={accordionControlsContainerCss}>
      <div className={navDotsContainerCss}>
        {Array.from({ length: itemsCount }).map((_, index) => {
          if (currentItemIndex === index) {
            return (
              <div key={index} className={progressIndicatorContainerCss}>
                <ProgressIndicatorMobile
                  durationMs={durationMs}
                  isActive={isActive}
                  isPaused={isPaused}
                  onAnimationComplete={onAnimationComplete}
                />
              </div>
            );
          } else {
            return (
              <div key={index} className={dotContainerCss}>
                <div className={dotCss}></div>
              </div>
            );
          }
        })}
      </div>
      <button className={navButtonCss} onClick={() => onPause()}>
        {isPaused ? <PlayFilledIcon /> : <PauseIcon />}
      </button>
    </div>
  );
};
