import type { FC } from 'react';
import { useContext } from 'react';

import { MediaMode } from '../../../constants';
import { FooterContext } from '../FooterContext';
import { FooterColumnsDesktop } from './FooterColumnsDesktop';
import { FooterColumnsMobile } from './FooterColumnsMobile';
import type { FooterColumnsProps } from './types';

export const FooterColumns: FC<FooterColumnsProps> = ({ children, ...props }) => {
  const { mode } = useContext(FooterContext);

  if (mode === MediaMode.Mobile) {
    return <FooterColumnsMobile {...props}>{children}</FooterColumnsMobile>;
  } else {
    return <FooterColumnsDesktop {...props}>{children}</FooterColumnsDesktop>;
  }
};
