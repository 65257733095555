import { css } from '@emotion/css';
import { getLocalStorageItem, setLocalStorageItem } from '@snapchat/mw-common';
import type {
  Form as FormType,
  FormRow as FormRowType,
  FormRowFieldsItem,
} from '@snapchat/mw-contentful-schema';
import type { FormBody, SubmitProps } from '@snapchat/snap-design-system-marketing';
import {
  Form as FormSDS,
  FormRow as FormRowSDS,
  MessageContext,
} from '@snapchat/snap-design-system-marketing';
import debounce from 'lodash-es/debounce';
import merge from 'lodash-es/merge';
import type { FC, ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { logError, logger, logUserEvent } from '../../helpers/logging';
import type { CustomArkoseClient } from '../../hooks/useArkose';
import { useArkose } from '../../hooks/useArkose';
import { UserAction } from '../../types/events';
import type { Items } from '../../types/Items';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { renderRichTextWithEmbeddingsNoHeadings } from '../../utils/renderText/renderRichText';
import { ConsumerContext } from '../ConsumerContextProvider';
import { FieldByType } from './FieldByType';
import type {
  ContentfulFormRow,
  ContentfulInputField,
  FormDataProps,
  FormResponseWithUrl,
} from './types';
import { FormResponseType } from './types';
import { getFormExtraParameters, getFormField, getFormQueryParams } from './utils';

const hiddenCss = css`
  opacity: 0;
  visibility: hidden;
`;

const defaultRedirectTimeout = 4000;

const formKey = 'mwp-form-data';

const FormRow: FC<
  ContentfulFormRow & { fieldRender: (item: ContentfulInputField) => ReactNode }
> = ({ rowAsText, sys, fieldsCollection, fieldRender }) => {
  const { rowAsTextDataset } = getContentfulInspectorProps<FormRowType>({
    entryId: sys.id,
    fieldIds: ['rowAsText'],
  });

  if (rowAsText) {
    return (
      <FormRowSDS key={sys.id} dataset={rowAsTextDataset}>
        {renderRichTextWithEmbeddingsNoHeadings(rowAsText)}
      </FormRowSDS>
    );
  }

  return <FormRowSDS key={sys.id}>{fieldsCollection.items.map(fieldRender)}</FormRowSDS>;
};

const makeFormChildrenRenderer = (
  rowsCollection: Items<ContentfulFormRow>,
  submitText: string,
  submitSuccessText?: string,
  submitTextDataset?: DOMStringMap,
  formInitialOverrides?: FormBody
): FC<SubmitProps> => {
  const RowRenderer: FC<SubmitProps> = submitProps => (
    <>
      {rowsCollection.items.map((item: ContentfulFormRow) => (
        <FormRow
          {...item}
          key={item.sys.id}
          fieldRender={(item: ContentfulInputField) => (
            <FieldByType
              key={item.sys.id}
              contentfulInputField={item}
              submitProps={submitProps}
              submitText={submitText}
              submitSuccessText={submitSuccessText}
              submitTextDataset={submitTextDataset}
              initialValue={formInitialOverrides?.[item.name!]}
            />
          )}
        />
      ))}
    </>
  );

  return RowRenderer;
};

export const Form: FC<FormDataProps> = ({
  rowsCollection,
  additionalFeaturesCollection,
  analytics,
  redirectUrl,
  submitText,
  submitSuccessText,
  endpoint,
  responseType,
  redirectTimeout = defaultRedirectTimeout,
  extraParams,
  callback,
  enableArkose = Config.enableArkoseOnForms,
  arkosePublicDevKey,
  arkosePublicProdKey,
  sys,
  persistFieldEdits,
  prepopulatePerQueryParams,
  ...rest
}) => {
  // local storage state. We are using a ref to avoid re-rendering on form updates.
  const [initalFormValues, setInitialFormValues] = useState<FormBody>({});
  const allFormLocalStorageRef = useRef<Record<string, FormBody>>({});

  const { userLocation, currentLocale, getCurrentUrl } = useContext(AppContext);
  const country = userLocation.country;

  useEffect(() => {
    const initialFormBody: FormBody = {};

    // form field controls if we should use local storage or query params.
    if (persistFieldEdits) {
      const allLocalStorageForms = JSON.parse(getLocalStorageItem(formKey) ?? '{}');

      if (allLocalStorageForms[sys.id]) {
        merge(initialFormBody, allLocalStorageForms[sys.id]);
      }

      allFormLocalStorageRef.current[sys.id] = initialFormBody;
    }

    // override local storage values with query params if they exist
    if (prepopulatePerQueryParams) {
      const currentUrl = getCurrentUrl();
      const queryParamsValues = getFormQueryParams(currentUrl, (fieldName: string) =>
        getFormField({ rowsCollection }, fieldName)
      );

      for (const [key, value] of queryParamsValues) {
        initialFormBody[key] = value;
      }
    }

    setInitialFormValues(initialFormBody);
  }, [persistFieldEdits, sys.id, getCurrentUrl, rowsCollection, prepopulatePerQueryParams]);

  const { logEvent } = useContext(ConsumerContext);

  const arkoseRef = useRef<CustomArkoseClient>();

  const arkosePromiseResolveRef = useRef<(value: FormBody) => void>();
  const arkosePromiseRejectRef = useRef<(reason: string) => void>();

  useArkose({
    arkoseClientRef: arkoseRef,
    enableArkose,
    arkoseDevKey: arkosePublicDevKey,
    arkoseProdKey: arkosePublicProdKey,

    onCompleted: token => {
      arkosePromiseResolveRef.current?.({ arkoseToken: token });
    },
    onError: error => {
      logger.logError({ component: 'Form', error, message: 'Arkose error', action: 'Validate' });
      arkosePromiseRejectRef.current?.(error ?? 'Arkose error');
    },
  });

  // Retrieve localized messages
  const { formatMessage } = useContext(MessageContext);
  const on400ResponseMessage = formatMessage({
    id: 'formApiResponse400ErrorMessage',
    defaultMessage: '400 Bad Request',
  });
  const on500ResponseMessage = formatMessage({
    id: 'formApiResponse500ErrorMessage',
    defaultMessage: '500 Internal Server',
  });

  const [formDownloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const anchorTag = useRef<HTMLAnchorElement>(null);

  const { submitTextDataset } = getContentfulInspectorProps<FormType>({
    entryId: sys.id,
    fieldIds: ['submitText'],
  });

  useEffect(() => {
    if (formDownloadUrl && anchorTag.current) {
      anchorTag.current.click();
    }
  }, [formDownloadUrl]);

  const onSubmitSuccess = useCallback(
    async (response: Response, formBody: FormBody): Promise<void> => {
      try {
        if (analytics && logEvent) {
          logEvent({ type: UserAction.FormSubmit, label: analytics.label });
        }

        if (callback) {
          callback(response, formBody);
        }

        let formRedirectUrl = redirectUrl;

        try {
          if (responseType === FormResponseType.DownloadableResourceUrl) {
            const data = (await response.json()) as FormResponseWithUrl;
            setDownloadUrl(data.url);
          } else if (responseType === FormResponseType.RedirectUrl && !redirectUrl) {
            const data = (await response.json()) as FormResponseWithUrl;
            formRedirectUrl = data.url ?? '';
          }
        } catch (err) {
          // reset to default
          setDownloadUrl(undefined);
        }

        if (formRedirectUrl) {
          setTimeout(() => {
            window.location.assign(formRedirectUrl as string);
          }, redirectTimeout);
        }

        if (persistFieldEdits) {
          // remove the whole form from local storage
          delete allFormLocalStorageRef.current[sys.id];
          setLocalStorageItem(formKey, JSON.stringify(allFormLocalStorageRef.current));
        }
      } catch (error) {
        logError({ component: 'Form', error });
      }
    },
    [
      analytics,
      logEvent,
      callback,
      redirectUrl,
      persistFieldEdits,
      responseType,
      redirectTimeout,
      sys.id,
    ]
  );

  const onSubmitFailure = useCallback((error: Error, _formBody: FormBody, _response?: Response) => {
    logError({ component: 'Form', error, message: 'Form submit failed', action: 'Submit' });
  }, []);

  const hasSubmitButton = useMemo(() => {
    return rowsCollection.items
      .flatMap((item: ContentfulFormRow) => item.fieldsCollection.items)
      .some(({ __typename: typename }: FormRowFieldsItem) => typename === 'SubmitButton');
  }, [rowsCollection]);

  const persistentFormFields = initalFormValues;

  // "rowsCollection", "submitTextDataset", and "persistentFormFields" are each reference types, therefore React will use
  // shallow comparison to determine if the memoized value should be updated. Unfortunately, this means that every re-render
  // of the Form component will cause the memoized value to be updated as the reference to the each of these fields will
  // change even if the value of the objects is the same. We SHOULD get rid of this FormChildRenderer (the only reason
  // we need it is to support the "SubmitButton" field via Contentful), but the quick and dirty fix for this is to stringify
  // these particular properties before using them in the dependency array.
  // TODO: Get rid of the "formChildrenRenderer" and find another way to support the "SubmitButton" field via Contentful.
  const rowsAsJson = JSON.stringify(rowsCollection);
  const submitTextDatasetAsJson = JSON.stringify(submitTextDataset);
  const persistFormFieldsAsJson = JSON.stringify(persistentFormFields);

  const FormChildrenRenderer = useMemo(() => {
    if (hasSubmitButton) {
      return makeFormChildrenRenderer(
        rowsCollection,
        submitText,
        submitSuccessText,
        submitTextDataset,
        persistentFormFields
      );
    }

    return undefined;
    // See comment above for full explanation. TLDR: we can't use submitTextDataset or persistentFormFields in the dependency
    // array because they are reference types and will cause the creation of a new FormChildRenderer every time the Form
    // component re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasSubmitButton,
    rowsAsJson,
    submitText,
    submitSuccessText,
    submitTextDatasetAsJson,
    persistFormFieldsAsJson,
  ]);

  const formRows = useMemo(() => {
    return rowsCollection.items.map((item: ContentfulFormRow) => (
      <FormRow
        {...item}
        key={item.sys.id}
        fieldRender={(item: ContentfulInputField) => {
          return (
            <FieldByType
              key={item.sys.id}
              contentfulInputField={item}
              submitText={submitText}
              submitSuccessText={submitSuccessText}
              initialValue={persistentFormFields?.[item.name!]}
            />
          );
        }}
      />
    ));
  }, [persistentFormFields, rowsCollection.items, submitSuccessText, submitText]);

  const getArkoseToken = useCallback(async () => {
    arkoseRef.current?.run();

    return new Promise<FormBody>((resolve, reject) => {
      arkosePromiseResolveRef.current = resolve;
      arkosePromiseRejectRef.current = reject;
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFormValueChange = useCallback(
    // Debounced to not do too many writes to local storage or send too many events
    debounce((formBody: FormBody, lastChangedFieldName?: string, isInit?: boolean) => {
      lastChangedFieldName &&
        logUserEvent({
          eventCategory: 'FormField',
          eventAction: 'Change',
          eventLabel: lastChangedFieldName,
        });

      // If the form is in its initial "blank" state, we don't want to save the form body to local storage
      // otherwise we risk overwriting previously saved form data.
      if (!persistFieldEdits || isInit) return;

      allFormLocalStorageRef.current[sys.id] = formBody;
      setLocalStorageItem(formKey, JSON.stringify({ ...allFormLocalStorageRef.current }));
    }, 1e3),
    [sys.id]
  );

  const finalExtraParams = useMemo(() => {
    const currentUrl = getCurrentUrl();
    const formExtraParameters = getFormExtraParameters(additionalFeaturesCollection, currentUrl);

    return { ...formExtraParameters, ...extraParams, locale: currentLocale, country };
  }, [additionalFeaturesCollection, extraParams, getCurrentUrl, currentLocale, country]);

  return (
    <>
      <FormSDS
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFailure={onSubmitFailure}
        submitText={submitText}
        submitSuccessText={submitSuccessText}
        submitTextDataset={submitTextDataset}
        endpoint={endpoint}
        formChildrenRenderer={FormChildrenRenderer}
        extraParams={finalExtraParams}
        extraParamsAsync={enableArkose ? getArkoseToken : undefined}
        onFormBodyChange={onFormValueChange}
        on400ResponseMessage={on400ResponseMessage}
        on500ResponseMessage={on500ResponseMessage}
        renderErrorDetails={Config.isLocal}
        {...rest}
      >
        {formRows}
      </FormSDS>
      {responseType === FormResponseType.DownloadableResourceUrl && (
        <a
          data-testid="test-anchor-download"
          ref={anchorTag}
          href={formDownloadUrl}
          download
          className={hiddenCss}
        />
      )}
    </>
  );
};
