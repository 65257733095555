import { cx } from '@emotion/css';
// TODO: Move this helper function someplace common (when we have a common contentful-UI package).
import { PlainRichText } from '@snapchat/mw-global-components/src/components/Contentful/PlainRichText';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import { ButtonType, dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { Anchor } from '../../../../components/Anchor';
import type { ButtonData } from '../../../../components/Button';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { UserAction } from '../../../../types/events';
import {
  buttonTypePrimaryCss,
  buttonTypeSecondaryCss,
  cheeriosButtonCss,
} from './CheeriosButton.style';

/**
 * Fork of the button element that correctly passes pass-through properties like style and
 * classname.
 */
export const CheeriosButton: FC<ButtonData & BaseComponentProps> = props => {
  const consumerContext = useContext(ConsumerContext);
  const { url, title, className, style, dataset, buttonType } = props;
  const { logEvent } = consumerContext;

  const onClick = (): void => {
    logEvent?.({ type: UserAction.Click, component: 'Button', url });
  };

  if (!url) return <PlainRichText document={title.json} />;

  return (
    <Anchor
      href={url}
      onClick={onClick}
      className={cx(cheeriosButtonCss, className, {
        [buttonTypePrimaryCss]: buttonType === ButtonType.Primary,
        [buttonTypeSecondaryCss]: buttonType === ButtonType.Secondary,
      })}
      style={style}
      {...dataSetToAttributes(dataset)}
    >
      <PlainRichText document={title.json} />
    </Anchor>
  );
};
CheeriosButton.displayName = 'CheeriosButton';
