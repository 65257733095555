import type { GalleryDropdown } from '@snapchat/mw-contentful-schema';
import kebabCase from 'lodash-es/kebabCase';

import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { galleryTagsPrefix } from './constants';
import type { Dropdown } from './query';
import type { SearchMenuProps } from './types';

export const buildSearchMenus = (
  dropdowns: Array<Dropdown> | undefined,
  galleryPaginationEnabled: boolean = false
): SearchMenuProps => {
  if (!dropdowns) {
    return undefined;
  }

  return dropdowns.map(({ matchAllOptionsTitle, dropdownItemsCollection, title, sys }) => {
    const { contentfulDescriptionDataset } = getContentfulInspectorProps<GalleryDropdown>({
      entryId: sys.id,
      // Direct users to the entry rather than a specific field, the text is different depending on the dropdown option
      fieldIds: ['contentfulDescription'],
    });

    return {
      title,
      id: galleryPaginationEnabled
        ? `${galleryTagsPrefix}${kebabCase(title)}`
        : `${kebabCase(title)}`,
      allItemTitle: matchAllOptionsTitle,
      items: dropdownItemsCollection.items
        .map(({ title, contentfulMetadata }) => {
          const tags = contentfulMetadata.tags;
          const itemId = tags.map(t => t.id).join(',');
          return { title, id: itemId };
        })
        .sort((a, b) => a.title.localeCompare(b.title)),
      dataset: contentfulDescriptionDataset,
    };
  });
};
