import type { DocumentNode } from 'graphql';

import { queries as accordionQueries } from '../../components/Accordion';
import { query as animatedAccordionQuery } from '../../components/AnimatedAccordion/query';
import { breakQuery } from '../../components/Break';
import { carouselV3Query } from '../../components/CarouselV3';
import { queries as contentQueries } from '../../components/Content';
import { queries as formQueries } from '../../components/Form';
import { lazyGalleryQuery } from '../../components/Gallery';
import { blockHeroQuery } from '../../components/Hero/query';
import { latestPostBlockQuery } from '../../components/LatestPostBlock';
import { query as mosaicQuery } from '../../components/Mosaic';
import { query as mvbQuery } from '../../components/MultiVideoBlock/query';
import { subNavigationQuery } from '../../components/SubNavigation/query';
import { queries as tabQueries } from '../../components/Tabs';
import { queries as barQueries } from '../../components/visualizations/BarChart';
import { chartToggleQuery } from '../../components/visualizations/ChartToggle';
import { queries as geoQueries } from '../../components/visualizations/GeoMap';
import { queries as lineQueries } from '../../components/visualizations/LineChart';
import { queries as vizQueries } from '../../components/visualizations/MultiVisualization';
import { queries as tableQueries } from '../../components/visualizations/Table';
import { latestGalleryCardHeroQuery } from '../../sites/newsroom/components/LatestGalleryCardHero';

/**
 * Contentful queries that can be selected by the content type (__typename) with a variable "id"
 * being the sys id.
 */
export const contentfulTypeQueries: Record<string, DocumentNode | undefined> = {
  LatestPosts: latestPostBlockQuery,
  MultiVideoBlock: mvbQuery,
  Content: contentQueries.all,
  CarouselV3: carouselV3Query,
  Gallery: lazyGalleryQuery,
  Tabs: tabQueries.all,
  Mosaic: mosaicQuery.all,
  Form: formQueries.all,
  ChartToggle: chartToggleQuery,
  BarChart: barQueries.all,
  LineChart: lineQueries.all,
  GeoVisualization: geoQueries.all,
  VisualizationSelector: vizQueries.all,
  TableVisualization: tableQueries.all,
  Accordion: accordionQueries.all,
  BlockHero: blockHeroQuery,
  Break: breakQuery,
  SubNavigation: subNavigationQuery,
  LatestGalleryCardHero: latestGalleryCardHeroQuery,
  AnimatedAccordion: animatedAccordionQuery,
};
