import { cx } from '@emotion/css';
import { useBrowserLayoutEffect } from '@snapchat/mw-common/client';
import { type FC, type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { MediaMode } from '../../constants';
import { useMediaMode } from '../../hooks';
import type { BaseComponentProps } from '../../types';
import { useWindowSize } from '../../utils';
import {
  arrowButtonContainerCss,
  itemLinkActiveCss,
  itemLinkCss,
  leftGradientCss,
  rightGradientCss,
  tabItemCenterContainterCss,
  tabItemContainterCss,
  tabItemStartContainterCss,
  tabsContainerCss,
} from './styles';
import { TabArrows } from './TabArrows';
import type { TabsNavigationItemProps } from './types';
import { TabDirection } from './types';

export interface TabItemsProps extends BaseComponentProps {
  onLinkClick?: (event: React.MouseEvent) => void;
  items: TabsNavigationItemProps[];
  selectTab: (index: number) => void;
  selectedTab: number;
}

/**
 * Returns the entire tab component.
 *
 * @param items - Contains text, content, and maxColumns
 * @param className - Attribute for selecting CSS class
 * @param selectTab - UseState hook for selecting the tab
 * @param selectedTab - UseState hook for accessing selected tab
 */
export const TabItems: FC<PropsWithChildren<TabItemsProps>> = ({
  items,
  className,
  selectTab,
  selectedTab,
}) => {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const arrowContainerRef = useRef<HTMLDivElement | null>(null);

  const [isRTL, setIsRTL] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isArrowVisible, setArrowVisibility] = useState(false);
  const { width: windowWidth } = useWindowSize();

  const scroll = useCallback(
    (scrollNum: number) => {
      if (containerRef.current) {
        const newScrollPosition = scrollPosition + scrollNum;
        containerRef.current.scrollLeft = newScrollPosition;
        setScrollPosition(newScrollPosition);
      }
    },
    [scrollPosition]
  );

  let parentTabWidth = 0;
  let totalTabWidth = 0;

  if (arrowContainerRef.current && containerRef.current?.parentElement) {
    parentTabWidth =
      containerRef.current.parentElement.scrollWidth - arrowContainerRef.current.scrollWidth;
    totalTabWidth = containerRef.current.scrollWidth;
  }

  const onScroll = useCallback(
    (direction: TabDirection) => {
      if (isRTL) {
        if (direction === 'Left' && -scrollPosition + parentTabWidth < totalTabWidth) {
          scroll(Math.min(-parentTabWidth, -scrollPosition));
        }

        if (direction === 'Right' && scrollPosition < 0) {
          scroll(Math.max(parentTabWidth, scrollPosition));
        }
      } else {
        if (direction === 'Left' && scrollPosition > 0) {
          scroll(Math.max(-parentTabWidth, -scrollPosition));
        }

        if (direction === 'Right' && scrollPosition + parentTabWidth < totalTabWidth) {
          scroll(Math.min(parentTabWidth, totalTabWidth - (scrollPosition + parentTabWidth)));
        }
      }
    },
    [isRTL, parentTabWidth, scroll, scrollPosition, totalTabWidth]
  );

  useBrowserLayoutEffect(() => {
    let totalParentWidth = 0,
      totalTabWidth = 0,
      arrowWidth = 0;
    setIsRTL(!!containerRef?.current?.closest('[dir="rtl"]'));

    if (containerRef.current?.parentElement) {
      totalParentWidth = containerRef.current.parentElement.scrollWidth;
      totalTabWidth = containerRef.current.scrollWidth;
    }

    if (arrowContainerRef.current) {
      arrowWidth = arrowContainerRef.current.scrollWidth;
    }
    const parentTabWidth = totalParentWidth - arrowWidth;

    if (totalTabWidth > parentTabWidth) {
      setArrowVisibility(true);
    } else {
      setArrowVisibility(false);
    }
  }, [windowWidth, items]);

  const mode = useMediaMode();
  const isMobile = mode === MediaMode.Mobile;

  const [swipePosition, setSwipePosition] = useState(0);

  useEffect(() => {
    const element = containerRef?.current;
    if (!element) return;

    const listener = () => {
      setSwipePosition(containerRef?.current?.scrollLeft ?? 0);
    };

    element.addEventListener('touchmove', listener, { passive: false });

    return () => {
      element.removeEventListener('touchmove', listener);
    };
  }, [containerRef]);

  let isDisabledLeft = false,
    isDisabledRight = false;

  if (isRTL) {
    isDisabledLeft = scrollPosition <= -totalTabWidth + parentTabWidth;
    isDisabledRight = scrollPosition === 0;
  } else {
    isDisabledLeft = scrollPosition === 0;
    isDisabledRight = scrollPosition >= totalTabWidth - parentTabWidth;
  }

  let isGradientLeft = false,
    isGradientRight = false;

  if (isRTL) {
    if (isMobile) {
      isGradientLeft = Math.ceil(-swipePosition) < totalTabWidth - parentTabWidth;
      isGradientRight = swipePosition < 0;
    } else {
      isGradientLeft = -scrollPosition <= totalTabWidth - parentTabWidth;
      isGradientRight = scrollPosition < 0;
    }
  } else {
    if (isMobile) {
      isGradientLeft = swipePosition > 0;
      isGradientRight = swipePosition < totalTabWidth - parentTabWidth;
    } else {
      isGradientLeft = scrollPosition > 0;
      isGradientRight = scrollPosition < totalTabWidth - parentTabWidth;
    }
  }

  return (
    <nav className={cx('sdsm-tabs-item', tabsContainerCss)}>
      {isGradientLeft && <div className={leftGradientCss}></div>}
      <ul
        ref={containerRef}
        className={cx(tabItemContainterCss, {
          [tabItemStartContainterCss]: isArrowVisible,
          [tabItemCenterContainterCss]: !isArrowVisible,
        })}
        role="tablist"
      >
        {items?.map((item, index) => {
          const isSelected = selectedTab === index;
          return (
            <li
              aria-current={isSelected}
              id={`tab-${index}`}
              key={`tabItem-${index}`}
              className={cx(
                itemLinkCss,
                {
                  [itemLinkActiveCss]: isSelected,
                },
                className
              )}
              onClick={() => selectTab(index)}
              role="tab"
            >
              {item.text}
            </li>
          );
        })}
      </ul>
      {isGradientRight && <div className={rightGradientCss}></div>}
      {isArrowVisible && (
        <div ref={arrowContainerRef} className={arrowButtonContainerCss}>
          <TabArrows
            direction={TabDirection.Left}
            onClick={() => {
              onScroll(TabDirection.Left);
            }}
            isDisabled={isDisabledLeft}
          />
          <TabArrows
            direction={TabDirection.Right}
            onClick={() => {
              onScroll(TabDirection.Right);
            }}
            isDisabled={isDisabledRight}
          />
        </div>
      )}
    </nav>
  );
};
