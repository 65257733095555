import throttle from 'lodash/throttle';
import { useCallback, useContext, useEffect, useState } from 'react';

import { BrowserFeaturesContext } from '../BrowserFeatures';
import { isBrowser } from './environment';

export interface ViewportSize {
  width?: number;
  height?: number;
}

class ViewportSizeEmitter extends EventTarget {
  public width: number | undefined;
  public height: number | undefined;

  constructor() {
    super();
    this.width = undefined;
    this.height = undefined;
  }

  public dispatchResizeEvent(): void {
    viewportSizeEventTarget.dispatchEvent(new Event('resize'));
  }

  public setViewportSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  public getViewportSize(): ViewportSize {
    return {
      width: this.width,
      height: this.height,
    };
  }
}

// Event target to dispatch resize events
const viewportSizeEventTarget = new ViewportSizeEmitter();

if (isBrowser()) {
  // Set initial size
  viewportSizeEventTarget.setViewportSize(window.innerWidth, window.innerHeight);

  // Add window resize listener to update sizes (do we want to throttle this?)
  window.addEventListener('resize', () => {
    viewportSizeEventTarget.setViewportSize(window.innerWidth, window.innerHeight);
    viewportSizeEventTarget.dispatchResizeEvent();
  });
}

// Hook for determining viewport size.
// Initially based on https://stackoverflow.com/questions/63406435/how-to-detect-window-size-in-next-js-ssr-using-react-hook
export function useWindowSize(throttleWindow = 1e3): ViewportSize {
  const isClient = isBrowser();
  const { getCachedHighEntropyHints } = useContext(BrowserFeaturesContext);

  const getSize = useCallback(() => {
    // if on server, try to use client hint to get values
    if (!isClient) {
      return {
        width: getCachedHighEntropyHints()?.viewportWidth,
        height: getCachedHighEntropyHints()?.viewportHeight,
      };
    }

    // else just get from singleton object
    return viewportSizeEventTarget.getViewportSize();
  }, [getCachedHighEntropyHints, isClient]);

  const [windowSize, setWindowSize] = useState<ViewportSize>(getSize);

  const handleResize = useCallback(() => {
    setWindowSize(getSize());
  }, [getSize, setWindowSize]);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const eventListener = throttle(handleResize, throttleWindow, {
      leading: false,
      trailing: true,
    });

    viewportSizeEventTarget.addEventListener('resize', eventListener);
    return () => viewportSizeEventTarget.removeEventListener('resize', eventListener);
  }, [handleResize, isClient, throttleWindow]);

  return windowSize;
}
