import { Black, Gray, Plain, Red } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const formSpacingVars = {
  '--form-grid-gap': m('--spacing-xs'),
} as const;

export const inputVars = {
  '--form-input-placeholder-color': Gray.V300,
  '--form-input-fg-color': Plain.BLACK,
  '--form-input-error-color': Red.V200,
  '--form-input-bg-color': Plain.WHITE,
  '--form-input-border-color': Gray.V150,
  '--form-input-active-border-color': Gray.V150,
  '--form-input-border-width': '1px',
  '--form-input-border-radius': m('--spacing-s'),
  '--form-input-desktop-font-size': m('--h6-desktop-font-size'),
  '--form-input-desktop-font-line-height': m('--h6-desktop-font-line-height'),
  '--form-input-desktop-font-weight': m('--h6-desktop-font-weight'),
  '--form-input-mobile-font-size': m('--h6-mobile-font-size'),
  '--form-input-mobile-font-line-height': m('--h6-mobile-font-line-height'),
  '--form-input-mobile-font-weight': m('--h6-mobile-font-weight'),
  '--form-input-box-shadow': m('--box-shadow-m'),
  '--form-input-active-box-shadow': m('--box-shadow-m'),
  '--form-input-padding': `0 ${m('--spacing-l')}`,
  '--form-input-mobile-font-stretch': m('--h6-mobile-font-stretch'),
  '--form-input-desktop-font-stretch': m('--h6-desktop-font-stretch'),
} as const;

export const formVars: MotifComponentProps<MotifComponent.FORM> = {
  ...formSpacingVars,
  ...inputVars,
} as const;

export const contrastBlackFormVars: MotifComponentProps<MotifComponent.FORM> = {
  ...formVars,
  '--form-input-placeholder-color': Gray.V250,
  '--form-input-fg-color': Plain.WHITE,
  '--form-input-error-color': Red.V50,
  '--form-input-bg-color': Black.V125,
  '--form-input-border-color': Black.V100,
} as const;
