import { css } from '@emotion/css';

import { Gray, mobileMediaQuery, nonMobileMediaQuery, Red } from '../../constants';
import { p2Css } from '../../motif';
import { m } from '../../motif/m';

export const formCss: string = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  max-width: 650px;
  margin: auto;
  /* extra margin for button shadow */
  margin-bottom: ${m('--spacing-m')};
`;

export const formRowCss: string = css`
  display: flex;
  align-items: flex-start;
  column-gap: ${m('--form-grid-gap')};
  width: 100%;

  /* Add extra top spacing if there is a label */
  &:not(:first-child) label {
    margin-top: ${m('--spacing-m')};
  }

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const inputLabelCss = css`
  margin-bottom: ${m('--spacing-xs')};
`;

export const inputHelpTextCss = css`
  ${p2Css}
  color: ${m('--form-input-placeholder-color')};
  margin-top: ${m('--spacing-xs')};
`;

export const errorMessageCss = css`
  ${inputHelpTextCss}

  color: ${m('--form-input-error-color')};
`;

export const errorDebugSectionCss = css`
  width: 100%;
  text-align: center;
  margin-top: ${m('--spacing-m')};
`;

export const errorCodeCss = css`
  display: block;
  text-align: start;
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  border-radius: ${m('--spacing-m')};
  background-color: ${m('--form-input-bg-color')};
  box-shadow: ${m('--form-input-box-shadow')};
  color: ${m('--form-input-error-color')};
  margin-top: ${m('--spacing-s')};
  padding: ${m('--spacing-m')};
  word-wrap: break-word;
`;

export const submitButtonCss = css`
  justify-content: center;

  margin-top: ${m('--spacing-xl')};
  margin-bottom: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    width: 100%;
    max-width: 350px;
    margin-top: ${m('--spacing-m')};
    margin-bottom: ${m('--spacing-m')};
  }
`;

export const inputContainerCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${m('--spacing-m')};

  *[dir='rtl'] & > select {
    background-position: left ${m('--spacing-l')} top 50%;
  }
`;

export const inputErrorCss = css`
  border: 1px solid ${Red.V50};
`;

const readonlyCss = css`
  cursor: not-allowed;
  opacity: 0.8;
`;

export const inputCss: string = css`
  background-color: ${m('--form-input-bg-color')};
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  border-radius: ${m('--form-input-border-radius')};
  box-shadow: ${m('--form-input-box-shadow')};
  box-sizing: border-box;
  color: ${m('--form-input-fg-color')};
  padding: ${m('--form-input-padding')};
  height: 52px;
  width: 100%;

  &[readonly] {
    ${readonlyCss}
  }

  &:active {
    border-color: ${m('--form-input-active-border-color')};
    box-shadow: ${m('--form-input-active-box-shadow')};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--form-input-desktop-font-size')};
    line-height: ${m('--form-input-desktop-font-line-height')};
    font-weight: ${m('--form-input-desktop-font-weight')};
    font-stretch: ${m('--form-input-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--form-input-mobile-font-size')};
    line-height: ${m('--form-input-mobile-font-line-height')};
    font-weight: ${m('--form-input-mobile-font-weight')};
    font-stretch: ${m('--form-input-mobile-font-stretch')};
  }
`;

export const checkboxCss: string = css`
  border: 1px solid ${Gray.V150};
  border-radius: ${m('--spacing-s')};
  box-shadow: ${m('--box-shadow-m')};
  box-sizing: border-box;
  font-size: 20px;
  padding: 0 ${m('--spacing-xs')};
  margin-right: 5px;
  height: 21px;

  &[readonly] {
    ${readonlyCss}
  }

  &[required]:not(:checked)::before {
    content: '*';
    color: ${Red.V50};
    font-size: 20px;
    position: relative;
    left: -12px;
  }
`;

export const checkboxWrapperCss: string = css`
  display: flex;

  label {
    /* Override label margin-top */
    margin: 0 0 0 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      &,
      &:hover,
      &:visited {
        /* TODO: See if this should be hyperlink color or its own */
        color: ${m('--foreground-color')};
      }
    }
  }
`;

export const textAreaCss = css`
  ${inputCss}
  font-family: inherit;
  height: 133px;
  padding: 20px ${m('--spacing-l')};
  resize: vertical;

  &[readonly] {
    ${readonlyCss}
  }
`;

export const selectCss: string = css`
  ${inputCss}
  appearance: none;

  &:-ms-expand {
    display: none;
  }
`;

export const selectWrapperCss = css`
  position: relative;

  i {
    margin-left: 10px;
    width: 10px;
    cursor: pointer;

    svg {
      fill: ${Gray.V300};
    }
  }
`;

// needed to enforce same placeholder color for dropdown input
export const placeholderCss = css`
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input:-moz-placeholder,
  textarea:-moz-placeholder,
  input::-moz-placeholder,
  textarea::-moz-placeholder,
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder,
  input::placeholder,
  textarea::placeholder,
  ::placeholder {
    color: ${m('--form-input-placeholder-color')};
  }

  /* TODO: find a way that doesnt need it to be required on the field */
  select:invalid,
  select option[value=''] {
    color: ${m('--form-input-placeholder-color')};
  }
`;

export const arrowCss = css`
  pointer-events: none;
  position: absolute;
  right: ${m('--spacing-l')};
  top: calc(50% - 5px);
  width: 20px;
  height: 20px;
  margin: -4px;
  fill: ${m('--form-input-fg-color')};

  html[dir='rtl'] & {
    right: unset;
    left: ${m('--spacing-l')};
  }
`;

export const multiSelectDropdownCss = css`
  ${inputCss}
  height: auto;
  padding: 0;

  /** prevents issue with SSR rendering without this style */
  .displayNone {
    display: none;
  }

  .searchWrapper {
    padding: 0 ${m('--spacing-xxl')} 0 ${m('--spacing-xs')};
    border: 0;
    min-height: 50px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    input {
      color: inherit;
      font-size: inherit;
      /* input is disabled */
      cursor: default;
      min-width: 0px;
      padding-left: ${m('--spacing-m')};
      flex: 1 1 0%;
    }

    .chip {
      background: ${m('--form-input-border-color')};
      color: ${m('--form-input-fg-color')};
      font-size: inherit;
      padding: ${m('--spacing-xs')};
      margin: 5px;
      cursor: default;

      svg {
        fill: ${m('--form-input-fg-color')};
      }
    }

    .icon_down_dir {
      right: -60px;
    }
  }

  .optionListContainer {
    background: ${m('--form-input-bg-color')};
    list-style-type: none;
    padding-left: ${m('--spacing-xs')};
    padding-right: ${m('--spacing-xs')};
    z-index: 51;

    .optionContainer {
      border: none;

      &::-webkit-scrollbar {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    .option {
      display: flex;
      align-items: center;
      margin-top: ${m('--spacing-xs')};
      margin-bottom: ${m('--spacing-xs')};
      padding-left: ${m('--spacing-m')};
      padding-right: ${m('--spacing-m')};
      border-radius: 5px;
      color: ${m('--form-input-fg-color')};

      &.selected,
      &.highlight,
      &:hover {
        background: ${m('--form-input-border-color')};
        color: ${m('--form-input-fg-color')};
      }

      input[type='checkbox'] {
        height: 17px;
        width: 17px;

        &:checked {
          accent-color: ${m('--form-input-fg-color')};
        }
      }
    }
  }
`;

export const multiSelectIconCss = css`
  margin: -7px;
`;
