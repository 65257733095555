/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/css';
import type { FC } from 'react';
import { cloneElement } from 'react';

import { Icon } from '../../Icon';
import type { StateCallBack } from './types';

const iconCss = css`
  width: 25px;
  height: 25px;
`;

interface LeftArrowProps {
  customLeftArrow?: React.ReactElement<any> | null;
  getState: () => StateCallBack;
  previous: () => void;
  disabled?: boolean;
}
interface RightArrowProps {
  customRightArrow?: React.ReactElement<any> | null;
  getState: () => StateCallBack;
  next: () => void;
  disabled?: boolean;
}

const LeftArrow: FC<LeftArrowProps> = ({
  customLeftArrow,
  getState,
  previous,
  disabled,
}): React.ReactElement<any> => {
  if (customLeftArrow) {
    return cloneElement(customLeftArrow, {
      onClick: () => previous(),
      carouselState: getState(),
      disabled,
    });
  }

  return (
    <button
      aria-label="Go to previous slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={() => previous()}
      type="button"
      disabled={disabled}
    >
      <Icon name="chevron-left" fill="#FFFFFF" className={iconCss} />
    </button>
  );
};

const RightArrow: FC<RightArrowProps> = ({
  customRightArrow,
  getState,
  next,
  disabled,
}): React.ReactElement<any> => {
  if (customRightArrow) {
    return cloneElement(customRightArrow, {
      onClick: () => next(),
      carouselState: getState(),
      disabled,
    });
  }

  return (
    <button
      aria-label="Go to next slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={() => next()}
      type="button"
      disabled={disabled}
    >
      <Icon name="chevron-right" fill="#FFFFFF" className={iconCss} />
    </button>
  );
};

export { LeftArrow, RightArrow };
