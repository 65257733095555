import type { LoggedEvent, MultipleDataLayerEvents } from '@snapchat/logging';
import { LoggingEventType } from '@snapchat/logging';
import type { GoogleTagManagerClientEventListenerProps } from '@snapchat/logging-browser';
import { GoogleTagManagerClientEventListener } from '@snapchat/logging-browser';

import { UrlParameter } from '../../../constants/urlParameters';
import type {
  GoogleEvent,
  GoogleNonInteractionEvent,
  GoogleTimingEvent,
  GoogleUserEvent,
  GoogleValueEvent,
} from '../../../types/gtm';
import { SubscribedEventType } from '../eventListenerTypes';
import type { LoggingContext, LoggingCustomEvents, LoggingPermissions } from '../loggingTypes';

/**
 * Name of the event that we can pass to the GTM to trigger the universal analytics event record (GA
 * Event).
 */
export const googleAnalyticsEventName = 'analyticsEvent';

const googleAnalyticsValueEventName = 'analyticsValueEvent';

const googleAnalyticsTimingEventName = 'analyticsTimingEvent';

type Props = Pick<GoogleTagManagerClientEventListenerProps, 'gtmId' | 'nonce' | 'useAsync'>;

/** Custom event listener for GTM events. */
export class GoogleEventListener extends GoogleTagManagerClientEventListener<
  LoggingContext,
  LoggingCustomEvents,
  LoggingPermissions
> {
  protected isOutsideCalifornia: boolean = false;

  public constructor(props: Props) {
    super({ ...props, eventFormatter: GoogleEventListener.eventFormat });
  }

  /**
   * Custom permission handler that only allowes GTM logging if outside of California.
   *
   * @override
   */
  protected hasAllPermissions: () => boolean = () => {
    // Absolute override. When running in GTM debug mode, need to ensure that
    // this listener is turned on.
    const isGtmDebug = new URL(window.location.href).searchParams.has(
      UrlParameter.GOOGLE_TAG_MANAGER_DEBUG
    );

    if (isGtmDebug) {
      return true;
    }

    // TODO: Figure out why we can't call super.hasAllPermissions() here.
    let superHasAllPermissions = true;

    for (const permission of this.baseProps.requiredPermissions) {
      if (!this.permissions.has(permission)) {
        superHasAllPermissions = false;
        break;
      }
    }

    if (!superHasAllPermissions) return false;

    return this.isOutsideCalifornia;
  };

  /**
   * Checks whether running outside of California where we are allowed to log.
   *
   * TODO: Wait for legal's response on whether we need to continue doing this. If they reaffirm,
   * then we should move this into some sort of a helper function. See
   * https://groups.google.com/a/snapchat.com/g/webeng/c/-a43qV45er4 And the current decision here:
   * https://wiki.sc-corp.net/pages/viewpage.action?pageId=255171537
   */
  public checkRegion = (): void => {
    if (this.isOutsideCalifornia) return;

    fetch('https://web-platform.snap.com/cookies/user_location')
      .then(response => response.json())
      .then((data: { country: string; region: string }) => {
        this.isOutsideCalifornia = data.country !== 'US' || data.region !== 'ca';

        // Hack to trigger logging init and flush any waiting logs.
        if (this.isOutsideCalifornia && this.permissions.has('logging')) {
          void this.allow('logging');
        }
      })
      .catch(console.error);
  };

  /**
   * Event Formatter for the GTM events. Note that this isn't generic and only works because MWP's
   * GTM container accepts these fields.
   */
  static eventFormat(
    event: LoggedEvent<LoggingCustomEvents>,
    _context: Partial<LoggingContext>
  ): GoogleEvent | MultipleDataLayerEvents<GoogleEvent> | null {
    switch (event.type) {
      case LoggingEventType.USER_ACTION: {
        return {
          event: googleAnalyticsEventName,
          eventAction: event.action,
          eventLabel: event.label,
          eventCategory: event.component,
          eventNonInt: false,
        } as GoogleUserEvent;
      }

      case LoggingEventType.INFO: {
        return {
          event: googleAnalyticsEventName,
          eventAction: event.action,
          eventLabel: event.label,
          eventCategory: event.component,
          eventNonInt: true,
        } as GoogleNonInteractionEvent;
      }

      case LoggingEventType.VALUE: {
        return {
          event: googleAnalyticsValueEventName,
          eventCategory: event.component,
          eventVariable: event.variable,
          eventLabel: event.label,
          eventValue: event.value,
          eventNonInt: true,
        } as GoogleValueEvent;
      }

      case LoggingEventType.TIMING: {
        return {
          event: googleAnalyticsTimingEventName,
          eventCategory: event.component,
          eventVariable: event.variable,
          eventLabel: event.label,
          eventValue: event.valueMs,
          eventNonInt: true,
        } as GoogleTimingEvent;
      }

      case LoggingEventType.CUSTOM: {
        switch (event.subscribedEventType) {
          case SubscribedEventType.PHONE_NUMBER_EVENT: {
            return {
              ...event,
              event: event.event ?? googleAnalyticsEventName,
              eventAction: event.event ?? 'PhoneNumberInteraction',
              eventCategory: 'PhoneNumberForm',
              eventNonInt: false,
            };
          }

          case SubscribedEventType.PAGE_LOAD: {
            return {
              type: 'multiple',
              events: [
                // Fires 'Virtual Pageview' Trigger in GTM, used for Legacy GA UA integration
                // TODO: remove this once the GTM configuration no longer references this.
                {
                  event: 'virtualPageview',
                  eventNonInt: true,
                  eventAction: 'View',
                  eventCategory: 'Page',
                  virtualPageviewPath: window.location.pathname,
                },
                {
                  event: googleAnalyticsEventName,
                  eventNonInt: true,
                  eventAction: 'Load',
                  eventCategory: 'Page',
                  eventLabel: window.location.pathname,
                },
              ],
            };
          }

          /**
           * Ecommerce specific event. One event needs to be pushed with {ecommerce: null} The next
           * event will be sent with the usual event fields (eventLabel, eventCategory, etc.)
           *
           * - An ecommerce object Reference:
           *   https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce
           */
          case SubscribedEventType.ECOMMERCE: {
            return {
              type: 'multiple',
              // For now, we log these using GA UA ecommerce format (does not utilize GA4 ecommerce integrations)
              // TODO: reimplement using GA 4 ecommerce format: https://jira.sc-corp.net/browse/ENTWEB-8181
              events: [{ ecommerce: null }, { ...event, event: 'analyticsEvent' }],
            };
          }

          case SubscribedEventType.EXPERIMENT_IMPRESSION: {
            if (!event.experimentId && !event.variantId) {
              return null;
            }

            return {
              event: 'experiment_impression',
              experiment_id: event.experimentId,
              variant_id: `${event.experimentId}.${event.variantId}`,
            };
          }
        }

        // Default custom events is to skip them.
        return null;
      }
    }

    // Default for other events is to skip them.
    return null;
  }

  /** @override */
  protected flushInternal: () => Promise<unknown> = () => {
    return Promise.resolve();
  };
}
