import { getImageUrl } from '@snapchat/mw-common/client';
import type { HeaderProps } from '@snapchat/snap-design-system-marketing';
import { Header } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { logger } from '../../helpers/logging';
import type { MetasProps } from './types';

/**
 * Parses meta tags into name and string attributes based on the value in the string.
 *
 * Note that this supports a legacy format like <name>=<content> as well as parsing the meta tag as
 * a whole.
 *
 * Values are escaped to avoid script injection.
 */
export function parseCustomMeta(customMeta: string): { name: string; content: string } {
  customMeta = customMeta.trim();

  // Support values like <meta name="<name>" content="<content>" />
  if (customMeta.startsWith('<meta')) {
    const name = /name="([^"]+)"/.exec(customMeta)?.[1] ?? 'unknown';
    const content = /content="([^"]+)"/.exec(customMeta)?.[1] ?? 'unknown';
    return { name: encodeURIComponent(name), content: encodeURIComponent(content) };
  }

  // Support values like <name>=<content>
  if (!/['"><]/.test(customMeta) && customMeta.includes('=')) {
    const [name, content] = customMeta.split('=');
    return {
      name: encodeURIComponent((name as string).trim()),
      content: encodeURIComponent((content as string).trim()),
    };
  }

  logger.logWarning({
    component: 'Meta',
    action: 'parse-custom-meta',
    message: `Unable to parse custom metadata: "${encodeURIComponent(customMeta)}"`,
  });

  // In all other cases throw a warning and diplay bad metadata.
  return {
    name: 'unparseable-meta',
    content: encodeURIComponent(customMeta),
  };
}

export const Metas: FC<MetasProps> = props => {
  const { customMetas } = props;

  const parsedCustomMetas = customMetas?.map(parseCustomMeta) ?? [];

  const imageUrl =
    props.ogImage &&
    getImageUrl({
      imageUrl: props.ogImage.url,
      // 512px tall should be more than large enough for image previews
      settings: { height: 512 },
    });

  const headerProps: HeaderProps = {
    description: props.description,
    ogImage: imageUrl,
    ogVideo: props.ogVideo && props.ogVideo.url,
    noIndex: !!props.noIndex,
    noFollow: !!props.noFollow,
    customMetas: parsedCustomMetas,
  };

  return <Header {...headerProps} />;
};

Metas.displayName = 'Metas';
