import { Footnote as FootnoteSDS } from '@snapchat/snap-design-system-marketing';
import isNull from 'lodash-es/isNull';
import type { FC } from 'react';

import { renderMultiLineText } from '../../utils/renderText/textUtils';
import type { FootnoteProps } from './types';

// Footnote translator connects to a legacy FootNotes components
// this is why sometimes it's being referenced as Footnote (SDS) and FootNotes (Contentful)
export const Footnote: FC<FootnoteProps> = props => {
  const { text, number } = props;
  return isNull(text) ? null : (
    <FootnoteSDS index={String(number)}>{renderMultiLineText(text)}</FootnoteSDS>
  );
};

Footnote.displayName = 'Footnote';
