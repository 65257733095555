import { css } from '@emotion/css';

import {
  mobileMediaQuery,
  noHorizontalScrollbarsCss,
  nonMobileMediaQuery,
} from '../../../constants';
import { ZIndex } from '../../../constants/zIndex';
import { m } from '../../../motif/m';

// Buttons
export const buttonsWrapperClassName = 'toggleButtonsWrapper';
export const dropdownWrapperClassName = 'dropdownWrapper';

export const buttonsWrapperCss = css`
  width: 100%;
`;

const buttonsBaseCss = css`
  display: flex;
  justify-content: center;
  padding-top: ${m('--spacing-xxxs')};
  flex-wrap: wrap;

  ${mobileMediaQuery} {
    justify-content: start;
    overflow-x: auto;
    flex-wrap: nowrap;
    ${noHorizontalScrollbarsCss}
  }

  *[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`;

const buttonsSizeCss = css`
  margin-inline: 0;

  ${mobileMediaQuery} {
    &::before,
    &::after {
      content: '';
      flex: 0 0 ${m('--spacing-xl')};
    }
    margin-inline: calc(${m('--spacing-xl')} * -1);
  }
`;

export const buttonsCss = css`
  ${buttonsBaseCss}
  ${buttonsSizeCss}
`;

// Panels
export const panelsCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  & > div {
    margin: auto;

    & > div:not(:last-of-type):not(.${buttonsWrapperClassName}) {
      margin-bottom: calc(${m('--spacing-xxl')} * 2);
    }
  }
`;

// Single Button
export const buttonActiveClassName = 'toggleButton-active';

const buttonBaseCss = css`
  border-radius: 64px;
  cursor: pointer;
  font-weight: ${m('--action-desktop-font-weight')};
  line-height: ${m('--action-desktop-font-line-height')};
  text-decoration: none;
  transition: transform 150ms ease-in-out;
  white-space: nowrap;
  z-index: ${ZIndex.BUTTON};

  @media (hover: hover) {
    &:hover:not(:disabled) {
      box-shadow: ${m('--box-shadow-xl')};
      transform: translateY(-2px);
    }
  }

  &:active:not(:disabled) {
    box-shadow: ${m('--box-shadow-m')};
    transform: translateY(-1px);
  }

  ${mobileMediaQuery} {
    font-weight: ${m('--action-mobile-font-weight')};
    line-height: ${m('--action-mobile-font-line-height')};
  }
`;

const buttonSizeCss = css`
  margin-inline: ${m('--spacing-xs')};
  margin-bottom: ${m('--spacing-xl')};
  font-size: ${m('--action-desktop-font-size')};
  padding: ${m('--spacing-s')} ${m('--spacing-xl')};
  border: 1px solid ${m('--chart-toggle-buttons-border-color')};
  background-color: ${m('--chart-toggle-buttons-bg-color')};
  color: ${m('--chart-toggle-buttons-color')};

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
  }

  &.${buttonActiveClassName} {
    border: 1px solid ${m('--chart-toggle-buttons-active-border-color')};
    background-color: ${m('--chart-toggle-buttons-active-bg-color')};
    color: ${m('--chart-toggle-buttons-active-color')};
  }
`;

export const buttonCss = css`
  ${buttonBaseCss}
  ${buttonSizeCss}
`;

// TODO: Simplify this... I think it's just adding some margin on the child.
export const contentContainerStyle1ColumnCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 100%;
  & > * {
    box-sizing: border-box;
    margin-left: ${m('--spacing-m')};
    margin-right: ${m('--spacing-m')};

    ${mobileMediaQuery} {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    width: 100%;
  }
`;

export const chartToggleWrapperCss = css`
  align-items: flex-start;
  column-gap: ${m('--spacing-m')};
  display: flex;
  min-height: 60vh;
  row-gap: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    flex-direction: column;

    & > * {
      width: 100%;
    }

    /** Style buttons in dropdown component */
    button {
      width: 100%;
    }
  }

  ${nonMobileMediaQuery} {
    flex-wrap: wrap;

    & > *:not(.${dropdownWrapperClassName}) {
      width: 100%;
    }
  }
`;

export const dropdownCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
  justify-content: end;
  label {
    font-size: ${m('--action-desktop-font-size')};
    font-weight: ${m('--action-desktop-font-weight')};

    ${mobileMediaQuery} {
      font-size: ${m('--action-mobile-font-size')};
      font-weight: ${m('--action-mobile-font-weight')};
    }
  }

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

export const noLabelCss = css`
  ${nonMobileMediaQuery} {
    margin-block-start: calc(24px + ${m('--spacing-xs')});
  }
`;
