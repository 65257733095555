import { useContext, useEffect, useState } from 'react';

import type { BrowserFeatures } from '../BrowserFeatures';
import {
  BrowserFeaturesContext,
  isEmptyBrowserFeatures,
  isMac,
  isWindows,
} from '../BrowserFeatures';
import { isSSR } from './environment';

// We refer to the key used to do most keyboard commands like cut, copy, and paste
// as the "super key." It's control on Windows but Command on Mac.
export const superKey = 'SUPER';

export const keysMatch = (key1: string, key2: string, features: BrowserFeatures): boolean => {
  if (key1 === superKey) {
    if (key2.startsWith('Meta') && (isMac(features) || isEmptyBrowserFeatures(features))) {
      return true;
    }

    if (key2.startsWith('Control') && (isWindows(features) || isEmptyBrowserFeatures(features))) {
      return true;
    }

    return false;
  } else {
    return key1 === key2;
  }
};

// Hook
export function useKeyPressed(code: string): boolean {
  const [isPressed, setIsPressed] = useState(false);
  const browserFeatures = useContext(BrowserFeaturesContext);

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    function handleKeyDown(event: KeyboardEvent) {
      const { code: pressed } = event;

      if (keysMatch(code, pressed, browserFeatures)) {
        setIsPressed(true);
      }
    }

    function handleKeyUp(event: KeyboardEvent) {
      const { code: released } = event;

      if (keysMatch(code, released, browserFeatures)) {
        setIsPressed(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [browserFeatures, code]);

  return isPressed;
}
