import { parseError } from '@snapchat/core';
import type { Dimensions } from '@snapchat/graphene';
import { Partition } from '@snapchat/graphene';
import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import clone from 'lodash/clone';
import head from 'lodash/head';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ContentfulContext } from '../../context';
import type { CookieModalType } from '../../generated/contentful-types';
import { CookieModalQueryDocumentType } from '../../generated/contentful-types';
import { BrowserGrapheneClient, DevelopmentDomains } from '../../helpers/logging';
import type { CategorizedCookie } from '../../utils';
import {
  acceptAllCookies,
  acceptCustomCookies,
  auditForUncategorizedCookies,
  createCookieMapping,
  essentialOnlyCookies,
  fetchShouldDisplayModal,
  fetchUserLocation,
  getAllCookies,
  getCookieDomain,
  removeCookiesForNonacceptedCategories,
  setOptInCookies,
} from '../../utils';
import { DesignAgnosticModal } from '../designAgnostic';
import type { WithOnError } from '../ErrorBoundary';
import { withErrorBoundary } from '../ErrorBoundary';
import type { CookieAcceptance, LocaleOption } from '../types';
import { BackgroundType, CategoryOptInCookie, CookieScreen, EventAction } from '../types';
import { CookieLandingScreen } from './CookieLandingScreen';
import { CookieSettingsScreen } from './CookieSettingsScreen';

interface OnCompleteEvent {
  /**
   * User's location per API endpoint. Included as a convenience for consuming applications to avoid
   * multiple calls to that endpoint if configuring GTM/GA, etc.
   */
  userLocation: string;
  /** Whether the modal shown to the user. */
  didUserInteract: boolean;
  /** Category Cookies and acceptance state for the user. */
  cookieAcceptance: CookieAcceptance;
  /** Whether cookies were changed based on global privacy control */
  isGlobalPrivacyControlApplied: boolean;
}

export interface CookieModalProps extends WithOnError {
  supportedLocales: Record<string, LocaleOption>;
  /** If not specified, defaults to the top level domain of the current location */
  cookieDomain?: string;
  /** Optional, use to specify parent element within which to render the modal */
  portalRoot?: HTMLElement;
  /** If not specified, defaults to `LightMode` */
  backgroundType?: BackgroundType;
  /** On change listener for Locale Dropdown */
  onLocaleChange?: (locale: string) => void;
  /**
   * Fires whenever the modal interaction is complete (whether it was visually shown or not). This
   * event hook is used to enable/disable features per user cookie acceptance.
   */
  onComplete?: (event: OnCompleteEvent) => void;
  /** On event listener for modal actions */
  onEvent?: (event: { component: string; label: string; action: EventAction }) => void;
  /** Fires whenever global privacy control is in place */
  onGlobalPrivacyControlSet?: () => void;
  /** Whether to force the modal to be visible (for testing) */
  forceVisible?: boolean;
  /** Whether the user has enabled Global Privacy Control */
  globalPrivacyControl?: boolean;
}

const displayName = 'CookieModal';

const CookieModal: FC<CookieModalProps> = ({
  supportedLocales,
  cookieDomain: inputCookieDomain,
  portalRoot,
  backgroundType = BackgroundType.LightMode,
  onLocaleChange,
  onComplete,
  onEvent,
  onGlobalPrivacyControlSet,
  globalPrivacyControl,
  forceVisible,
}) => {
  // ==============================================================================================
  // Fetch content from Contentful
  // ==============================================================================================

  const context = useContext(ContentfulContext);
  const { data } = useGlobalComponentsContentfulQuery(CookieModalQueryDocumentType, context, {
    client: context.client,
  });
  const config = head(data?.cookieModalCollection.items) as CookieModalType | undefined;

  const gpcLocations = config?.gpcLocations;
  // Convert configuration into optimized data structures
  const { categorySettings, cookieCategoryMap, enableCookieDeletion } = useMemo(() => {
    if (!config?.cookieCategoriesCollection?.items?.length)
      return {
        categorySettings: undefined,
        cookieCategoryMap: undefined,
        enableCookieDeletion: false,
      };

    // Settings for generating UI, storing user selections
    const categorySettings = config.cookieCategoriesCollection.items;

    // Map of related cookies by category, used for deleting after opt out
    const cookieCategoryMap = createCookieMapping(config.cookieCategoriesCollection.items);

    // Settings for cookie deletion
    const { enableCookieDeletion } = config;

    return { categorySettings, cookieCategoryMap, enableCookieDeletion };
  }, [config]);

  // ==============================================================================================
  // Internal State
  // ==============================================================================================
  const cookieDomain = getCookieDomain(inputCookieDomain);

  const [isInModalRequiredRegion, setIsInModalRequiredRegion] = useState<boolean>();
  const [userLocation, setUserLocation] = useState('Unknown');
  const [isDisplayed, setIsDisplayed] = useState(false);

  const [categoryState, setCategoryState] = useState<CookieAcceptance>({});

  const updateCategoryState = (key: CategoryOptInCookie, value: boolean) => {
    const newState = clone(categoryState);
    newState[key] = value;
    setCategoryState(newState);
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [activeScreen, setActiveScreen] = useState(CookieScreen.LANDING);

  const [grapheneClient, setGrapheneClient] = useState<BrowserGrapheneClient>();

  // @ts-ignore navigator does have globalPrivacyControl
  const hasGlobalPrivacyControl = window?.navigator?.globalPrivacyControl || globalPrivacyControl;

  const isGlobalPrivacyControlApplied = !!(
    hasGlobalPrivacyControl &&
    cookieCategoryMap &&
    // If the user location is not in the list of locations that require GPC, then GPC is not applied.
    // Alternatively if no locations are set in contentful GPC is applied by default.
    (gpcLocations?.includes(userLocation) || gpcLocations?.length === 0 || !gpcLocations)
  );
  // ==============================================================================================
  // Helper Functions
  // ==============================================================================================

  /** Detect and log uncategorized cookies */
  const logUncategorizedCookies = useCallback(
    (cookieCategoryMap: Map<string, Set<CategorizedCookie>>) => {
      const isProductionDomain = !DevelopmentDomains.has(cookieDomain);
      if (!isProductionDomain) return; // noop for Development environments

      const uncategorizedCookies = auditForUncategorizedCookies(cookieCategoryMap);

      for (const cookieName of uncategorizedCookies) {
        const dimensions: Dimensions = {
          cookieName,
          userLocation,
        };

        grapheneClient?.logMetric('modal_uncategorized_cookie', dimensions);
      }
    },
    [grapheneClient, cookieDomain, userLocation]
  );

  /**
   * Centralized function for setting cookies and triggering callback functions. The optional
   * `existingCookies` parameter controls whether update all setting cookies or apply changes only.
   */
  const updateUserCookies = useCallback(
    (cookiesToSet: CookieAcceptance, existingCookies?: CookieAcceptance) => {
      // If mappings have not been loaded, treat as noop
      if (!cookieCategoryMap || !categorySettings) return;

      const filterToChanges = (
        cookiesToSet: CookieAcceptance,
        existingCookies: CookieAcceptance = {}
      ): CookieAcceptance => {
        return pickBy(
          cookiesToSet,
          // @ts-ignore TODO: Fix types.
          (value, key) => isUndefined(existingCookies[key]) || existingCookies[key] !== value
        );
      };

      // Apply user settings to Cookies
      const changesToApply = filterToChanges(cookiesToSet, existingCookies);

      setOptInCookies(cookieDomain, changesToApply);

      const results = removeCookiesForNonacceptedCategories(
        cookieDomain,
        cookiesToSet,
        cookieCategoryMap,
        enableCookieDeletion
      );

      // log any cookie removals that were incomplete (indicates misconfiguration of cookie attributes)
      for (const cookieName in results) {
        // removal was successful, noop
        if (results[cookieName]?.wasRemoved) continue;

        // otherwise log so we can review the configuration.  Graphene doesn't support enough dimensions
        // for us to store the full configuration, so domain and cookieName will have to do.
        const dimensions: Dimensions = {
          cookieName,
          userLocation,
        };
        grapheneClient?.logMetric('tracking_cookie_misconfiguration', dimensions);
      }

      logUncategorizedCookies(cookieCategoryMap);

      // Fire onComplete event hook
      onComplete?.({
        didUserInteract: isDisplayed,
        userLocation,
        cookieAcceptance: cookiesToSet,
        isGlobalPrivacyControlApplied,
      });

      setIsDisplayed(false);
    },
    [
      cookieDomain,
      cookieCategoryMap,
      categorySettings,
      grapheneClient,
      isDisplayed,
      onComplete,
      userLocation,
      setIsDisplayed,
      logUncategorizedCookies,
      isGlobalPrivacyControlApplied,
      enableCookieDeletion,
    ]
  );

  // ==============================================================================================
  // useEffect calls
  // ==============================================================================================

  useEffect(() => {
    const host = window.location.hostname;
    const client = new BrowserGrapheneClient(Partition.COOKIE_MODAL_COMPONENTS, host);
    setGrapheneClient(client);
  }, [setGrapheneClient]);

  // Determine whether user is in GDPR region and should display modal
  useEffect(() => {
    // Wait for grapheneClient to initialize before executing
    if (!grapheneClient) return;

    const getIsInModalRequiredRegion = async () => {
      try {
        const shouldDisplayModal = await fetchShouldDisplayModal();
        setIsInModalRequiredRegion(shouldDisplayModal);
      } catch (unknownError) {
        const error = parseError(unknownError);
        grapheneClient.logError(displayName, 'shouldDisplayModal', error);
        // Failure of security or network connection prompts the popup to show
        setIsInModalRequiredRegion(true);
      }
    };
    void getIsInModalRequiredRegion();
  }, [grapheneClient, setIsInModalRequiredRegion]);

  // Retrieve user's location
  useEffect(() => {
    // Wait for grapheneClient to initialize before executing
    if (!grapheneClient) return;

    const fetchUserRegion = async () => {
      try {
        const userLocation = await fetchUserLocation();
        setUserLocation(userLocation);
      } catch (unknownError) {
        const error = parseError(unknownError);
        grapheneClient.logError(displayName, 'userLocation', error);
      }
    };
    void fetchUserRegion();
  }, [grapheneClient, setUserLocation]);

  // Set default categoryState - ensures toggles for essential categories default to enabled.
  useEffect(() => {
    if (!categorySettings) return;

    const defaultState = essentialOnlyCookies(categorySettings);
    setCategoryState(defaultState);
  }, [categorySettings, setCategoryState]);

  // Business logic for whether to render the Cookie Modal, trigger callbacks, etc.
  // Defers execution until all async data calls are complete.
  useEffect(() => {
    // Wait for async data fetches to complete before executing
    if (isUndefined(isInModalRequiredRegion) || !cookieCategoryMap || !categorySettings) return;

    // Ensure this useEffect function fires only once
    if (isLoaded) return;
    setIsLoaded(true);

    // fetch current cookie values (outside GDPR region: default to true, inside of GDPR region: default to false)
    const retrievedCookies = getAllCookies(categorySettings);
    const hasPreviouslyAcceptedCookies = retrievedCookies[CategoryOptInCookie.Essential];
    const gpcDimensions = {
      userLocation,
      cookieDomain,
    };

    // Scenario 1: Returning user outside of GDPR, recreate missing cookies (default true), and trigger callback functions
    // Scenario 2: Returning user inside of GDPR, recreate missing cookies (default false), and trigger callback functions
    // Edge case: if user manually deleted one of the necessary cookies (e.g. Performance, Marketing, etc.), this will recreate them.
    if (hasPreviouslyAcceptedCookies) {
      const defaultCookies = !isInModalRequiredRegion
        ? acceptAllCookies(categorySettings) // Scenario 1
        : essentialOnlyCookies(categorySettings); // Scenario 2

      const cookiesToRecreate = { ...defaultCookies, ...retrievedCookies };

      if (isGlobalPrivacyControlApplied && retrievedCookies[CategoryOptInCookie.Marketing]) {
        updateUserCookies(
          { ...cookiesToRecreate, [CategoryOptInCookie.Marketing]: false },
          retrievedCookies
        );

        onGlobalPrivacyControlSet?.();
        grapheneClient?.logMetric('global_privacy_control', gpcDimensions);
      } else {
        updateUserCookies(cookiesToRecreate, retrievedCookies);
      }

      return;
    }

    if (!isInModalRequiredRegion) {
      // Scenario 3: First time user located outside GDPR region, auto-accept all cookie categories and trigger callback functions.
      const acceptAll = acceptAllCookies(categorySettings);

      if (isGlobalPrivacyControlApplied) {
        updateUserCookies({ ...acceptAll, [CategoryOptInCookie.Marketing]: false });
        onGlobalPrivacyControlSet?.();
        grapheneClient?.logMetric('global_privacy_control', gpcDimensions);
      } else {
        updateUserCookies(acceptAll);
      }
    } else {
      // Scenario 4: First time user located inside GDPR region, display modal
      setIsDisplayed(true);
    }
  }, [
    isLoaded,
    isInModalRequiredRegion,
    cookieCategoryMap,
    categorySettings,
    setIsLoaded,
    setIsDisplayed,
    updateUserCookies,
    globalPrivacyControl,
    userLocation,
    isGlobalPrivacyControlApplied,
    cookieDomain,
    grapheneClient,
    onGlobalPrivacyControlSet,
  ]);

  // ==============================================================================================
  // User Interaction handlers
  // ==============================================================================================

  const acceptAll = () => {
    // If mappings have not been loaded, treat as noop
    if (!categorySettings) return;

    const cookiesToSet = acceptAllCookies(categorySettings);
    updateUserCookies(cookiesToSet);
    logUserAction('accept_all', cookiesToSet);
  };

  const logUserAction = (label: string, cookieCategories: CookieAcceptance) => {
    // Log event to event listener prop
    onEvent?.({ component: displayName, action: EventAction.Click, label });

    // Also log event to dedicated CookieComponents Graphene Partition
    const dimensions: Dimensions = {
      preferences: `${cookieCategories[CategoryOptInCookie.Preferences] ?? false}`,
      performance: `${cookieCategories[CategoryOptInCookie.Performance] ?? false}`,
      marketing: `${cookieCategories[CategoryOptInCookie.Marketing] ?? false}`,
      userLocation,
    };
    grapheneClient?.logMetric(`${label}_clicks_modal`, dimensions);
  };

  const acceptEssential = () => {
    // If mappings have not been loaded, treat as noop
    if (!categorySettings) return;

    const cookiesToSet = essentialOnlyCookies(categorySettings);
    updateUserCookies(cookiesToSet);
    logUserAction('accept_essential', cookiesToSet);
  };

  const acceptSelected = () => {
    // If mappings have not been loaded, treat as noop
    if (!categorySettings) return;

    const cookiesToSet = acceptCustomCookies(categorySettings, categoryState);
    updateUserCookies(cookiesToSet);
    logUserAction('accept_selected', cookiesToSet);
  };

  // ==============================================================================================
  // Render
  // ==============================================================================================

  if (!config) {
    return null;
  }

  return (
    <DesignAgnosticModal
      isDisplayed={forceVisible ?? isDisplayed}
      backgroundType={backgroundType}
      portalRoot={portalRoot}
    >
      {activeScreen === CookieScreen.LANDING && (
        <CookieLandingScreen
          backgroundType={backgroundType}
          supportedLocales={supportedLocales}
          title={config.landingScreenTitle}
          content={config.content.json}
          settingsButtonText={config.settingsButtonText}
          onSettingsbuttonClick={() => setActiveScreen(CookieScreen.SETTINGS)}
          acceptAllButtonText={config.acceptAllButtonText}
          onAcceptAllButtonClick={acceptAll}
          essentialOnlyButtonText={config.essentialOnlyButtonText}
          onAcceptEssentialButtonClick={acceptEssential}
          onLocaleChange={onLocaleChange}
        />
      )}
      {activeScreen === CookieScreen.SETTINGS && (
        <CookieSettingsScreen
          backgroundType={backgroundType}
          supportedLocales={supportedLocales}
          title={config.landingScreenTitle}
          cookieCategories={config.cookieCategoriesCollection.items}
          activeToggleLabel={config.toggleEnabledText}
          inactiveToggleLabel={config.toggleDisabledText}
          categoriesState={categoryState}
          updateCategoriesState={updateCategoryState}
          acceptAllButtonText={config.acceptAllButtonText}
          essentialOnlyButtonText={config.essentialOnlyButtonText}
          acceptSelectedButtonText={config.acceptSelectedButtonText}
          onAcceptAllButtonClick={acceptAll}
          onAcceptEssentialButtonClick={acceptEssential}
          onAcceptSelectedButtonClick={acceptSelected}
          onLocaleChange={onLocaleChange}
        />
      )}
    </DesignAgnosticModal>
  );
};

// NOTE: for some reason displayName is not automatically deetected by ErrorBoundary.  Need to explicitly set it.
CookieModal.displayName = displayName;

const wrapped = withErrorBoundary(CookieModal, Partition.COOKIE_MODAL_COMPONENTS);

export { wrapped as CookieModal };
