import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
import { Children } from 'react';

import { mobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';
import type { BaseComponentProps } from '../../types';

export const footnoteBlockBackgroundColor = 'White';

const footnotesCss = css`
  background-color: ${m('--footnote-bg-color')};
  color: ${m('--footnote-fg-color')};
  margin: 0px auto;
  padding: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    padding: ${m('--spacing-m')};
  }
`;

interface FootnoteBlockProps extends PropsWithChildren<BaseComponentProps> {}

export const FootnoteBlock: FC<FootnoteBlockProps> = ({ children, className }) => {
  if (Children.count(children) === 0) {
    return null;
  }

  return (
    <footer data-test-id="sdsm-footnotes" className={cx(footnotesCss, className)}>
      {children}
    </footer>
  );
};

FootnoteBlock.displayName = 'FootnoteBlock';
