import { getDprSrcSetSettingsByHeight } from '@snapchat/mw-common/client';
import { useContentfulImages } from '@snapchat/mw-global-components';
import { Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { combineImageSources } from '../../utils/combineImageSources';
import { parseMedia } from '../../utils/media';
import type { ImageProps } from './types';

export const imageQuality = {
  // High quality images based on what we currently have for pixy.com
  high: 85,
  // Arrived at this number via manual testing not too far off visually but good savings
  default: 40,
  // Arrived at this number via manual testing images are not very clear but huge savings
  low: 10,
};

// TODO: Rename to Image and the other to ImageShallow
export const Image: FC<ImageProps> = props => {
  const { quality, dataset } = props;
  const { imageSource, imageAltText } = parseMedia(props.media);
  const { imageSource: mobileImageSource } = parseMedia(props.mobileMedia);
  const { getImageSources } = useContentfulImages();

  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, {
      ...getDprSrcSetSettingsByHeight(800, props.media.height ?? 0),
      quality: imageQuality[quality ?? 'default'],
    }),
    mobile: getImageSources(mobileImageSource ?? imageSource, {
      ...getDprSrcSetSettingsByHeight(800, props.mobileMedia?.height ?? 0),
      quality: imageQuality[quality ?? 'default'],
    }),
  });

  return (
    <MediaSDS
      altText={imageAltText}
      imgSrcs={imgSrcs}
      wrap={props.wrap ?? undefined}
      dataset={dataset}
    />
  );
};

Image.displayName = 'Image';
