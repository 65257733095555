import type { FC } from 'react';
import { useContext } from 'react';

import type { BaseComponentProps, ImageSources } from '../../types';
import { dataSetToAttributes } from '../../utils';
import { PictureContext } from './PictureContext';

interface Props extends BaseComponentProps {
  altText?: string;
  defaultSrc?: string;
  imgClassName?: string;
  imgSrcs?: ImageSources;
  height?: number;
  width?: number;
  fetchPriority?: 'high' | 'low' | 'auto';
  isDraggable?: boolean;
}

/**
 * Helper function to attach src/srcst AFTER its been rendered and attached to the real DOM. we do
 * this because safari eagerly loads imgs the moment src/srcset is attached since react uses js to
 * create the nodes, which will cause the browser to load images that it doesn't need. Modern
 * browseres don't even need src/srcset set on <img> so it does not affect img load times at all
 * since the SSR DOM still includes sources and browser still loads quickly.
 *
 * Related Links: https://bug-190031-attachments.webkit.org/attachment.cgi?id=456958
 * https://bugs.webkit.org/show_bug.cgi?id=190031 fix soon maybe:
 * https://github.com/WebKit/WebKit/commit/ebdff21ed51676ee81de66302692042d5bf8d6c4 current
 * workaround found here: https://github.com/facebook/react/issues/20682
 */
const addSrcFactory = (src?: string, srcSet?: string) => {
  return function (img: HTMLImageElement) {
    if (img && src) {
      img.src = src;
    }

    if (img && srcSet) {
      img.srcset = srcSet;
    }
  };
};

export const Picture: FC<Props> = ({
  altText,
  className,
  style,
  defaultSrc,
  imgClassName,
  imgSrcs,
  height,
  width,
  fetchPriority,
  dataset,
  isDraggable,
}) => {
  const hasNoSources = !imgSrcs?.sources?.length;
  const pictureContext = useContext(PictureContext);

  return (
    <picture className={className} style={style}>
      {imgSrcs?.sources?.map((imgSrc, index) => (
        <source
          srcSet={imgSrc.url}
          type={imgSrc.type}
          key={`${imgSrc.type}${index}desktop`}
          sizes={imgSrc.sizes}
          media={imgSrc.media}
        />
      ))}
      <img
        ref={addSrcFactory(imgSrcs?.default ?? defaultSrc, imgSrcs?.defaultSrcSet)}
        alt={altText ?? ''} // default to an empty string to ensure always render an alt attribute
        className={imgClassName}
        sizes={imgSrcs?.defaultSizes}
        height={height}
        width={width}
        // we do this because if there are no sources, we can just append the src and srcset since safari issue wont happen
        // and it fixes missing srcs on SSR
        src={hasNoSources ? imgSrcs?.default ?? defaultSrc : undefined}
        srcSet={hasNoSources ? imgSrcs?.defaultSrcSet : undefined}
        loading={pictureContext?.lazy ? 'lazy' : undefined}
        // @ts-ignore this attribute is chrome only and doesn't exist (as of November 2023) on typings yet because its so new
        // eslint-disable-next-line react/no-unknown-property
        fetchpriorty={fetchPriority}
        {...dataSetToAttributes(dataset)}
        draggable={isDraggable}
      />
    </picture>
  );
};
