import type { GeoLocation } from './geoHeaderTypes';

/** See 'unknown' country code here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
export const defaultCountryCode = 'ZZ';

/** By convention using this value. */
export const defaultStateCode = 'na';

export const defaultGeo: GeoLocation = {
  country: defaultCountryCode,
  region: defaultStateCode,
  regionSubdivision: `${defaultCountryCode}${defaultStateCode}`.toUpperCase(),
};

/** Parses the Google Cloud Load Balancer Geo headers into {@link GeoLocation} format. */
export function parseGclbGeoHeaders(regionHeader: string, subdivisionHeader: string): GeoLocation {
  const country = regionHeader; // Upper-cased like US or CA
  const regionSubdivision = subdivisionHeader; // Upper-cased like USCA or CAQC
  const region = regionSubdivision.replace(country, '').toLocaleLowerCase();
  return {
    country,
    region,
    regionSubdivision,
  };
}

/** Convert header names to lower-case (in case not done already), and discard duplicate headers. */
export function cleanGeoHeaders(
  rawHeaders: Record<string, string | string[] | undefined>
): Record<string, string | undefined> {
  return Object.fromEntries(
    Object.entries(rawHeaders).map(([header, value]) => [
      header.toLocaleLowerCase(),
      Array.isArray(value) ? value[value.length - 1] : value,
    ])
  );
}

/**
 * Returns the parsed geo location from the provided headers.
 *
 * Parses in this order:
 *
 * - Snap Geo headers
 * - Forwarded Load Balancer headers
 * - Load Balancer headers
 * - AppEngine headers
 *
 * Note that 'snapGeoHeaderParser' is an argument because the current implementation uses JSPB which
 * is very heavy and uses eval (not appropriate for common code).
 *
 * TODO: Rewrite the parsing from the value using raw wire format. See wire format:
 * https://developers.google.com/protocol-buffers/docs/encoding See proto def:
 * https://github.sc-corp.net/Snapchat/geo-header-api/blob/master/api/v1/geo_header.proto
 */
export function parseGeoHeaders(
  rawHeaders: Record<string, string | string[] | undefined>,
  snapGeoHeaderParser?: (snapGeoHeader: string) => GeoLocation | undefined
): GeoLocation {
  const headers = cleanGeoHeaders(rawHeaders);

  // 1. First, try to see if we've been forwarded GCLB headers from API Gateway.
  if (headers['x-gcp-client-region'] && headers['x-gcp-client-subdivision']) {
    return parseGclbGeoHeaders(headers['x-gcp-client-region'], headers['x-gcp-client-subdivision']);
  }

  // 2. Second parse snapchat headers. See https://wiki.sc-corp.net/x/HNWDBQ
  // These are set by service mesh if our page are being proxied through that.
  if (headers['x-snap-geo'] && snapGeoHeaderParser) {
    const snapGeo = snapGeoHeaderParser(headers['x-snap-geo']);

    if (snapGeo) {
      return snapGeo;
    }
  }

  // 3. Then try parse forwarded geo headers from MWP/Snapchat.
  // These are set when forwarding requests from MWP to cookie service.
  if (
    headers['x-sc-forwarded-client-region'] &&
    headers['x-sc-forwarded-client-region-subdivision']
  ) {
    return parseGclbGeoHeaders(
      headers['x-sc-forwarded-client-region'],
      headers['x-sc-forwarded-client-region-subdivision']
    );
  }

  // 4. Check MWP load balancer headers.
  // These are set by MWP load balancer. See https://cloud.google.com/load-balancing/docs/https/custom-headers
  if (headers['x-lb-client-region-subdivision'] && headers['x-lb-client-region']) {
    return parseGclbGeoHeaders(
      headers['x-lb-client-region'],
      headers['x-lb-client-region-subdivision']
    );
  }

  // 5. Check AppEngine headers.
  // These are set by AppEngine.
  if (headers['x-appengine-country'] && headers['x-appengine-region']) {
    // Upper-case. But can be '?' when not certain.
    const country =
      headers['x-appengine-country'] === '?' ? defaultCountryCode : headers['x-appengine-country'];
    // lower-case like 'ca' or 'tx'. Can be '?' when not certain.
    const region =
      headers['x-appengine-region'] === '?' ? defaultStateCode : headers['x-appengine-region'];
    const regionSubdivision = `${country}${region}`.toUpperCase();
    return {
      country,
      region,
      regionSubdivision,
    };
  }

  // 5. Default to default values.
  return defaultGeo;
}
