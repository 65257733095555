import { css } from '@emotion/css';
import { m, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

// helps center social logos
export const socialLogoCss = css`
  width: 25px;
  height: 25px;
`;

export const socialAnchorCss = css`
  display: flex;
  align-items: center;
`;

export const logoWrapperCss = css`
  padding-block-end: ${m('--spacing-xs')};

  ${nonMobileMediaQuery} {
    padding-block-end: 0;
  }
`;
