/**
 * Given a script element id, will wait for script to load if it is already on the DOM. If a script
 * element with the given ID does not exist on the DOM, then add it and wait for it to load.
 */
export function ensureSingleScript(
  scriptId: string,
  source: string,
  onLoaded: () => void,
  attributes?: Record<string, string>
): void {
  // const scriptId = 'akamai-player-script';
  const scriptElement = document.getElementById(scriptId);

  // If already loaded, call callback.
  if (scriptElement?.dataset['loaded']) {
    onLoaded();
    return;
  }

  const markLoadedAndCallback = () => {
    document.getElementById(scriptId)!.dataset['loaded'] = 'true';
    onLoaded();
  };

  // If loading, but hasn't finished, listen for load.
  if (scriptElement) {
    scriptElement.addEventListener('load', markLoadedAndCallback);
    return;
  }

  // Else start loading.
  const newScriptElement = document.createElement('script');
  newScriptElement.id = scriptId;
  newScriptElement.src = source;

  if (attributes) {
    for (const key in attributes) {
      newScriptElement.setAttribute(key, attributes[key] ?? '');
    }
  }

  newScriptElement.addEventListener('load', markLoadedAndCallback);
  document.head.appendChild(newScriptElement);
}
