import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { NavigatorItem as NavigatorItemType } from '@snapchat/mw-contentful-schema';
import { ItemDesktop as ItemDesktopSDS } from '@snapchat/snap-design-system-marketing';
import type { ComponentProps, FC } from 'react';

import { isStringLocalUrl } from '../../helpers/getLocalPath';
import { logEvent, logger, SubscribedEventType } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { PathAwareNavItem } from '../Header/headerPathUtils';

function logLocalNavDesktopClick(url: string, label?: string) {
  logEvent({
    subscribedEventType: SubscribedEventType.USER_INTERACTION,
    eventCategory: 'NavigatorItem',
    eventAction: UserAction.Click,
    eventLabel: label ?? url,
  });
}

interface LocalNavDesktopProps {
  items?: PathAwareNavItem[];
}

export const LocalNavDesktop: FC<LocalNavDesktopProps> = ({ items }) => {
  if (!items || !items.length) {
    return null;
  }

  const getItemDesktopProps = (props: PathAwareNavItem): ComponentProps<typeof ItemDesktopSDS> => {
    const { id, analyticsId, title, url, isSelected } = props;

    const { contentfulDescriptionDataset } = getContentfulInspectorProps<NavigatorItemType>({
      entryId: id,
      fieldIds: ['contentfulDescription'],
    });

    return {
      id,
      url,
      isSelected,
      title: documentToPlainTextString(title?.json),
      onClick: (): void => {
        logLocalNavDesktopClick(url ?? '', analyticsId);

        if (!isStringLocalUrl(url)) {
          void logger.flush();
        }
      },
      dataset: contentfulDescriptionDataset,
    };
  };

  return (
    <>
      {items.map(item1 => (
        <ItemDesktopSDS key={item1.id} {...getItemDesktopProps(item1)}>
          {item1.subItems.map(item2 => (
            <ItemDesktopSDS key={item2.id} {...getItemDesktopProps(item2)}>
              {item2.subItems.map(item3 => (
                <ItemDesktopSDS key={item3.id} {...getItemDesktopProps(item3)} />
              ))}
            </ItemDesktopSDS>
          ))}
        </ItemDesktopSDS>
      ))}
    </>
  );
};
