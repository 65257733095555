import { lazyComponent } from '@snapchat/mw-common/client';
import { getRawData } from '@snapchat/mw-contentful-schema';
import type { BarData } from '@snapchat/snap-design-system-marketing/src/components/visualizations/BarChart/utils/types';
import { type FC, useContext } from 'react';

import { AppContext } from '../../../AppContext';
import { SuspenseWrapper } from '../../SuspenseWrapper';
import { Loading } from '../Loading';
import type { BarChartRenderProps } from './types';

const LazyBarChart = lazyComponent(() =>
  import('@snapchat/snap-design-system-marketing/src/components/visualizations/BarChart').then(
    module => ({
      default: module.BarChart,
    })
  )
);

export const BarChart: FC<BarChartRenderProps> = props => {
  const {
    chartData,
    yAxis,
    xAxes,
    stackKey,
    filters,
    width,
    height,
    hideXAxisLabels,
    hideYAxisLabels,
    hideXPercentLabels,
    hideLegend,
    chartTitle,
    dataColors,
    showAsPercent,
    numberOfDecimalPlaces,
  } = props;

  const { isRTL } = useContext(AppContext);

  if (!chartData) return null;

  const rawData = getRawData(chartData);

  return (
    <SuspenseWrapper fallbackElement={<Loading />}>
      <LazyBarChart
        chartTitle={chartTitle}
        width={width}
        height={height ?? 0}
        yAxis={yAxis}
        xAxes={xAxes}
        stackKey={stackKey}
        filters={filters}
        hideXAxisLabels={hideXAxisLabels}
        hideYAxisLabels={hideYAxisLabels}
        hideXPercentLabels={hideXPercentLabels}
        showAsPercent={showAsPercent ?? true}
        numberOfDecimalPlaces={numberOfDecimalPlaces ?? 1}
        hideLegend={hideLegend ?? true}
        data={rawData as unknown as BarData}
        dataColors={dataColors || ['#A05CCC', '#FEFC01', '#3EDB9A', '#3BB2F7']}
        isRTL={isRTL}
      />
    </SuspenseWrapper>
  );
};
