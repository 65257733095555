import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-global-components';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import { dataSetToAttributes, useWindowSize } from '@snapchat/snap-design-system-marketing';
import noop from 'lodash-es/noop';
import type { CSSProperties, FC, ReactEventHandler } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { ContentfulImageProps } from '../../../../components/Media/mediaUtils';
import type { MediaDataProps } from '../../../../components/Media/types';
import { logEvent, SubscribedEventType } from '../../../../helpers/logging';
import { PlayButtonIcon } from './PlayButtonSvg';
import {
  fullScreenCss,
  objectFitCoverCss,
  playButtonCss,
  playButtonOverlayCss,
  videoWrapperCss,
} from './styles';
import type { CheeriosVideoDataProps } from './type';
import { getResolution } from './utils';

type Props = Omit<CheeriosVideoDataProps, '__typename' | 'sys'> &
  BaseComponentProps & {
    style: CSSProperties; // TODO: Add this to BaseComponentProps
    // Note we can pass objectFit: cover | contain overrides through this.
    withControls?: boolean;
    isAutoplay?: boolean;
    isMuted?: boolean;
    sysId?: string;
  };

let observer: IntersectionObserver;

const ensureObserver = () => {
  if (observer) return;

  observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.target instanceof HTMLVideoElement) {
          const video = entry.target as HTMLVideoElement;

          if (entry.isIntersecting && entry.intersectionRatio > 0.8) {
            if (video.paused) {
              // TODO: See if we can reload/reset/seek to beginning.
              video.play()?.catch(noop);
            }
          } else if (!video.paused) {
            video.pause();
          }
        }
      });
    },
    {
      root: null, // Viewport
      threshold: new Array(11).fill(0).map((_, i) => i / 10), // Trigger every ~10%
    }
  );
};

const defaultScreenWidth = 1080;

export const videoPosterContentfulImageOptions: ContentfulImageProps = {
  widthPx: 600, // Max image size. Super small because we need it to load FAST.
  quality: 50, // Again, low quality so this loads FAST.
};

export const CheeriosVideo: FC<Props> = props => {
  const {
    videoSourcesCollection: sources,
    thumbnailImage,
    className,
    style,
    dataset,
    withControls,
    isAutoplay,
    isMuted,
  } = props;
  const [video, setVideo] = useState<HTMLVideoElement>();
  const captureVideo = useCallback((node: HTMLVideoElement) => setVideo(node), []);
  const videoSizes = useRef<Record<string, number>>({});
  const [videoSources, setVideoSources] = useState<MediaDataProps[]>([]);
  const { width = defaultScreenWidth } = useWindowSize();
  const { getImageSources } = useContentfulImages();

  // Precompute the resolutions. Minor optimization.
  sources.items.forEach(source => {
    if (videoSizes.current[source.url]) return;
    videoSizes.current[source.url] = getResolution(source.description, defaultScreenWidth);
  });

  useEffect(() => {
    const smallestResolution = Math.min(...Object.values(videoSizes.current));
    const supportedVideoSources = sources.items.filter(
      video => videoSizes.current[video.url]! <= Math.max(width, smallestResolution)
    );
    setVideoSources(supportedVideoSources);
  }, [width, sources, setVideoSources, videoSizes]);

  useEffect(() => {
    ensureObserver();
  }, []);

  useEffect(() => {
    if (!video || !isAutoplay) return;

    observer?.observe(video);

    return () => {
      observer?.unobserve(video);
    };
  }, [video, isAutoplay]);

  const poster = thumbnailImage
    ? getImageSources(thumbnailImage.url, {
        // For backwards compatibility. Since posterSrc doesn't support multiple
        // sources, we have to use the default that works everywhere here
        image: { format: 'webp' },
      })?.default
    : undefined;

  const playVideo: ReactEventHandler<HTMLButtonElement> = useCallback(
    event => {
      if (!video) return;

      if (video.paused) {
        video.play()?.catch(noop);
      }

      if (!video.controls) {
        video.controls = true;
      }

      const playOverlay = event.target as HTMLButtonElement;
      playOverlay.style.visibility = 'hidden';
    },
    [video]
  );

  const handleVideoPlayPause: ReactEventHandler<HTMLVideoElement> = event => {
    if (isAutoplay) return;

    const video = event.target as HTMLVideoElement;
    const isPaused = video.paused;

    logEvent({
      subscribedEventType: SubscribedEventType.USER_INTERACTION,
      eventAction: `${isPaused ? 'Pause' : 'Play'}`,
      eventCategory: 'CheeriosVideo',
      eventLabel: `Id: ${props.sysId}`,
    });
  };

  return (
    <div
      style={{
        ...style,
        backgroundImage: poster ? `url(${poster})` : undefined,
      }}
      className={cx(videoWrapperCss, className)}
      {...dataSetToAttributes(dataset)}
      data-for="video"
      data-purpose="Workaround for https://developer.apple.com/forums/thread/688146"
    >
      <video
        ref={captureVideo}
        className={cx(objectFitCoverCss, fullScreenCss)}
        poster={poster}
        playsInline
        loop
        crossOrigin="anonymous"
        muted={isMuted}
        autoPlay={isAutoplay}
        controls={withControls}
        onPlay={handleVideoPlayPause}
        onPause={handleVideoPlayPause}
      >
        {React.Children.toArray(
          videoSources.map(video => (
            <source key={video.url} src={video.url} type={video.contentType} />
          ))
        )}
      </video>
      {!isAutoplay && (
        <button style={style} className={cx(className, playButtonOverlayCss)} onClick={playVideo}>
          <PlayButtonIcon className={playButtonCss} />
        </button>
      )}
    </div>
  );
};

CheeriosVideo.displayName = 'CheeriosVideo';
