import type { FC, PropsWithChildren } from 'react';
import { memo, useContext } from 'react';
import { createPortal } from 'react-dom';

import { AppContext } from '../../AppContext';

// eslint-disable-next-line import/no-unused-modules
export const headerPortalId = 'mwp-header-portal';

/**
 * Portal for rendering content directly into the header.
 *
 * Use as return React.createPortal(<element>, headerPortalId)
 */
export const HeaderPortal: FC<{ className?: string }> = memo(props => {
  const { headerPortalRef } = useContext(AppContext);

  return <aside className={props.className} data-testid={headerPortalId} ref={headerPortalRef} />;
});

HeaderPortal.displayName = 'HeaderPortal';

/**
 * Easy insertion point into the HeaderPortal.
 *
 * Use as <IntoHeaderPortal>{.. your stuff ..}</IntoHeaderPortal>
 *
 * NOTE: The contently only gets inserted on the client. Nothing displays on the server.
 */
export const IntoHeaderPortal: FC<PropsWithChildren> = ({ children }) => {
  const { headerPortalRef } = useContext(AppContext);

  return headerPortalRef?.current ? createPortal(<>{children}</>, headerPortalRef!.current) : null;
};
IntoHeaderPortal.displayName = 'IntoHeaderPortal';
