/**
 * URL Parameters that override app behavior.
 *
 * Use this for dev purposes only. These should have no behavior on prod.
 */
export const enum UrlParameter {
  // NOTE: Techically all of the Feature enums are also in here since they are part of the URL params.

  CONTENTFUL_ENVIRONMENT_NAME = 'contentfulEnvironmentName',

  /**
   * Overrides apollo behavior. Useful for turning off cache ('no-cache') or ignoring network
   * ('cache-only').
   */
  CONTENTFUL_FETCH_POLICY = 'contentfulFetchPolicy',

  /**
   * Disables web sockets for the experience site. In an effort to save cost, we don't normally
   * leave the staging version of the broadcast and bitmoji reaction services running. This flag
   * allows us to test the experience site without trying (and failing) to connect to those services
   * when they aren't running.
   */
  EXPERIENCE_DISABLE_WEB_SOCKETS = 'experienceDisableWebSockets',

  /** State of the homepage on the experience site. Used for testing and debugging. */
  EXPERIENCE_EVENT_STATE = 'eventState',

  /**
   * Used to disable video playing requirement to send bitmoji reactions Purpose: BRS live site
   * loadtest
   */
  EXPERIENCE_REQUIRE_LIVE_VIDEO_FOR_BITMOJI = 'experienceRequireLiveVideo',

  /**
   * Overrides the final experiment user bucket output determined in `decideExperiment`. Used by
   * CI/CD pipeline to ensure stable screen-shot tests when an experiment exists, and also in
   * contentful to let users preview certain variants.
   */
  EXPERIMENTS_USER_BUCKET = 'experimentsUserBucket',

  /**
   * Google Tag Manager Debugging. This flag is appended by GTM Preview feature. When this happens
   * we want to fire off GTM regardless of user's location because most of our customers are in
   * California.
   */
  GOOGLE_TAG_MANAGER_DEBUG = 'gtm_debug',

  /** Whether to display the debug panel in the lower-right corner. This works in prod. */
  MWP_DEBUG = 'mwp_debug',

  /** Whether to pause animations, like carousels. Primarily used by release tests. */
  PAUSE_ANIMATIONS = 'pauseAnimations',

  /** Easter Egg changing the scheme to a custom one (Bynd figma tokens import from 2023-10) */
  USE_ALTERNATE_MOTIF = 'alternateMotif',
}

export const urlParameters: UrlParameter[] = [
  UrlParameter.CONTENTFUL_ENVIRONMENT_NAME,
  UrlParameter.CONTENTFUL_FETCH_POLICY,
  UrlParameter.EXPERIMENTS_USER_BUCKET,
  UrlParameter.EXPERIENCE_EVENT_STATE,
  UrlParameter.EXPERIENCE_REQUIRE_LIVE_VIDEO_FOR_BITMOJI,
];
