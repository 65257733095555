import { css } from '@emotion/css';

import {
  mobileMediaQuery,
  nonMobileMediaQuery,
  nonTwoColumnCollapseMediaQuery,
  twoColumnCollapseMediaQuery,
  ZIndex,
} from '../../constants';
import { m } from '../../motif/m';
import { globalHeaderHeight } from '../GlobalHeader';

// TODO: Remove this. Leaving alone to merge in w/ the break templates.
export const blockPadding = 64;

export const backgroundCss = css`
  object-fit: cover;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const curtainCss = css`
  background-color: rgba(0, 0, 0, 0.75);
  z-index: ${ZIndex.BLOCK_CURTAIN};
`;

export const blockCss = css`
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: ${m('--background-color')};
  background-image: ${m('--background-image')};
  color: ${m('--foreground-color')};

  /** Styles for the background image if it gets set. Legacy */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const boundaryCss = css`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: ${m('--block-boundary-desktop-padding')};
  position: relative;
  z-index: ${ZIndex.BLOCK_BOUNDARY};

  ${mobileMediaQuery} {
    padding: ${m('--block-boundary-mobile-padding')};
  }
`;

export const headerCss = css`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 75%;
  padding: ${m('--block-header-desktop-padding')};

  ${mobileMediaQuery} {
    max-width: unset;
    padding: ${m('--block-header-mobile-padding')};
  }
`;

export const eyebrowCss = css`
  color: ${m('--block-eyebrow-color')};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 20px;
  margin: 0;
  margin-bottom: ${m('--spacing-xs')};
`;

export const titleCss = css`
  /* Overriding h* tags requires double specificity */
  && {
    color: ${m('--block-title-color')};
    font-size: ${m('--block-title-desktop-font-size')};
    font-stretch: ${m('--block-title-desktop-font-stretch')};
    line-height: ${m('--block-title-desktop-font-line-height')};

    ${mobileMediaQuery} {
      font-size: ${m('--block-title-mobile-font-size')};
      padding: 0;
    }
  }
  margin: 0; /* Resets h* user stylesheet margin. */
`;

export const subtitleCss = css`
  color: ${m('--block-subtitle-color')};
  /* Resets h* user stylesheet margin. */
  margin: 0;
  /* The margin between title/subtitle has to be on subtitle so that it collapses when there's only title. */
  margin-top: ${m('--spacing-m')};
`;

export const callsToActionWrapperCss = css`
  display: flex;
  padding: ${m('--spacing-m')} 0;

  > *:not(:last-child) {
    margin-right: ${m('--spacing-m')};
    margin-left: 0;
  }

  *[dir='rtl'] & > *:not(:last-child) {
    margin-right: 0;
    margin-left: ${m('--spacing-m')};
  }

  ${mobileMediaQuery} {
    padding: ${m('--spacing-m')};
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: ${m('--spacing-m')};
      margin-right: 0;
    }
  }
`;

export const contentContainerCss = css`
  --column-span: 2;
  display: grid;
  position: relative;
  grid-row-gap: ${m('--spacing-xl')};
  grid-column-gap: ${m('--spacing-xl')};
  padding-left: ${m('--spacing-m')};
  padding-right: ${m('--spacing-m')};
  justify-content: center;

  /* We make every column span 2 in desktop mode so we can half-column align. */
  & > * {
    grid-column: span var(--column-span);
  }

  ${mobileMediaQuery} {
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${m('--spacing-l')};
    /* NOTE: grid-column-gap same as desktop */
    padding-left: 0;
    padding-right: 0;
    padding-inline: ${m('--spacing-m')};
  }
`;

/** Augments the styles for {@link contentContainerCss} */
export const contentContainerStyle1ColumnCss = css`
  grid-template-columns: repeat(var(--column-span), minmax(0, 1fr));
`;

/** Augments the styles for {@link contentContainerCss} */
export const contentContainerStyle2ColumnCss = css`
  /* NOTE: We double the columns so we can do half-column adjustments */
  grid-template-columns: repeat(calc(2 * var(--column-span)), minmax(0, 1fr));

  /* In order to center a case where the last row only has 1 content,
   * We shift the start of the column.
   * Note that we don't do this if there's only 1 row.
   */
  ${nonMobileMediaQuery} {
    & > *:not(:first-child):nth-child(2n + 1):last-child {
      grid-column: var(--column-span) / span var(--column-span);
    }
  }
`;

/** Augments the styles for {@link contentContainerCss} */
export const contentContainerStyle3ColumnCss = css`
  /* NOTE: We double the columns so we can do half-column adjustments */
  grid-template-columns: repeat(calc(3 * var(--column-span)), minmax(0, 1fr));

  /* The case where there are actually 3 columns. */
  ${nonTwoColumnCollapseMediaQuery} {
    /* Handle the case where the last child can be in 1st position*/
    & > *:not(:first-child):last-child:nth-child(3n + 1) {
      grid-column: calc(1 + var(--column-span)) / span var(--column-span);
    }

    /* When there are 2 children on the last row, we shift over the first one to center both. */
    & > *:not(:first-child):nth-child(3n + 1):nth-last-child(2) {
      grid-column: var(--column-span) / span var(--column-span);
    }
  }

  ${twoColumnCollapseMediaQuery} {
    grid-template-columns: repeat(calc(2 * var(--column-span)), minmax(0, 1fr));

    ${nonMobileMediaQuery} {
      & > *:not(:first-child):nth-child(2n + 1):last-child {
        grid-column: var(--column-span) / span var(--column-span);
      }
    }
  }

  /** TODO: Figure out if we can remove this. */
  ${mobileMediaQuery} {
    grid-template-columns: repeat(var(--column-span), minmax(0, 1fr));
  }
`;

export const fullHeightBlockCss = css`
  min-height: calc(100vh - ${globalHeaderHeight}px);
  display: flex;
  background-size: cover;
  flex-direction: column;
`;
