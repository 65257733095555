import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';
import { cloneElement } from 'react';

import { useIsMobile } from '../../../hooks';
import type { BaseComponentProps } from '../../../types/components';
import type { CarouselProps } from '../../Carousel';
import { isCarouselElement } from '../../Carousel';
import type { CarouselV3Props } from '../../CarouselV3';
import { isCarouselV3Element } from '../../CarouselV3/utils';
import type { MediaProps } from '../../Media';
import { Media } from '../../Media';
import { isSummaryCardElement } from '../../SummaryCard/utils';
import { type HeroProps } from '../Hero';
import { HeroSize } from '../Hero.constants';
import { compactHeroMediaCss, heroMediaCss, heroWideMediaCss } from './HeroMedia.styles';

export type Props = BaseComponentProps &
  Pick<HeroProps, 'foregroundMedia' | 'imgSrcs' | 'wrapMedia' | 'showMediaMobile' | 'size'>;

export const HeroMedia: FC<Props> = ({
  className,
  foregroundMedia,
  imgSrcs,
  wrapMedia,
  showMediaMobile,
  size,
}) => {
  const isMobile = useIsMobile();

  wrapMedia = (foregroundMedia?.props as MediaProps)?.wrap ?? wrapMedia;

  if (isMobile && !showMediaMobile) return null;

  let children: ReactNode;

  if (foregroundMedia) {
    children = foregroundMedia;

    if (isCarouselElement(foregroundMedia)) {
      children = cloneElement(foregroundMedia, {
        itemWidth: undefined, // Required to force the number of items.
        maxItems: 1,
        arrows: isMobile,
      } as Partial<CarouselProps>);
    } else if (isCarouselV3Element(foregroundMedia)) {
      children = cloneElement(foregroundMedia, {
        isSingleView: true, // need to force single view
      } as Partial<CarouselV3Props>);
    }
  } else {
    children = <Media imgSrcs={imgSrcs} wrap={wrapMedia} />;
  }

  return (
    <aside
      data-test-id="sdsm-hero-media"
      className={cx(
        heroMediaCss,
        className,
        { [compactHeroMediaCss]: size === HeroSize.Compact },
        { [heroWideMediaCss]: foregroundMedia ? isSummaryCardElement(foregroundMedia) : false }
      )}
    >
      {children}
    </aside>
  );
};

HeroMedia.displayName = 'HeroMedia';
