import type { IsUrlCurrent } from '../components/ConsumerContextProvider'; // eslint-disable-line max-len
import { Config } from '../config';

/** List of domains that we consider as 'local'. I.e. links to these do not require hard navigations. */
const localHosts = [
  Config.domainName,
  `www.${Config.domainName}`,
  'localhost',
  '127.0.0.1',
  '[::]',
  '[::1]',
  /.*-dot-entapps-web-dev.gae.sc-corp.net/,
];

/**
 * Returns whether the url is local (on the same domain).
 *
 * Accounts for current domain, domain with www. subdomain and localhost.
 */
export const isLocalUrl = (url: URL): boolean => {
  for (const domain of localHosts) {
    if (typeof domain === 'string' && url.hostname === domain) {
      return true;
    }

    if (typeof domain === 'object' && domain.test(url.hostname)) {
      return true;
    }
  }

  return false;
};

/**
 * Returns whether the string is local (on the same domain).
 *
 * Accounts for current domain, domain with www. subdomain and localhost.
 *
 * Used when it is possible to have an invalid URL, but we don't want the function to throw an
 * error.
 */
export const isStringLocalUrl = (url?: string): boolean => {
  if (!url) return false;

  try {
    const urlObj = new URL(url);

    return isLocalUrl(urlObj);
  } catch (e) {
    // Do nothing return false
  }

  return false;
};

/**
 * Function factory for whether a given URL is the one currently being displayed OR is a parent.
 *
 * This is used to highlight breadcrumbs through navigation by highlighting items in the nav bar,
 * side bar etc.
 *
 * TODO: Refactor this. This should be in a separate context, and it needs to CHANGE when the
 * current path changes. Ticket: https://jira.sc-corp.net/browse/ENTWEB-5794
 */
export const createIsCurrentUrl: (getRequestUrl: () => string) => IsUrlCurrent =
  getRequestUrl => (destinationUrlString, options) => {
    const currentUrl = new URL(getRequestUrl());
    const destinationUrl = new URL(destinationUrlString, currentUrl);

    // If the target URL is in a different domain, then assume it's external and definitely not current.
    if (!isLocalUrl(destinationUrl)) {
      return false;
    }

    // Special case root dir.
    if (destinationUrl.pathname === '/') {
      return currentUrl.pathname === '/';
    }

    // Normal case
    if (!options?.ignoredPathPrefix) {
      return currentUrl.pathname === destinationUrl.pathname;
    }

    // Edge case: navigation item highlighting
    // In this case, we need to omit prefixes when comparing the current path to the target url specified by users.
    const pattern = new RegExp(`^${options.ignoredPathPrefix}`);
    const currentPathWithoutPrefix = currentUrl.pathname.replace(pattern, '');

    return currentPathWithoutPrefix === destinationUrl.pathname;
  };
