/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react';
import React from 'react';

import type { CarouselInternalState, MultiCarouselProps, SkipCallbackOptions } from './types';
import { getIfSlideIsVisbile, getInitialState } from './utils';

interface CarouselItemsProps {
  props: MultiCarouselProps;
  state: CarouselInternalState;
  clones: any[];
  notEnoughChildren: boolean;
  goToSlide: (index: number, skipCallbacks?: SkipCallbackOptions) => void;
}

export const CarouselItems: FC<CarouselItemsProps> = ({
  props,
  state,
  goToSlide,
  clones,
  notEnoughChildren,
}) => {
  const { itemWidth } = state;
  const { children, infinite, itemClass, itemAriaLabel, partialVisbile, partialVisible } = props;
  const {
    flexBisis,
    shouldRenderOnSSR,
    domFullyLoaded,
    partialVisibilityGutter,
    shouldRenderAtAll,
  } = getInitialState(state, props);

  if (!shouldRenderAtAll) {
    return null;
  }

  if (partialVisbile) {
    // eslint-disable-next-line no-console
    console.warn(
      'WARNING: Please correct props name: "partialVisible" as old typo will be removed in future versions!'
    );
  }

  return (
    <>
      {(infinite ? clones : React.Children.toArray(children)).map((child, index) => {
        return (
          <li
            key={index}
            data-index={index}
            onClick={() => {
              if (props.focusOnSelect) {
                goToSlide(index);
              }
            }}
            aria-hidden={getIfSlideIsVisbile(index, state) ? 'false' : 'true'}
            aria-label={
              itemAriaLabel ? itemAriaLabel : child.props.ariaLabel ? child.props.ariaLabel : null
            }
            style={{
              flex: shouldRenderOnSSR ? `1 0 ${flexBisis}%` : 'auto',
              position: 'relative',
              width: domFullyLoaded
                ? `${
                    // old wrongly spelt partialVisbile prop kept to not make changes breaking
                    (partialVisbile || partialVisible) &&
                    partialVisibilityGutter &&
                    !notEnoughChildren
                      ? itemWidth - partialVisibilityGutter
                      : itemWidth
                  }px`
                : 'wrappedImageCss',
            }}
            className={`react-multi-carousel-item ${
              getIfSlideIsVisbile(index, state) ? 'react-multi-carousel-item--active' : ''
            } ${itemClass}`}
          >
            {child}
          </li>
        );
      })}
    </>
  );
};
