import { cx } from '@emotion/css';
import { dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import camelCase from 'lodash-es/camelCase';
import type { CSSProperties, FC, ReactNode } from 'react';
import type React from 'react';

import { Mosaic } from '../../../../components/Mosaic';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { useMobileLayout } from '../../../../hooks/useLayout';
import { responsiveFontCss } from '../../../../styles/responsiveFontCss';
import type { ContentfulIdProps, ContentfulSysProps } from '../../../../types/contentful';
import { CheeriosButton } from '../CheeriosButton/CheeriosButton';
import { CheeriosImage } from '../CheeriosImage';
import { CheeriosMultiVideoBlock } from '../CheeriosMultiVideoBlock/CheeriosMultiVideoBlock';
import { CheeriosText } from '../CheeriosText';
import { CheeriosVideo } from '../CheeriosVideo/CheeriosVideo';
import { query } from './query';
import type { CheeriosStaticBlockData, CheeriosStaticBlockProps } from './types';

type Props =
  | ContentfulIdProps & {
      isPreviousSameBackgroundColor?: boolean;
      isNextSameBackgroundColor?: boolean;
    };

/** A Cheerios block with custom styles. */
export const CheeriosStaticBlock: FC<Props> = props => {
  const isMobile = useMobileLayout();

  const { data } = useContentfulQuery<
    CheeriosStaticBlockData,
    ContentfulIdVariable & { isMobile: boolean }
  >(query, {
    variables: { id: props.id, isMobile },
  });
  if (!data) return null;
  return <CheeriosStaticBlockRender {...data.cheeriosStaticBlock} isMobile={isMobile} />;
};

CheeriosStaticBlock.displayName = 'CheeriosStyledBlock';

//TODO: filter valid properties (Romain)
const sanitizeCssProperties = (properties?: React.CSSProperties) =>
  Object.entries(properties || {}).reduce((acc, [k, v]) => {
    acc[camelCase(k)] = v;
    return acc;
  }, {} as { [k: string]: string });

const CheeriosStaticBlockRender: FC<CheeriosStaticBlockProps> = ({
  sys: { id },
  title,
  cssProperties,
  mobileCssProperties,
  desktopCssProperties,
  content,
  isMobile,
  className,
}) => {
  const properties: CSSProperties = {
    ...sanitizeCssProperties(cssProperties),
    ...sanitizeCssProperties(isMobile ? mobileCssProperties : desktopCssProperties),
  };

  const dataset = {
    sysId: id,
    title,
  };

  let renderedContent: ReactNode = null;

  switch (content.__typename) {
    case 'Image':
      renderedContent = (
        <CheeriosImage style={properties} className={className} dataset={dataset} {...content} />
      );
      break;

    case 'Video': {
      renderedContent = (
        <CheeriosVideo
          style={properties}
          className={className}
          dataset={dataset}
          videoSourcesCollection={{ items: [content.media] }}
          thumbnailImage={content.thumbnailMedia}
        />
      );
      break;
    }

    case 'CheeriosMultiVideoBlock': {
      properties.position = 'absolute';
      renderedContent = <CheeriosMultiVideoBlock style={properties} {...content} />;
      break;
    }

    case 'CheeriosText': {
      renderedContent = (
        <CheeriosText
          text={content.text ?? ''}
          style={properties}
          className={className}
          dataset={dataset}
        />
      );
      break;
    }

    case 'Button': {
      renderedContent = (
        <CheeriosButton {...content} style={properties} className={className} dataset={dataset} />
      );
      break;
    }

    case 'Mosaic': {
      renderedContent = (
        <div style={properties} className={className} {...dataSetToAttributes(dataset)}>
          <Mosaic {...content} />
        </div>
      );
      break;
    }

    case 'ImageSequenceSource': {
      renderedContent = <>ImageSequence is no longer supported</>;
      break;
    }

    default: {
      const unknownContent = content as ContentfulSysProps;

      renderedContent = (
        <div {...dataSetToAttributes(dataset)} className={className} style={properties}>
          Not renderable content {unknownContent.__typename}.
        </div>
      );
      break;
    }
  }

  return <div className={cx(responsiveFontCss)}>{renderedContent}</div>;
};
