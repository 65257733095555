import { cx } from '@emotion/css';
import { GlobalHeader } from '@snapchat/mw-global-components';
import type {
  BackgroundColor,
  GlobalHeaderNavItemAlignment,
} from '@snapchat/snap-design-system-marketing';
import { globalHeaderHeight } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Config } from '../../config';
import type { MarketingWebConfiguration } from '../../configTypes';
import { HeaderView, PageLayoutContext } from '../../context/PageLayoutContext';
import { SitewideConfigurationContext } from '../../context/SitewideConfiguration/SitewideConfigurationContext';
import { Anchor } from '../Anchor';
import { CartIcon } from '../Cart';
import { useExperiment } from '../Experiment';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { DisplayRegion, GlobalHeaderCta } from '../GlobalHeaderCta/GlobalHeaderCta';
import { GlobalHeaderLogo } from '../GlobalHeaderLogo';
import { GlobalHeaderSearch } from '../GlobalHeaderSearch';
import { LocalNavDesktop } from '../LocalNavDesktop';
import { LocalNavMobile } from '../LocalNavMobile/LocalNavMobile';
import { useConsumerContext } from '../useConsumerContext';
import {
  globalHeaderSearchContainerDesktopOpenCss,
  globalHeaderSearchContainerMobileCss,
  headerContainerCss,
  noMarginCss,
  searchOpenCss,
  siteNameCss,
} from './Header.styles';
import { convertToPathAwareNavItems } from './headerPathUtils';
import { HeaderPortal } from './HeaderPortal';
import { setHeaderHeight, totalHeaderHeightCssVar } from './headerSizeUtils';

type Props = {
  config: MarketingWebConfiguration;
};

export const Header: FC<Props> = ({ config }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const location = useLocation();
  const { checkCriteria } = useExperiment();
  const { headerView, headerBackgroundColorOverride } = useContext(PageLayoutContext);
  const { sitewideValues } = useContext(SitewideConfigurationContext);
  const featureFlags = useFeatureFlags();

  const { isUrlCurrent } = useConsumerContext();

  const localNavProps = sitewideValues?.navigationBar;

  const callsToActionCollection = localNavProps?.callsToActionCollection;
  const navigatorItemsCollection = localNavProps?.navigatorItemsCollection;
  const hasCtas = callsToActionCollection?.items.length;
  const pathAwareNavItems = convertToPathAwareNavItems(
    navigatorItemsCollection?.items,
    isUrlCurrent,
    checkCriteria
  );

  const headerContainerRef = useRef<HTMLDivElement>(null);

  const resizeObserver = useMemo(() => {
    // attach resize observer on client
    if (typeof window !== 'undefined') {
      let lastSize = globalHeaderHeight;
      return new ResizeObserver(entries => {
        const { height } = entries[0]!.contentRect;

        if (lastSize !== height) {
          lastSize = height;
          document.documentElement.style.setProperty(totalHeaderHeightCssVar, `${height}px`);
        }
      });
    } else {
      // sets value for the variable being used in renderHtml to put that value on the root element during SSR
      setHeaderHeight(globalHeaderHeight);
    }

    return undefined;
    // we disable here because we only want it to run once on mount (on ssr and on client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (headerContainerRef.current && resizeObserver) {
      resizeObserver.observe(headerContainerRef.current, { box: 'border-box' });

      return () => {
        resizeObserver.disconnect();
      };
    }

    return;
  }, [headerContainerRef, resizeObserver]);

  const endChildren = (
    <>
      <GlobalHeaderCta callsToAction={callsToActionCollection?.items} />
      <HeaderPortal className={noMarginCss} />
      {Config.shopify && <CartIcon />}
    </>
  );

  if (headerView === HeaderView.NO_HEADER) {
    return null;
  }

  // In Order: value from Contentful > value from site Config > default value
  const siteName = localNavProps?.title ?? config.globalNavProps?.siteName ?? '';

  if (headerView === HeaderView.SIMPLE_HEADER || headerView === HeaderView.MINIMIZED_HEADER) {
    return (
      <GlobalHeader
        siteName={headerView === HeaderView.SIMPLE_HEADER && siteName}
        backgroundColor={
          headerBackgroundColorOverride ??
          (localNavProps?.backgroundColor as BackgroundColor) ??
          undefined
        }
        logo={<GlobalHeaderLogo />}
        pathname={location.pathname}
        showGlobalLinks={false}
        showNavScreen={false}
        cta={headerView === HeaderView.MINIMIZED_HEADER && endChildren}
      />
    );
  }

  const siteNameLink = (
    <Anchor href={localNavProps?.url} className={siteNameCss}>
      {siteName}
    </Anchor>
  );

  const shouldRenderSearch = location?.pathname !== '/search' && config.search;

  return (
    <div
      data-testid="mwp-header"
      ref={headerContainerRef}
      className={cx(headerContainerCss, config.headerProps?.className)}
    >
      <GlobalHeader
        {...config.globalNavProps}
        className={cx(config.globalNavProps?.className, searchOpen ? searchOpenCss : undefined)}
        siteName={siteNameLink}
        backgroundColor={
          headerBackgroundColorOverride ??
          config.globalNavProps?.backgroundColor ??
          (localNavProps?.backgroundColor as BackgroundColor) ??
          undefined
        }
        logo={<GlobalHeaderLogo />}
        cta={endChildren}
        pathname={location.pathname}
        localNavDesktop={!searchOpen ? <LocalNavDesktop items={pathAwareNavItems} /> : null}
        localNavMobile={<LocalNavMobile items={pathAwareNavItems} />}
        localNavMobileFooter={
          hasCtas ? (
            <GlobalHeaderCta
              callsToAction={callsToActionCollection?.items}
              displayRegion={DisplayRegion.Screen}
            />
          ) : null
        }
        search={
          shouldRenderSearch && <GlobalHeaderSearch key="search" setSearchOpen={setSearchOpen} />
        }
        searchOpen={searchOpen}
        endChildrenDesktopClassName={cx(
          config.globalNavProps?.endChildrenDesktopClassName,
          globalHeaderSearchContainerDesktopOpenCss
        )}
        endChildrenMobileClassName={cx(
          config.globalNavProps?.endChildrenMobileClassName,
          globalHeaderSearchContainerMobileCss
        )}
        navItemAlignment={
          featureFlags[Feature.GLOBAL_NAV_ITEMS_POSITION] as GlobalHeaderNavItemAlignment
        }
      />
    </div>
  );
};
