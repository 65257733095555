import { Spinner } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

export const Loading: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
      }}
    >
      <Spinner />
    </div>
  );
};
