import { useAsyncData } from '@snapchat/async-data-browser';
import isNil from 'lodash-es/isNil';
import { useCallback, useContext, useState } from 'react';

import type { UserInfo } from '../AppContext';
import { AppContext } from '../AppContext';
import { Config } from '../config';
import { userBucketCount } from '../constants/experiments';
import { UrlParameter } from '../constants/urlParameters';
import { logger } from '../helpers/logging';
import { adler32 } from '../utils/adler32';

const component = 'useAbExperiments';

export interface AbExperiment<V extends AbVariant> {
  seed: string;
  default?: V;
  variants?: V[];
}

export interface AbVariant {
  trafficStartRange?: number;
  trafficEndRange?: number;
}

export type AbExperimentDecider = <V extends AbVariant>(
  ab: AbExperiment<V>
) => {
  isThinking: boolean;
  decision: V | undefined;
};

/**
 * Hook that returns helper functions for working with AB Experiments.
 *
 * The ab experiment functions, when triggerred have side-effects like reading/setting cookies or
 * making RPC requets, so they should be called conditionally on them being needed.
 */
export function useAbExperiments(): AbExperimentDecider {
  const { getUserInfo, getCurrentUrl } = useContext(AppContext);

  const [needsDecision, setNeedsDecision] = useState(false);

  // NOTE: Calling getUserFactory reads and writes cookies, which means that it makes the
  // render ineligible for server-side rendering. This is why it's called very-very conditionally.

  // TODO: Add support for "skip" property on useAsyncData.
  const { data: userInfo } = useAsyncData({
    dataId: needsDecision ? 'userInfo' : 'dummy', // Has to be the same as in other places to hit cache.
    dataAsync: needsDecision ? getUserInfo : () => Promise.resolve(undefined),
    onError: error => logger.logError({ component, error }),
  });

  const decideAbExperiment = useCallback<AbExperimentDecider>(
    ab => {
      // Signals to load the userInfo.
      !needsDecision && setNeedsDecision(true);
      return decideAbExperimentImpl(ab, { userInfo, getCurrentUrl });
    },
    [needsDecision, getCurrentUrl, userInfo]
  );

  return decideAbExperiment;
}

/**
 * Internal helper function with the business logic.
 *
 * Extracted for testing purposes.
 */
export const decideAbExperimentImpl = <V extends AbVariant>(
  ab: AbExperiment<V>,
  context: { userInfo?: UserInfo; getCurrentUrl: () => string }
): { isThinking: boolean; decision: V | undefined } => {
  const { userInfo, getCurrentUrl } = context;

  // User info not loaded yet. Don't make a decision yet.
  if (!userInfo) {
    return { isThinking: true, decision: undefined };
  }

  const seededBucket = userInfo.experimentBucket.id + ab.seed;
  const userBucket = adler32(seededBucket) % userBucketCount;
  let userTrafficPercentile = (userBucket / userBucketCount) * 100;

  // Override userBucket via URL parameter for testing purposes.
  if (!Config.isDeploymentTypeProd) {
    const url = new URL(getCurrentUrl());
    const userBucketParameter = url.searchParams.get(UrlParameter.EXPERIMENTS_USER_BUCKET);
    const userBucket = userBucketParameter ? Number.parseInt(userBucketParameter) : undefined;

    if (userBucket !== undefined && userBucket >= 0 && userBucket <= 100) {
      userTrafficPercentile = userBucket;
    }
  }

  if (!ab.variants) return { isThinking: false, decision: ab.default };

  for (const variant of ab.variants) {
    // Skip variants without limits.
    if (isNil(variant.trafficStartRange) || isNil(variant.trafficEndRange)) continue;

    // If variant matches population criteria,
    if (
      variant.trafficStartRange <= userTrafficPercentile &&
      variant.trafficEndRange >= userTrafficPercentile
    ) {
      return { isThinking: false, decision: variant };
    }
  }

  return { isThinking: false, decision: ab.default };
};
