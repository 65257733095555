import { cx } from '@emotion/css';
import { Button as ButtonSDS, Icon, MessageContext } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { Config } from '../../config';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { AddToCart } from '../AddToCart';
import { ConsumerContext } from '../ConsumerContextProvider';
import { ShopifyContext } from '../ShopifyProvider';
import { CartAccessory } from './CartAccessory';
import { CartItem } from './CartItem';
import { useCheckoutUrl, usePrice } from './hooks';
import { Price } from './Price';
import {
  activeWrapperCss,
  allAccessoriesCss,
  buttonWrapperCss,
  cartCloseButtonCss,
  cartCloseCss,
  cartInternalCss,
  cartOverlayActiveCss,
  cartOverlayCss,
  cartWrapperStyles,
  checkoutButtonCss,
  checkoutDisclaimerCss,
  featuredProductButtonCss,
  featuredProductImg,
  featuredProductPriceCss,
  featuredProductTitleCss,
  noCartItemsContainerCss,
  noCartItemsTitleCss,
  totalCss,
} from './styles';

export const Cart: FC = () => {
  const { logEvent } = useContext(ConsumerContext);
  const { formatMessage } = useContext(MessageContext);
  const { cart, products } = useContext(ShopifyContext);
  const featuredProductHandle = Config.shopify?.options.featuredProduct ?? '';
  const featuredProduct = products.shopify[featuredProductHandle];
  const featuredContentfulProduct = products.contentful[featuredProductHandle];
  const checkoutUrl = useCheckoutUrl();
  const lineItemsById = Object.keys(cart.lineItems);
  const accessories = products.extras.accessories ?? [];
  const bundleItems = products.extras.bundleItems ?? [];

  const checkoutButtonCopy = formatMessage({
    id: 'cartCheckoutButton',
    defaultMessage: 'Checkout',
  });

  const disclaimer = formatMessage({
    id: 'cartCheckoutDisclaimer',
    defaultMessage: 'Shipping calculated at checkout',
  });

  const noCartItems = formatMessage({
    id: 'noCartItems',
    defaultMessage: 'Nothing added to cart yet',
  });

  const isAccessory = (id: string): boolean => accessories.includes(id);
  const isBundleItem = (id: string): boolean => bundleItems.includes(id);
  const hasItems = lineItemsById.length > 0;

  const totalFromCart = Object.keys(cart.lineItems).reduce((acc, handle) => {
    if (products?.shopify[handle] && !isBundleItem(handle)) {
      return acc + products?.shopify[handle]!.price * cart.lineItems[handle]!;
    }

    return acc;
  }, 0);

  const total = usePrice(totalFromCart);

  const totalCopy = formatMessage(
    {
      id: 'cartTotal',
      defaultMessage: 'Total {total}',
    },
    { total }
  );

  // Log the checkout interaction and take the user to the Shopify site.
  const onCheckoutClick = (): void => {
    logEvent?.({ type: UserAction.Click, component: 'CheckoutButton', url: checkoutUrl.href });
    void logger.flush();
  };

  return (
    <div
      className={cx(cartWrapperStyles(hasItems), {
        [activeWrapperCss]: cart?.cartOpen,
      })}
    >
      <div
        onClick={() => cart.setCartOpen(false)}
        className={cx(cartOverlayCss, {
          [cartOverlayActiveCss]: cart?.cartOpen,
        })}
      ></div>
      <div className={buttonWrapperCss}>
        <button className={cartCloseCss} onClick={() => cart.setCartOpen(false)}>
          <Icon name="cross" className={cartCloseButtonCss} />
        </button>
      </div>
      <div className={cartInternalCss}></div>
      {lineItemsById.map(
        productHandle =>
          products.shopify[productHandle] &&
          !isAccessory(productHandle) &&
          !isBundleItem(productHandle) && (
            <CartItem key={productHandle} {...products.shopify[productHandle]!} />
          )
      )}
      <div className={allAccessoriesCss}>
        {accessories.map(
          productHandle =>
            products.shopify[productHandle] &&
            products.contentful[productHandle] && (
              <CartAccessory
                key={productHandle}
                {...products.shopify[productHandle]!}
                title={products.contentful[productHandle]!.title}
              />
            )
        )}
      </div>
      {!hasItems && (
        <div className={noCartItemsContainerCss}>
          <div className={noCartItemsTitleCss}>{noCartItems}</div>
          {featuredProduct && featuredContentfulProduct && (
            <>
              <div className={featuredProductImg(featuredProduct.image)} />
              <div className={featuredProductTitleCss}>{featuredContentfulProduct.title}</div>
              <Price
                className={featuredProductPriceCss}
                productPrice={featuredProduct?.price ?? 0}
              />
              <AddToCart
                className={featuredProductButtonCss}
                productHandle={featuredProductHandle}
              />
            </>
          )}
        </div>
      )}
      {hasItems && (
        <>
          <div className={totalCss}>{totalCopy}</div>
          <div className={checkoutButtonCss}>
            <ButtonSDS link={checkoutUrl.href} type="Primary" onClick={onCheckoutClick}>
              {checkoutButtonCopy}
            </ButtonSDS>
          </div>
          <div className={checkoutDisclaimerCss}>{disclaimer}</div>
        </>
      )}
    </div>
  );
};
