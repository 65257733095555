import type { FC } from 'react';
import { useContext } from 'react';

import type { FormProps } from './Form';
import { FormContext } from './FormContext';
import { errorCodeCss, errorDebugSectionCss, errorMessageCss } from './styles';

type FormErrorMessageProps = Pick<
  FormProps,
  'on400ResponseMessage' | 'on500ResponseMessage' | 'renderErrorDetails'
>;

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  on400ResponseMessage,
  on500ResponseMessage,
  renderErrorDetails,
}) => {
  const { state } = useContext(FormContext);
  const { errorStatusCode, error } = state;
  if (!error) return null;

  const errorDisplayText = errorStatusCode === 400 ? on400ResponseMessage : on500ResponseMessage;

  const errorObject = JSON.stringify(
    {
      name: error.name,
      message: error.message,
      callStack: error.stack,
    },
    null,
    2
  );

  return (
    <>
      <div className={errorMessageCss}>{errorDisplayText}</div>
      {renderErrorDetails && (
        <div className={errorDebugSectionCss}>
          <h6>Error Object</h6>
          <code className={errorCodeCss}>{errorObject}</code>
        </div>
      )}
    </>
  );
};
