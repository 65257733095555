import { connectionTimeoutMs, contentfulRequestTimeoutMs } from '@snapchat/mw-common';
import fetch from 'cross-fetch';

import { Config } from '../../config';
import { getTracer } from '../tracing/tracer';

/** Event listeners that handle the start and end of a fetch request */
export const startFetchEventListeners = new Set<() => void>();
export const endFetchEventListeners = new Set<() => void>();

/**
 * Wrapper around fetch which adds some bells and whistles:
 *
 * - Adds a tracing span so that we can see all fetch requests in google cloud tracing.
 * - Adds a timeout to all requests equal to the externally configured timeout to early kill
 *   long-running requests.
 */

export const customFetch: typeof fetch = (input, init) =>
  new Promise((resolve, reject) => {
    startFetchEventListeners.forEach(listener => listener());
    // Starts timing this fetch. Works on both cliend and server.
    const trace = getTracer().startSpan(`Fetch: ${input}`);

    // Only using AbortController on the server (which 100% supports it).
    // Do not use it on the client to allow slow request to complete (or be aborted by the browser).
    // Also AbortController is only supported by 96% of browsers, so we need to be defensive here.
    // See: https://caniuse.com/?search=abort%20controller
    const controller = !Config.isClient ? new AbortController() : undefined;
    let isRejected = false;

    // Custom listener to report the abort error.
    // For some reason, during testing the fetch(..).catch() didn't always fire when the abort
    // signal is sent, which leaves the middleware forever waiting for the fetch to reply.
    // There's no natural timeout, so it would be a memory leak.
    controller?.signal.addEventListener('abort', () => {
      if (!isRejected) {
        reject(
          new Error(
            `Connection timed out after ${(contentfulRequestTimeoutMs / 1e3).toFixed(1)} seconds.`
          )
        );
        isRejected = true;
      }
    });

    // Setups up a timeout to abort if the request doesn't return before that.
    const timeoutMs = String(input).includes('contentful')
      ? contentfulRequestTimeoutMs
      : connectionTimeoutMs;
    const abortTimeout = setTimeout(() => {
      controller?.abort();
    }, timeoutMs);

    // Passing the abort signal to fetch. This only works for Node 15+.
    // But this is what allows us to release the resources and promises.
    const initWithAbort = { ...init, signal: controller?.signal };

    fetch(input, initWithAbort)
      .then(value => {
        resolve(value);
      })
      .catch(error => {
        // Note that we avoid rejecting here if we already rejected in the abort controller event.
        // Non-abort errors get rejected here.
        if (!isRejected) {
          reject(error);
          isRejected = true;
        }
      })
      .finally(() => {
        endFetchEventListeners.forEach(listener => listener());
        trace.endSpan();
        clearTimeout(abortTimeout);
      });
  });
