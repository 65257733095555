import type { ConnectionType } from '@snapchat/client-hints';
import {
  supportsAvif as supportsAvifCheck,
  supportsWebP as supportsWebpCheck,
} from '@snapchat/client-hints';
import { useContentfulImages as delegate } from '@snapchat/mw-common/client';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import { useContext } from 'react';

const slowConnections = new Set<ConnectionType>(['slow-2g', '2g', '3g']);

/**
 * Translation layer between the common helper functions for transforming contentful URLs and an app
 * that uses client hints.
 */
export function useContentfulImages(): ReturnType<typeof delegate> {
  const browserFeatures = useContext(BrowserFeaturesContext);

  // NOTE: This isn't a good solution because in pretty much all usecases this
  // won't be populated yet. But, it's better than nothing. I (Alex) tried to
  // do an async state update, but I get all sort of warnings and errors about
  // async state updates and nothing renders in tests.
  const connectionType = browserFeatures.getCachedHighEntropyHints()?.connection;

  const isSlowConnection =
    browserFeatures.getLowEntropyHints().saveData ||
    !!(connectionType && slowConnections.has(connectionType));

  const lowEntropyBrowserHints = browserFeatures.getLowEntropyHints();

  return delegate({
    isSlowConnection,
    supportsAvif: supportsAvifCheck(lowEntropyBrowserHints),
    supportsWebP: supportsWebpCheck(lowEntropyBrowserHints),
  });
}
