import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { Superscript } from '../Primitives';
import { footnoteCss } from './Footnote.styled';

export interface FootnoteProps {
  index: string;
  children?: ReactNode;
}

export const Footnote: FC<FootnoteProps> = ({ index, children }) => {
  useMotifStyles(MotifComponent.FOOTNOTE);
  return (
    <div data-testid="sdsm-footnote" className={cx(MotifComponent.FOOTNOTE, footnoteCss)}>
      {/* Note: The whitespace after "index" is important. It ensures that the browser never considers
      the footnote index as a part of the footnote content (i.e. "children"). This can happen if
      the footnote content begins with a roman numeral (i.e. 1, 2, 3 etc...) and causes problems
      in RTL locales */}
      <Superscript>{index} </Superscript>
      {children}
    </div>
  );
};

Footnote.displayName = 'Footnote';
